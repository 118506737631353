import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCardModule } from '../event-card/event-card.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CtSkeletonModule } from '@ctsolution/ct-framework';
import { EventFiltersModule } from './event-filters/event-filters.module';
import { EventListComponent } from './event-list.component';

@NgModule({
  declarations: [
    EventListComponent,
  ],
  exports: [
    EventListComponent,
  ],
  imports: [
    CommonModule,
    EventCardModule,
    FlexLayoutModule,
    CtSkeletonModule,
    EventFiltersModule,
  ],
})
export class EventListModule {
}
