<div class='purchases-container'>

  <h2> I miei acquisti </h2>

  <div>

    <ng-container *ngIf='carts.length > 0; else emptyTemplate'>

      <div>

        <h4 class='m-t-0'> In questa sezione potrai consultare la lista degli acquisti che hai effettuato.</h4>

      </div>

      <app-user-purchases [list]='carts'></app-user-purchases>

    </ng-container>
    <ng-template #emptyTemplate>

      <p class='text-center'> Non hai ancora effettuato nessun acquisto.</p>

    </ng-template>

  </div>

</div>
