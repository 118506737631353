import { AbstractControl, ValidationErrors } from "@angular/forms"

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {


    let value: string = control.value || '';
    let msg = "";

    if (!value) {
        return null
    }

    let upperCaseCharacters = /[A-Z]+/g;
    let lowerCaseCharacters = /[a-z]+/g;
    let numberCharacters = /[0-9]+/g;

    if (upperCaseCharacters.test(value) === false || lowerCaseCharacters.test(value) === false || numberCharacters.test(value) === false) { 

        return {
            passwordStrength: 'Password must contain at least an uppercase letter, a lowercase letter and a number.'
        }

    }

    return null;
}