import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileImageComponent } from './user-profile-image.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';



@NgModule({
  declarations: [
    UserProfileImageComponent,
  ],
  exports: [
    UserProfileImageComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
  ],
})
export class UserProfileImageModule { }
