import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { FilterCacheService } from '../../../../core/lib/filter-cache.service';

@Component({
  selector: 'app-athletes-filters',
  templateUrl: './athletes-filters.component.html',
})
export class AthletesFiltersComponent {

  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  expandedFilterPanel = true;

  constructor(private fb: FormBuilder, private cacheService: FilterCacheService) {

    this.form = this.fb.group({
      CompanyBusinessName: new FormControl(null),
      NameSurname: new FormControl(null),
      Category: new FormControl(null),
      Gender: new FormControl(null),
    });

    this.form
      .valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {

        const value = this.form.value;

        this.cacheService.saveFormValue(AthletesFiltersComponent, value);

        this.update.emit(value);

      });

    const prevValue = this.cacheService.getFormValue(AthletesFiltersComponent);

    if (prevValue) {

      this.form.patchValue(prevValue);
      this.expandedFilterPanel = true;

    }

  }

}
