<div class='event-card-actions-cntr' *ngIf='product'>

  <ng-container *ngIf='viewModel.subscribable; else defaultTemplate'>

    <ng-container *ngIf='viewModel.confirmed; else waitingApprovalTemplate'>

      <div class='payment-cntr'>

        <button
          mat-raised-button
          [class.warning-message]='viewModel.eventWaitingPayment'
          [disabled]='viewModel.eventSuccessfulPaid || viewModel.eventWaitingPayment'
          (click)='payment()'
          color='primary'>
          <ng-container *ngIf='viewModel.eventSuccessfulPaid; else handledPayment'>ISCRITTO</ng-container>
          <ng-template
            #handledPayment>{{ viewModel.eventWaitingPayment ? 'IN ATTESA DI VALIDAZIONE' : 'VAI AL PAGAMENTO'}}</ng-template>
          <mat-icon *ngIf='viewModel.eventSuccessfulPaid'>check</mat-icon>
        </button>

      </div>

      <div class='cancel-cntr' [hidden]='viewModel.eventWaitingPayment'>

        <button
          mat-raised-button
          (click)='cancel()'
          class='cancel-button'> ANNULLA PARTECIPAZIONE
        </button>

      </div>

    </ng-container>

    <ng-template #waitingApprovalTemplate>

      <p class='text-center'> {{ viewModel.invitationLabel }}</p>

      <div class='actions-cntr'>

        <button
          mat-raised-button
          color='primary'
          (click)='accept()'> CONFERMA
        </button>

        <button
          mat-raised-button
          (click)='cancel()'
          class='cancel-button'> RIFIUTA
        </button>

      </div>

    </ng-template>

  </ng-container>

  <ng-template #defaultTemplate>

    <div class='download-cntr' fxLayout='row wrap'>

      <div
        [fxFlex]='!!product?.UrlEventTimeLimitDocument ? 75 : 100'>

        <button
          *ngIf='product?.UrlEventPosterDocument'
          mat-raised-button
          (click)='downloadBrochure.emit()'
          color='primary'> SCARICA LOCANDINA
        </button>

      </div>

      <div *ngIf='product?.UrlEventTimeLimitDocument' fxFlex='25'>

        <button
          mat-raised-button
          (click)='downloadLimitTimes.emit()'
          class='tl-download'> TL
        </button>

      </div>

      <div
        *ngIf='product?.UrlEventConvocationDocument'
        [fxFlex]='100'>

        <button
          mat-raised-button
          (click)='downloadConvocationDocument.emit()'
          color='primary'> SCARICA CONVOCAZIONI
        </button>

      </div>

    </div>

  </ng-template>

</div>
