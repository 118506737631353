import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductController } from '../../../../core/controllers/product.controller';
import { ICheckoutPaymentBoxData } from './checkout-payment-box-data';
import { CartPaymentMethodEnum } from '../../../../core/enums/cart-payment-method.enum';

@Component({
  selector: 'app-checkout-payment-box',
  templateUrl: './checkout-payment-box.component.html',
  styleUrls: ['./checkout-payment-box.component.scss'],
  providers: [ProductController],
})
export class CheckoutPaymentBoxComponent {

  @Input() data!: ICheckoutPaymentBoxData;
  @Input() disabled: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelected: EventEmitter<CartPaymentMethodEnum> = new EventEmitter<CartPaymentMethodEnum>();

  constructor() {
  }

  select() {

    this.data.loader = true;
    this.onSelected.emit(this.data.type);

  }

}
