import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class StripeService {

  constructor() {
  }

  private parseResultHtml = (value: string): string => value.replace(/\"/g, '"');

  handleCheckOutForm(result: any): void {

    result = this.parseResultHtml(result);

    const form = $(result);

    form.appendTo($('body'));

    if (form.attr('method') === 'get') {

      const action: string | undefined = form.attr('action');

      if (action) {
        window.location.href = action;
      }

    } else {

      form.trigger('submit');

    }

  }

}
