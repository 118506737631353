import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompetitionPaymentComponent } from './competition-payment/competition-payment.component';
import { RouterModule } from '@angular/router';
import { ProductListModule } from '../../../../_components/product-list/product-list.module';


@NgModule({
  declarations: [
    CompetitionPaymentComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: CompetitionPaymentComponent,
      },
    ]),
    ProductListModule,
  ],
})
export class CompetitionPaymentModule {
}
