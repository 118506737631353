import { Component, OnInit } from '@angular/core';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { CartService } from '../../../core/lib/cart.service';
import { CartController } from '../../../core/controllers/cart.controller';
import { RoleService } from '../../../core/lib/role.service';

@Component({
  selector: 'app-vertical-header',
  templateUrl: './vertical-header.component.html',
  styleUrls: [],
  providers: [CartController],
})
export class VerticalAppHeaderComponent implements OnInit {

  viewModel: any = {

    isTechnical: null,

  };

  constructor(
    private ctAuthenticationService: CtAuthenticationService,
    public cartService: CartService,
    private cartController: CartController,
    public roleService: RoleService,
  ) {
  }

  ngOnInit(): void {

    this.setup()
      .then(() => this.cartController.activeCartProductsCount());

  }

  async setup() {

    this.viewModel.isTechnical = await this.roleService.isTechnical();

  }

  logout(): void {

    this.ctAuthenticationService
      .signOut('/authentication/login');

  }

}
