import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketPlaceListComponent } from './market-place-list/market-place-list.component';
import { RouterModule } from '@angular/router';
import { ProductListModule } from '../../_components/product-list/product-list.module';

@NgModule({
  declarations: [
    MarketPlaceListComponent,
  ],
  imports: [
    CommonModule,
    ProductListModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: '',
            component: MarketPlaceListComponent,
            data: {
              title: 'Marketplace',
              urls: [{ title: 'Marketplace', url: '/market-place' }, { title: 'Marketplace' }],
            },
          }],
      },
    ]),
  ],
})
export class MarketPlaceModule {
}
