<mat-label class='m-l-15' *ngIf='showDocumentsLable'>

  <ng-container [ngSwitch]='side'>

    <em *ngSwitchCase='DOCUMENT_SIDE.REAR'>Retro</em>
    <em *ngSwitchCase='DOCUMENT_SIDE.FRONT'>Fronte</em>

  </ng-container>

</mat-label>

<mat-card class='document-box-cntr'
          [class.valid]='_control.valid && _background'
          [class.invalid]="_control?.hasError('required') && _control.touched">

  <mat-card-content>

    <ng-container *ngIf='needsDataBeforeFileUpload; else uploadTemplate'>

      <ng-container *ngTemplateOutlet='alertFieldRequiredTemplate'></ng-container>

    </ng-container>

    <ng-template #uploadTemplate>

      <img *ngIf='!needsDataBeforeFileUpload && _background' class='document-img'
           [src]='_background | secureImage | async' />

      <button mat-fab (click)='fileUploader.click()' style='margin: auto' class='document-upload-button'>
        <mat-icon> {{ _background ? 'edit' : 'add' }}</mat-icon>
      </button>

      <input type='file' hidden #fileUploader (change)='onChange($event)'>

    </ng-template>

  </mat-card-content>

</mat-card>

<ng-template #alertFieldRequiredTemplate>

  <p style='margin: auto'> Compila il campo in sovraimpressione prima di caricare il documento. </p>

</ng-template>
