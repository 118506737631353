import { Component, OnInit } from '@angular/core';
import { CartController } from '../../../../core/controllers/cart.controller';
import { UserCart } from '../../../../core/interfaces/user-cart';

@Component({
  selector: 'app-purchases-list',
  templateUrl: './purchases-list.component.html',
  styleUrls: ['./purchases-list.component.scss'],
  providers: [CartController],
})
export class PurchasesListComponent implements OnInit {

  carts: UserCart[] = [];

  constructor(private cartController: CartController) {
  }

  ngOnInit(): void {

    this.getPaidCarts();

  }

  getPaidCarts() {

    this.cartController
      .getPaidCarts()
      .then((result: UserCart[]) => this.carts = result);

  }

}
