import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { SpnUserFile } from '../classes/spn-user-file';
import { GeneralService } from '../lib/general.service';
import { CouponAmountDTO } from '../classes/coupon-amount';
import { UserProfileUpdateParameter } from '../classes/user-profile-update.parameter';

const PROFILE_CONTROLLER = 'Profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileController {

  constructor(private generalService: GeneralService, private webapi: CtWebapiService) {
  }

  private loadingUserData = false;

  private userProfilePicUrl: string | null = null;

  private loadingUserProfilePicUrl = false;

  updateProfile(parameter: UserProfileUpdateParameter) {

    const dataRequest = new DataRequest(`/${PROFILE_CONTROLLER}/UpdateProfile`)
      .setBody(parameter);

    return this.webapi
      .put(dataRequest);

  }

  getUserData = (): Promise<any> => new Promise<any>((resolve) => {

    if (!this.loadingUserData) {

      this.loadingUserData = true;

      this.webapi
        .get(new DataRequest(`/${PROFILE_CONTROLLER}/GetUserData`))
        .subscribe(r => {

          this.loadingUserData = false;
          resolve(r as any);

        });

    } else {

      setTimeout(() => resolve(this.getUserData()), 300);

    }

  });

  getPhotoPreviewUrl(): Promise<string | null> {

    return new Promise<string | null>((resolve) => {

      if (!!this.userProfilePicUrl) {

        resolve(this.userProfilePicUrl);
        return;

      }

      if (!this.loadingUserProfilePicUrl) {

        this.loadingUserProfilePicUrl = true;

        const request: DataRequest = new DataRequest(`/${PROFILE_CONTROLLER}/GetPhotoPreviewUrl`);

        this.webapi
          .get(request)
          .subscribe((response: string | null) => {

            this.userProfilePicUrl = response;
            this.loadingUserProfilePicUrl = false;

            resolve(this.userProfilePicUrl);

          });

      } else {

        setTimeout(() => resolve(this.getPhotoPreviewUrl()), 300);

      }

    });

  }

  getRegistrationDocuments = (): Promise<any> => new Promise<any>((resolve) => {

    this.webapi
      .get(new DataRequest(`/${PROFILE_CONTROLLER}/GetRegistrationDocuments`))
      .subscribe(r => resolve(r as any));

  });

  setDocument(spnUserFileDto: SpnUserFile): Promise<any> {
    return new Promise<any>((resolve) => {
      this.webapi.post(new DataRequest(`/${PROFILE_CONTROLLER}/SetDocument`), spnUserFileDto)
        .subscribe((r: any) => {
          resolve(r as any);
        });
    });
  }

  setFamilyData(parentDataParameter: {
    ParentEmail: string,
    ParentPhone: any,
    ParentFiscalCode: string,
    ParentName: string,
    ParentSurname: string
  }): Promise<any> {

    parentDataParameter.ParentName = this.generalService.upperCamelCase(parentDataParameter.ParentName);
    parentDataParameter.ParentSurname = this.generalService.upperCamelCase(parentDataParameter.ParentSurname);
    parentDataParameter.ParentFiscalCode = parentDataParameter.ParentFiscalCode.toUpperCase();

    return new Promise<any>((resolve) => {
      this.webapi.post(new DataRequest(`/${PROFILE_CONTROLLER}/SetFamilyData`), parentDataParameter)
        .subscribe((r: any) => {
          resolve(r as any);
        });
    });

  }

  putPassword(Password: string, NewPassword: string) {

    const params = { Password, NewPassword };

    return this.webapi
      .put(new DataRequest(`/ct/${PROFILE_CONTROLLER}/PutPassword`), params);

  }

  getCouponAmount(): Promise<number> {

    const request: DataRequest = new DataRequest(`/${PROFILE_CONTROLLER}/GetCouponAmount`);

    return new Promise<number>((resolve) => {

      this.webapi
        .get(request)
        .subscribe((result: CtWebapiGenericResponse<any>) => {

          const couponAmount: CouponAmountDTO = result.Result;
          resolve(couponAmount.Amount);

        });

    });

  }

}
