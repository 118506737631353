import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteProfileConfirmComponent } from './delete-profile-confirm.component';
import { DeleteProfileConfirmService } from './delete-profile-confirm.service';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [
    DeleteProfileConfirmComponent,
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
  ],
  providers: [
    DeleteProfileConfirmService,
  ],
})
export class DeleteProfileConfirmModule {
}
