<h1 mat-dialog-title>Attenzione</h1>
<div mat-dialog-content>

  Scegliendo il pagamento rateale tramite carta di credito o bonifico verrà addebitata adesso solo la prima rata; la
  prossima dovrà essere saldata entro il 5 dicembre.<br />
  La prima scadenza contiene il tesseramento per intero e la prima rata (50%) della quota. Ogni rata sarà maggiorata di
  25€.<br />

  <br />
  Confermi di voler proseguire?

</div>
<div mat-dialog-actions style='display: flex; justify-content: space-between'>
  <button mat-button [mat-dialog-close]='false'>No</button>
  <button mat-raised-button color='primary' [mat-dialog-close]='true' cdkFocusInitial>Conferma</button>
</div>
