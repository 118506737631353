import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProductListModule } from '../../_components/product-list/product-list.module';
import { UpcomingModule } from './upcoming/upcoming.module';
import { MyCompetitionsModule } from './my-competitions/my-competitions.module';
import { HistoricalModule } from './historical/historical.module';
import { SpecialRankingsModule } from './special-rankings/special-rankings.module';
import { RecordModule } from './record/record.module';
import { LimitTimesModule } from './limit-times/limit-times.module';
import { QualificationsModule } from './qualifications/qualifications.module';

@NgModule({
  imports: [
    CommonModule,
    ProductListModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: 'upcoming',
            loadChildren: () => UpcomingModule,
          },
          {
            path: 'my-competitions',
            loadChildren: () => MyCompetitionsModule,
          },
          {
            path: 'historical',
            loadChildren: () => HistoricalModule,
          },
          {
            path: 'record',
            loadChildren: () => RecordModule,
          },
          {
            path: 'limit-times',
            loadChildren: () => LimitTimesModule,
          },
          {
            path: 'special-rankings',
            loadChildren: () => SpecialRankingsModule,
          },
          {
            path: 'qualifications',
            loadChildren: () => QualificationsModule,
          },
        ],
      },
    ]),
  ],
})
export class CompetitionsAndEventsModule {
}
