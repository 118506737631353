import { Component, OnInit } from '@angular/core';
import { ProductListConfiguration } from '../../../../../_components/product-list/product-list.configuration';
import { ProductCategoryMacroTypeEnum } from '../../../../../core/enums/product-category-macro-type.enum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-competition-payment',
  templateUrl: './competition-payment.component.html',
  styleUrls: ['./competition-payment.component.scss'],
})
export class CompetitionPaymentComponent implements OnInit {

  competitionPayment: ProductListConfiguration | undefined;

  constructor(private route: ActivatedRoute) {

    this.route
      .queryParams
      .subscribe(qp => {

        const configuration: ProductListConfiguration = ProductListConfiguration.create(ProductCategoryMacroTypeEnum.Event);

        if (qp['id']) {

          configuration.ProductOIDs = [+qp['id']];

        }

        this.competitionPayment = configuration;

      });

  }

  ngOnInit(): void {
  }

}
