import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { ProfileController } from 'src/app/core/controllers/profile.controller';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { GeneralService } from '../../../../core/lib/general.service';
import { PasswordStrengthValidator } from '../../../../authentication/password-strength.validator';

const oldpassword = new FormControl('', Validators.compose([Validators.required]));
// @ts-ignore
const newpassword = new FormControl('', Validators.compose([Validators.required, PasswordStrengthValidator, CustomValidators.notEqualTo(oldpassword)]));
// @ts-ignore
const repeatnewpassword = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(newpassword)]));

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  public form: FormGroup = Object.create(null);

  hideOldPw = true;
  hideNewPw = true;
  hideNewPw2 = true;


  constructor(
    private formBuilder: FormBuilder,
    private profileController: ProfileController,
    private _snackbar: MatSnackBar,
    private general: GeneralService,
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      oldpassword,
      newpassword,
      repeatnewpassword,
    });
  }

  onSubmit() {

    if (this.form.valid) {

      this.profileController
        .putPassword(
          this.form.get('oldpassword')?.value, this.form.get('newpassword')?.value)
        .subscribe((r) => {

          this._snackbar.open('La modifica della tua password è avvenuta con successo!', 'X', {
            duration: 3000,
            panelClass: 'general-snackbar-panel',
          });

          this.general.back();

        });

    }

  }

}
