import { CartPaymentMethodEnum } from '../enums/cart-payment-method.enum';
import { AddToCartDTO } from './add-to-cart';
import { CartCheckoutParameter } from './cart-checkout-parameter';

export class PurchaseDTO extends CartCheckoutParameter {

  CartProducts: Array<AddToCartDTO> = [];
  CartPaymentOid?: number;

  constructor(paymentMethod: CartPaymentMethodEnum, fiscalCode: string) {
    super(paymentMethod, fiscalCode);
  }

}
