import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  CompareSpecialtyTrend,
} from '../../event-card/event-card/event-results-list/event-results-list/event-results-list.component';
import { EventListFilterDTO } from '../../../../../core/controllers/event.controller';
import { CTDateAdapter } from '@ctsolution/ct-framework';
import { FilterCacheService } from '../../../../../core/lib/filter-cache.service';

@Component({
  selector: 'app-event-filters[parentName]',
  templateUrl: './event-filters.component.html',
})
export class EventFiltersComponent {

  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Input() parentName: string | null = null;

  form: FormGroup;
  expandedFilterPanel = true;

  constructor(
    private fb: FormBuilder,
    private cacheService: FilterCacheService,
    private route: ActivatedRoute) {

    this.form = this.fb.group({
      StartDate: new FormControl(null),
      EndDate: new FormControl(null),
      SwimStyle: new FormControl(null),
      Distance: new FormControl(null),
      PoolLength: new FormControl(null),
      MustHaveTimeLimit: new FormControl(null),
    });

  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        this.form
          .valueChanges
          .pipe(debounceTime(500), distinctUntilChanged())
          .subscribe(() => {

            const value = this.form.value;

            this.cacheService.saveFormValue(EventFiltersComponent, value, { suffix: this.parentName });

            this.update.emit();

          });

        const prevValue = this.cacheService.getFormValue(EventFiltersComponent, { suffix: this.parentName });

        if (prevValue) {

          this.form.patchValue(prevValue);
          this.expandedFilterPanel = true;

        } else {

          if (qp['MustHaveTimeLimit']) {

            this.form.get('MustHaveTimeLimit')?.setValue(true);
            this.expandedFilterPanel = true;

          }

        }

      });

  }

  setFilters(parameter: EventListFilterDTO): EventListFilterDTO {

    const value: any = this.form.value;

    parameter = Object.assign({}, value);

    const dateAdapter: CTDateAdapter = CTDateAdapter.create();

    parameter.FromEventStartDate = value.StartDate ? dateAdapter.transformDateToCurrentTimezone(value.StartDate) : undefined;
    parameter.ToEventStartDate = value.EndDate ? dateAdapter.transformDateToCurrentTimezone(value.EndDate) : undefined;

    return parameter;

  }

  setTrend(parameter: CompareSpecialtyTrend): void {

    this.form.get('SwimStyle')?.setValue(parameter.SpecialtyOid);
    this.form.get('SwimStyle')?.updateValueAndValidity();

    this.form.get('Distance')?.setValue(parameter.Distance);
    this.form.get('Distance')?.updateValueAndValidity();

  }

}
