export const MenuTechnicalItems = [
  {
    state: 'home',
    name: 'Home',
    icon: 'home',
    type: 'link',
  },
  {
    state: 'competitions-and-events',
    name: 'Gare ed Eventi',
    type: 'sub',
    icon: 'workspace_premium',
    children: [
      { state: 'upcoming', name: 'Calendario gare ed eventi', type: 'link' },
      { state: 'record', name: 'Record', type: 'link' },
      { state: 'special-rankings', name: 'Graduatorie speciali', type: 'link' },
      { state: 'qualifications', name: 'Qualifiche', type: 'link' },
    ],
  },
  {
    state: 'athletes',
    name: 'Scheda atleti',
    icon: 'pool',
    type: 'link',
  },
  {
    state: 'personal-area',
    name: 'Area Personale',
    type: 'link',
    icon: 'person',
  },
  {
    state: 'notifications',
    name: 'Notifiche',
    icon: 'mark_unread_chat_alt',
    type: 'sub',
    children: [
      { state: 'send', name: 'Comunicazioni', type: 'link' },
      { state: 'historical', name: 'Storico', type: 'link' },
    ],
  },
];
