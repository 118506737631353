import { Component, Input } from '@angular/core';
import { CheckOutPaymentData } from '../checkout-payment/check-out-payment-data';
import { UserService } from '../../../core/lib/user.service';
import { CartPaymentDTO } from '../../../core/interfaces/cart-payment';

@Component({
  selector: 'app-checkout-recap',
  templateUrl: './checkout-recap.component.html',
  styleUrls: ['./checkout-recap.component.scss'],
})
export class CheckoutRecapComponent {

  @Input() data!: CheckOutPaymentData;
  @Input() couponAmount = 0;

  viewModel: any = {

    nextPayment: null,

  };

  constructor(public userService: UserService) {
  }

  ngOnInit() {

    this.setup();

  }

  async setup() {

    this.viewModel.nextPayment = await this.userService.userNextPayment();

  }

  get price(): number {

    let price: number = (this.data.fullCart ? this.data.UserCart?.FinalAmount : this.data.product?.Quote.BasePrice) ?? 0;

    if (!this.data.fullCart && this.viewModel.nextPayment) {

      price = this.viewModel.nextPayment.Amount;

    }

    return price;

  }

  get getTotalAmount(): number {

    let totalAmount: number = this.price;

    if (this.couponAmount) {

      totalAmount -= this.couponAmount;

      if (totalAmount < 0) totalAmount = 0;

    }

    return totalAmount;

  }

  get splittedPaymentWarningMessage() {

    switch (this.viewModel.nextPayment?.InstallmentIndex) {

      case 3:
      case 2:
        return '';
      case 1:
      default:
        return 'Attenzione: verrà addebitata adesso solo la prima rata (50%); la prossima dovrà essere saldata entro il 5 dicembre.';

    }

  }

}
