import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileModule } from './profile/profile.module';
import { DocumentsComponent } from './documents/documents.component';
import { FamiliarComponent } from './familiar/familiar.component';
import { RouterModule } from '@angular/router';
import { PurchasesModule } from './purchases/purchases.module';
import { CouponsModule } from './coupons/coupons.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'profile',
        loadChildren: () => ProfileModule,
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        data: {
          title: 'Documenti caricati',
          urls: [{ title: 'Documenti caricati', url: '/personal-area/documents' }, { title: 'Documenti caricati' }],
        },
      },
      {
        path: 'familiar',
        component: FamiliarComponent,
        data: {
          title: 'Genitore',
          urls: [{ title: 'Genitore', url: '/personal-area/familiar' }, { title: 'Genitore' }],
        },
      },
      {
        path: 'purchases',
        loadChildren: () => PurchasesModule,
      },
      {
        path: 'coupons',
        loadChildren: () => CouponsModule,
      },
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full',
      },
    ]),
  ],
})
export class PersonalAreaModule {
}


