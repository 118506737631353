import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AthletesListComponent } from './athletes-list.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { CtSkeletonModule } from '@ctsolution/ct-framework';
import { FlexModule } from '@angular/flex-layout';
import { AthletesFiltersModule } from './athletes-filters/athletes-filters.module';
import { AthleteCardModule } from './athlete-card/athlete-card.module';
import { AthleteBestTimesModule } from '../athlete-best-times/athlete-best-times.module';

@NgModule({
  declarations: [
    AthletesListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule
      .forChild([
        {
          path: '',
          children: [
            {
              path: 'best-times',
              loadChildren: () => AthleteBestTimesModule,
            },
            {
              path: '',
              component: AthletesListComponent,
              data: {
                title: 'Schede atleti',
                urls: [{
                  title: 'Schede atleti',
                  url: '/athletes',
                }, { title: 'Schede atleti' }],
              },
            },
          ],
        },
      ]),
    MatButtonModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyTabsModule,
    CtSkeletonModule,
    FlexModule,
    AthletesFiltersModule,
    AthleteCardModule,
  ],
})
export class AthletesListModule {
}
