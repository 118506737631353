import { Component, OnInit } from '@angular/core';
import { CouponController } from '../../../../core/controllers/coupon.controller';
import { SPNUserCouponDTO } from '../../../../core/interfaces/spn-user-coupon';

@Component({
  selector: 'app-coupons-list',
  templateUrl: './coupons-list.component.html',
  styleUrls: ['./coupons-list.component.scss'],
  providers: [CouponController],
})
export class CouponsListComponent implements OnInit {

  coupons: SPNUserCouponDTO[] = [];

  constructor(private couponController: CouponController) {
  }

  ngOnInit(): void {

    this.getCoupons();

  }

  getCoupons() {

    this.couponController
      .list()
      .then((result: SPNUserCouponDTO[]) => this.coupons = result);

  }

}
