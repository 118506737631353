import { AfterViewInit, Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { CurrentSeason } from '../../../core/lib/season.service';

@Component({
  selector: 'app-spn-daterange-filter',
  templateUrl: './spn-daterange-filter.component.html',
})
export class SpnDaterangeFilterComponent implements AfterViewInit {

  @Input() startDateControl: FormControl | null = null;
  @Input() endDateControl: FormControl | null = null;

  constructor() {
  }

  ngAfterViewInit() {

    setTimeout(() => {

      if (this.startDateControl?.value && this.endDateControl?.value) return;

      this.setCurrentSeason(true);

    }, 300);

  }

  setCurrentSeason(firstSetting: boolean = false) {

    const currentSeason = new CurrentSeason();

    const startDate = new Date(currentSeason.startYear ?? new Date().getFullYear(), 9, 1);
    const endDate = new Date(currentSeason.endYear ?? new Date().getFullYear(), 8, 30);

    if (!(this.startDateControl?.value && firstSetting)) {

      this.startDateControl?.setValue(startDate);
      this.startDateControl?.updateValueAndValidity();

    }

    this.endDateControl?.setValue(endDate);
    this.endDateControl?.updateValueAndValidity();

  }

  resetRangeDate(startControl: AbstractControl | null, endControl: AbstractControl | null): void {

    startControl?.setValue(null);
    startControl?.updateValueAndValidity();

    endControl?.setValue(null);
    endControl?.updateValueAndValidity();

  }

}
