import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HtmlService {

  constructor() {
  }

  private isElementVisible(element: HTMLElement): boolean {

    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    return rect.top >= 0 && rect.bottom <= windowHeight;

  }

  scrollToElement(element?: ElementRef | null) {

    const reference = element?.nativeElement;

    if (reference) {

      reference.scrollIntoView({ behavior: 'smooth' });

    }

  }

}
