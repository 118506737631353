export enum AthleteCategoryEnum {

  RAGAZZI14 = 0,
  RAGAZZI1 = 1,
  RAGAZZI2 = 2,
  JUNIORES1 = 3,
  JUNIORES2 = 4,
  CADETTI1 = 5,
  CADETTI2 = 6,
  SENIORES1 = 7,
  SENIORES2 = 8,
  ASSOLUTI = 9

}
