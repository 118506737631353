<div class='checkout-container container'>

  <app-checkout-payment-message
    *ngIf='indirectSelectedPaymentType; else checkOutViewTemplate'
    [paymentMethod]='indirectSelectedPaymentType'
    [checkOutPaymentData]='data'
  ></app-checkout-payment-message>

  <ng-template #checkOutViewTemplate>

    <h2> Checkout </h2>

    <app-checkout-recap
      [data]='data'
      [couponAmount]='checkOutPayment.form.get("ApplyCoupon")?.value ? couponAvailableAmount : 0'></app-checkout-recap>

    <app-checkout-payment
      #checkOutPayment
      [checkOutPaymentData]='data'
      [couponAmount]='couponAvailableAmount'
      (onPaymentClosed)='setPayment($event)'
    ></app-checkout-payment>

  </ng-template>

  <div class='text-center'>

    <button
      class='m-t-50'
      mat-raised-button
      color='primary' (click)='close()'>
      Torna {{ !indirectSelectedPaymentType ? 'indietro' : 'alla home' }}
    </button>

  </div>

</div>
