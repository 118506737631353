<div class='main-container' [dir]='dir'>

  <mat-toolbar color='primary' class='topbar relative'>

    <div class='w-100 fix-width-for-horizontal'>

      <button mat-icon-button (click)='snav.toggle()' class='sidebar-toggle' value='sidebarclosed'>
        <mat-icon>menu</mat-icon>
      </button>

      <span fxFlex></span>

      <div class='navbar-header'>

        <a class='navbar-brand' href='/'>
          <span class='logo-icon'>
                        <img src='assets/images/logo_superbanuoto_icon.svg' alt='homepage' class='dark-logo' />
                        <img src='assets/images/logo_superbanuoto_icon.svg' alt='homepage' class='light-logo' />
                    </span>
        </a>
      </div>

      <span fxFlex></span>

      <div>

        <app-vertical-header></app-vertical-header>

      </div>

    </div>

  </mat-toolbar>

  <mat-sidenav-container class='example-sidenav-container' [style.marginTop.px]='mobileQuery.matches ? 0 : 0'>

    <mat-sidenav
      #snav
      id='snav'
      class='pl-xs'
      fixedTopGap='0'
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      [opened]='mobileQuery.matches'
      [disableClose]='mobileQuery.matches'
      (opened)='sidebarOpened = true'
      (close)='sidebarOpened = false'>

      <perfect-scrollbar>

        <app-vertical-sidebar class='app-sidebar' (notify)='snav.toggle()'></app-vertical-sidebar>

      </perfect-scrollbar>

    </mat-sidenav>

    <mat-sidenav-content class='page-wrapper'>

      <div class='page-container'>
        <div class='page-content'>
          <router-outlet></router-outlet>
        </div>
      </div>

    </mat-sidenav-content>

  </mat-sidenav-container>

</div>
