import { Athlete } from '../../../../core/classes/athlete';

export class AthleteCardConfiguration {

  get info(): Athlete | null {
    return this._info;
  }

  private set info(value: Athlete | null) {
    this._info = value;
  }

  private _info: Athlete | null = null;

  private constructor() {
  }

  public static create = (): AthleteCardConfiguration => new AthleteCardConfiguration();

  setInfo(value: Athlete | null): AthleteCardConfiguration {

    this.info = value;
    return this;

  }

}
