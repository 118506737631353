import { Component } from '@angular/core';
import { ProductListConfiguration } from '../../../_components/product-list/product-list.configuration';
import { ProductCategoryMacroTypeEnum } from '../../../core/enums/product-category-macro-type.enum';

@Component({
  selector: 'app-market-place-list',
  templateUrl: './market-place-list.component.html',
  styleUrls: ['./market-place-list.component.scss']
})
export class MarketPlaceListComponent {

  marketPlace: ProductListConfiguration = ProductListConfiguration.create(ProductCategoryMacroTypeEnum.Merchandising);

  constructor() { }

}
