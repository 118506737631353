import { Component, Input, ViewChild } from '@angular/core';
import { CtDatatableResult } from '@ctsolution/ct-framework';
import { SPNEventProduct } from '../../../../core/classes/SPNEvent';
import { EventListFilterDTO } from '../../../../core/controllers/event.controller';
import { EventFiltersComponent } from './event-filters/event-filters.component';


@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent {

  get events(): Array<SPNEventProduct> | null {
    return this._events;
  }

  private set events(value: Array<SPNEventProduct> | null) {
    this._events = value;
  }

  @Input() caller!: ((params?: EventListFilterDTO) => Promise<CtDatatableResult<SPNEventProduct>>);
  @ViewChild('filters') eventFiltersComponent!: EventFiltersComponent;

  private _events: Array<SPNEventProduct> | null = null;

  viewModel = {

    loadingList: true,

  };

  constructor() {
  }

  ngAfterViewInit(): void {

    this.eventFiltersComponent.form.get('StartDate')?.setValue(new Date());

  }

  getList(params: EventListFilterDTO = new EventListFilterDTO()): void {

    params = this.eventFiltersComponent.setFilters(params);

    this.viewModel.loadingList = true;

    this.caller(params)
      .then((result: CtDatatableResult<SPNEventProduct>) => {

        this.events = result.Items;

        setTimeout(() => this.viewModel.loadingList = false, 500);

      });

  }

}

