import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { CTDatatablePaginationParameter, CtDatatableResult } from '@ctsolution/ct-framework';
import { EventListFilterDTO } from './event.controller';
import { AthleteTimeResult } from '../interfaces/athlete-time-result';

const QUALIFICATIONS_CONTROLLER = ['Qualification'];

const STANDARD_REQUEST = (): DataRequest => DataRequest
  .create()
  .setController(QUALIFICATIONS_CONTROLLER);

@Injectable({
  providedIn: 'root',
})
export class QualificationsController {

  constructor(private webapi: CtWebapiService) {
  }

  getList(filters?: EventListFilterDTO, pagination: CTDatatablePaginationParameter = new CTDatatablePaginationParameter()): Promise<CtDatatableResult<AthleteTimeResult>> {

    return new Promise<CtDatatableResult<AthleteTimeResult>>((resolve) => {

      let params: any = {
        ...filters,
        ...pagination,
        hideSpinner: true,
      };

      params = Object.fromEntries(Object.entries(params).filter(([key, value]) => (!!value || value === 0)));

      const request = STANDARD_REQUEST()
        .setAction('GetList')
        .setQueryParams(params);

      this.webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<CtDatatableResult<AthleteTimeResult>>) => resolve(response.Result));

    });

  }

}
