import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

import { VerticalAppHeaderComponent } from './layouts/full/vertical-header/vertical-header.component';
import { VerticalAppSidebarComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CtAuthenticationModule, CtAuthenticationSetup, FcmConfiguration } from '@ctsolution/ct-authentication';
import { environment } from 'src/environments/environment';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CtWebapiModule, CtWebapiSetup } from '@ctsolution/ct-webapi';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { UserProfileImageModule } from './_components/user-profile-image/user-profile-image.module';

import localeIT from '@angular/common/locales/it';
import { MatBadgeModule } from '@angular/material/badge';
import { ProfileController } from './core/controllers/profile.controller';
import { CtFileManagementService } from '@ctsolution/ct-file-management';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ApplicationConfig, CtWebviewModule } from '@ctsolution/ct-webview';
import { ApiCacheInterceptor } from './core/lib/api-cache.interceptor';

registerLocaleData(localeIT);

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const AUTHENTICATION_CONFIG = CtAuthenticationSetup
  .create(environment.projectName, environment.API_URL)
  .setRedirectUrl('/authentication/login')
  .setFCMConfiguration(
    FcmConfiguration
      .create()
      .setEnabled(true),
  );

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

export const IUBENDA_CONFIG = {

  TermsAndConditions: {
    label: 'Termini e condizioni',
    url: 'https://www.iubenda.com/termini-e-condizioni/43141214',
  },
  PrivacyPolicy: {
    label: 'Privacy Policy',
    url: 'https://www.iubenda.com/privacy-policy/43141214',
  },
  CookiePolicy: {
    label: 'Cookie Policy',
    url: 'https://www.iubenda.com/privacy-policy/43141214/cookie-policy',
  },

};

const APPLICATION_CONFIG: ApplicationConfig = {
  Android: {
    package: 'it.superbanuoto.app',
  },
  Apple: {
    scheme: 'superbanuoto',
    storeLink: 'https://apps.apple.com/it/app/superbanuoto',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    VerticalAppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    VerticalAppSidebarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    MatProgressSpinnerModule,
    SharedModule,
    CommonModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(AppRoutes, {}),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CtWebviewModule.setup(APPLICATION_CONFIG),
    CtWebapiModule.setup(
      CtWebapiSetup
        .create(environment.API_URL),
    ),
    CtAuthenticationModule.setup(AUTHENTICATION_CONFIG),
    UserProfileImageModule,
    MatBadgeModule,
    NgxWebstorageModule.forRoot()
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    ProfileController,
    CtFileManagementService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiCacheInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [SpinnerComponent],
})
export class AppModule {
}
