import { Component, Input } from '@angular/core';
import { SPNUserCouponDTO } from '../../../../../core/interfaces/spn-user-coupon';

@Component({
  selector: 'app-user-coupons',
  templateUrl: './user-coupons.component.html',
  styleUrls: ['./user-coupons.component.scss'],
})
export class UserCouponsComponent {

  @Input() list: SPNUserCouponDTO[] = [];

  constructor() {
  }

  couponExpired(coupon: SPNUserCouponDTO): boolean {

    let expired: boolean = false;

    if (coupon.ExpireDate) {

      return new Date(coupon.ExpireDate).getTime() < new Date().getTime();

    }

    return expired;

  }

}
