import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EnumHelper } from '../../../core/lib/enum.helper';
import { AthleteCategoryEnum } from '../../../core/enums/athlete-category.enum';

@Component({
  selector: 'app-spn-athlete-category-filter',
  templateUrl: './spn-athlete-category-filter.component.html',
})
export class SpnAthleteCategoryFilterComponent {

  @Input() control: FormControl | null = null;
  @Input() multiple: boolean | null = null;

  values;

  constructor(private enumHelper: EnumHelper) {

    this.values = this.enumHelper.getEnumValues(AthleteCategoryEnum);

  }

  getLabel = (value: string) => this.enumHelper.getEnumLabel(AthleteCategoryEnum, value);


}
