import { Component, OnInit } from '@angular/core';
import {
  GlobalNotificationController,
  SPNNotification,
} from '../../../core/controllers/global-notification.controller';
import {
  NotificationsWidgetConfiguration,
} from '../../../_components/notifications-widget/notifications-widget.configuration';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

  notificationsWidgetConfiguration: NotificationsWidgetConfiguration = NotificationsWidgetConfiguration.create();

  constructor(private notificationsController: GlobalNotificationController) {
  }

  ngOnInit() {

    this.notificationsController
      .getGeneralList()
      .then((result: SPNNotification[]) => this.notificationsWidgetConfiguration.setList(result));

  }

}
