<ng-container
  *ngIf='paymentMethod'
  [ngSwitch]='paymentMethod'>

  <div *ngSwitchCase='PAYMENT_METHOD.Bank'>

    <h2> Grazie per aver effettuato il pagamento </h2>

    <p>
      Hai scelto di optare per il pagamento tramite bonifico bancario.<br />L'operazione e il rilascio della ricevuta
      verranno confermati non appena avvenuta la conferma di accredito.<br />Potrai trovare nella tua area riservata
      dalla App la ricevuta di pagamento.
    </p>

    <ng-container *ngIf='viewModel.checkOutContainsMerchandising'>

      <p>Una volta confermato il tuo ordine di materiale verrà inoltrato via mail alla segreteria e al
        nostro fornitore.</p>

    </ng-container>

  </div>

  <div *ngSwitchCase='PAYMENT_METHOD.BankTransferSplitted2'>

    <h2> Grazie per aver effettuato il pagamento </h2>

    <p>
      Hai scelto di optare per il pagamento rateale.<br />L'operazione e il rilascio della ricevuta
      verranno confermati non appena avvenuta la conferma di accredito.<br />
      Ti ricordiamo che le due rate sono entrambe del 50% con un supplemento di 25€ cadauna.
    </p>

  </div>

  <div *ngSwitchCase='PAYMENT_METHOD.Financig'>

    <h2> Grazie </h2>
    <p>

      La richiesta di pagamento mediante finanziamento è stata presa in carico ed è stata inviata una mail alla tua segreteria di riferimento. <br>
      Verrai ricontattato quanto prima per il completamento della richiesta.

    </p>

  </div>

</ng-container>
