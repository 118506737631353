import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CtAuthenticationService, SignInParameter } from '@ctsolution/ct-authentication';
import { GeneralService } from 'src/app/core/lib/general.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public form: FormGroup = Object.create(null);

  hidePassword = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private ctAuthenticationService: CtAuthenticationService,
    private formBuilder: FormBuilder,
    private generalService: GeneralService
  ) {

    if (this.ctAuthenticationService.userValue) {

      this.generalService.navigateToRoot();

    }

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      Email: [null, Validators.compose([Validators.required])],
      Password: [null, Validators.compose([Validators.required])],
      RememberMe: [false],
    });
  }

  onSubmit(): void {

    if (this.form.valid) {

      this.ctAuthenticationService
        .signIn(this.form.value as SignInParameter)
        .subscribe(() => this.generalService.navigateToRoot());

    }

  }

}
