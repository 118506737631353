import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialRankingsFiltersComponent } from './special-rankings-filters.component';
import { FlexModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { PaginatorModule } from 'primeng/paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { SpnFiltersModule } from '../../../../_components/spn-filters/spn-filters.module';
import {
  NotificationEventSelectControlModule
} from '../../../notifications/generate-notification/generate-notification-form-dialog/notification-event-select-control/notification-event-select-control.module';


@NgModule({
  declarations: [
    SpecialRankingsFiltersComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    MatExpansionModule,
    MatLegacyCheckboxModule,
    PaginatorModule,
    ReactiveFormsModule,
    SpnFiltersModule,
    NotificationEventSelectControlModule,
  ],
  exports: [SpecialRankingsFiltersComponent],
})
export class SpecialRankingsFiltersModule {
}
