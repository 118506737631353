import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/lib/user.service';

@Component({
  selector: 'app-user-profile-image',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.scss'],
})
export class UserProfileImageComponent implements OnInit {

  userInitial = 'SPN';

  constructor(private userService: UserService) {
  }

  ngOnInit() {

    this.setup();

  }

  async setup() {

    const userName = await this.userService.getUserName();
    const userSurname = await this.userService.getUserSurname();

    if (userName && userSurname) {

      this.userInitial = `${userName[0].toUpperCase()}${userSurname[0].toUpperCase()}`;

    }

  }

}
