<div class='event-card-info-container'>

  <p>

    {{ product?.EventProduct?.TrophyDescription ?? ''}}

  </p>

  <div fxLayout='row wrap'>

    <div fxFlex='35' *ngIf='product?.EventProduct?.StartDate'>

      <img src='/assets/images/events/icons/calendar.svg' />

      <div class='gara-info'>

        {{ product?.EventProduct?.StartDate | date:'dd/MM/yy' }}

      </div>

    </div>

    <div fxFlex='50' *ngIf='product?.EventProduct?.SportFacility?.Description'>

      <img src='/assets/images/events/icons/place.svg' />

      <div class='gara-info'>

        {{ product?.EventProduct?.SportFacility?.Description ?? '' }} {{ product?.EventProduct?.SportFacility?.City}}

      </div>

    </div>

    <div fxFlex='15' *ngIf='product?.EventProduct?.PoolLength !== null'>

      <img src='/assets/images/events/icons/pool.svg' />

      <div class='gara-info'>

        {{ viewModel.poolLength }}m

      </div>

    </div>

  </div>

  <div
    *ngIf='viewModel.subscribable'
    fxLayout='row'>

    <div fxFlex='50' fxLayoutAlign='start center'>

      <small *ngIf='product?.UrlEventPosterDocument' (click)='download.emit()'
             class='discover-more'>

        Scopri di più

      </small>

    </div>
    <div fxFlex='50' fxLayoutAlign='end center'>

      <small *ngIf='product?.UrlEventTimeLimitDocument' class='discover-more'
             (click)='downloadLimitTimes.emit()'>Tempi limite</small>

    </div>

  </div>

</div>
