import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DemoMaterialModule } from '../demo-material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PagesRoutes } from './pages.routing';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ChangePasswordModule } from './personal-area/profile/change-password/change-password.module';
import { ProfileComponent } from './personal-area/profile/profile.component';
import { ProductController } from '../core/controllers/product.controller';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { DocumentsModule } from './personal-area/documents/documents.module';
import { FamiliarModule } from './personal-area/familiar/familiar.module';
import { UserProfileImageModule } from '../_components/user-profile-image/user-profile-image.module';
import { PrivacyPolicyModule } from './personal-area/profile/privacy-policy/privacy-policy.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(PagesRoutes),
    DemoMaterialModule,
    FlexLayoutModule,
    FormsModule,
    MatListModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    ChangePasswordModule,
    FamiliarModule,
    DocumentsModule,
    UserProfileImageModule,
    PrivacyPolicyModule,
  ],
  declarations: [
    ProfileComponent,
    ThankYouPageComponent,
  ],
  providers: [ProductController],
})
export class PagesModule {
}
