import { Injectable } from '@angular/core';
import { GenerateNotificationFormDialogComponent } from './generate-notification-form-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SPNNotification } from '../../../../core/controllers/global-notification.controller';

@Injectable()
export class GenerateNotificationFormDialogService {

  constructor(private dialog: MatDialog) {
  }

  open = (data?: SPNNotification) => this.dialog.open(GenerateNotificationFormDialogComponent, { data });

}
