import { Component, Input, OnInit } from '@angular/core';
import { SPNNotification } from '../../../core/controllers/global-notification.controller';

@Component({
  selector: 'app-notification-sender',
  template: `{{sender}}`,
})
export class NotificationSenderComponent implements OnInit {

  @Input() notification: SPNNotification | null = null;
  sender: string | null = null;

  ngOnInit() {

    this.sender = this.setup();

  }

  setup() {

    if (this.notification?.OwnerIsSuperbaNuoto) {

      return 'SuperbaNuoto';

    }

    if (this.notification?.OwnerUserInfoGetFullName) {

      return this.notification.OwnerUserInfoGetFullName;

    }

    return null;

  }

}
