import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RecordType } from '../../../core/classes/record-filter';
import { EnumHelper } from '../../../core/lib/enum.helper';

@Component({
  selector: 'app-spn-record-type-filter',
  templateUrl: './spn-record-type-filter.component.html'
})
export class SpnRecordTypeFilterComponent {

  @Input() control: FormControl | null = null;

  values;

  constructor(private enumHelper: EnumHelper) {

    this.values = this.enumHelper.getEnumValues(RecordType);

  }

  getLabel = (value: string) => this.enumHelper.getEnumLabel(RecordType, value);

}
