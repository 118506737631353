import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SPNNotification } from '../../../core/controllers/global-notification.controller';

@Component({
  selector: 'app-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss'],
})
export class NotificationListItemComponent {

  @Input() notification: SPNNotification | null = null;
  @Input() technicalSentView: boolean | null = null;
  @Output() onEdit: EventEmitter<SPNNotification> = new EventEmitter<SPNNotification>();
  @Output() onDelete: EventEmitter<SPNNotification> = new EventEmitter<SPNNotification>();
  @Output() onDownload: EventEmitter<SPNNotification> = new EventEmitter<SPNNotification>();

  getBadgeValue(): string | null {

    return this.missingReadCount() <= 0 ? 'Letto' : `Letture: ${this.notification?.GlobalNotificationTrackingsRead}/${this.notification?.GlobalNotificationTrackingsTotal}`;

  }

  missingReadCount = (): number => (this.notification?.GlobalNotificationTrackingsTotal ?? 0) - (this.notification?.GlobalNotificationTrackingsRead ?? 0);

}
