import { ChangeDetectorRef, Component, OnDestroy, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { UserService } from '../../../core/lib/user.service';

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: [],
})
export class VerticalAppSidebarComponent implements OnInit, OnDestroy {

  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  // tslint:disable-next-line - Disables all
  private readonly _mobileQueryListener: () => void;
  status = true;
  showMenu = '';
  itemSelect: number[] = [];

  viewModel: any = {

    userName: null,
    menuItems: [],

  };

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private menuItems: MenuItems,
    private user: UserService,
  ) {

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnInit() {

    this.setup();

  }

  async setup() {

    const userName = await this.user.getUserName();

    if (!userName) return;

    this.viewModel.userName = userName;

    this.viewModel.menuItems = await this.menuItems.getMenuitem() ?? [];

  }

  addExpandClass(element: any): void {

    this.showMenu = element === this.showMenu ? '0' : element;

  }

  ngOnDestroy(): void {

    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

  handleNotify() {

    if (window.innerWidth < 1024) {

      this.notify.emit(!this.showClass);

    }

  }

}
