export const MenuDefaultItems = [
  {
    state: 'home',
    name: 'Home',
    icon: 'home',
    type: 'link',
  },
  {
    state: 'subscriptions',
    name: 'Tesseramento e Quota',
    icon: 'card_membership',
    type: 'link',
  },
  {
    state: 'competitions-and-events',
    name: 'Gare ed Eventi',
    type: 'sub',
    icon: 'workspace_premium',
    children: [
      { state: 'upcoming', name: 'Calendario gare ed eventi', type: 'link' },
      { state: 'my-competitions', name: 'Mie Gare', type: 'link' },
      { state: 'historical', name: 'Storico', type: 'link' },
      { state: 'record', name: 'Record', type: 'link' },
    ],
  },
  {
    state: 'market-place',
    name: 'Marketplace',
    icon: 'storefront',
    type: 'link',
  },
  {
    state: 'personal-area',
    name: 'Area Personale',
    type: 'sub',
    icon: 'person',
    children: [
      { state: 'profile', name: 'Profilo', type: 'link' },
      { state: 'documents', name: 'Documenti', type: 'link' },
      { state: 'familiar', name: 'Genitore', type: 'link' },
      { state: 'purchases', name: 'I miei acquisti', type: 'link' },
      { state: 'coupons', name: 'I miei coupon', type: 'link' },
    ],
  },
  {
    state: 'notifications',
    name: 'Notifiche',
    icon: 'mark_unread_chat_alt',
    type: 'link',
  },
];
