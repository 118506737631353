import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegistrationStep1Component} from './registration-step1.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {FlexLayoutModule} from "@angular/flex-layout";
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    RegistrationStep1Component
  ],
  exports: [
    RegistrationStep1Component
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FlexLayoutModule,
    MatSelectModule,
    MatIconModule
  ]
})
export class RegistrationStep1Module {
}
