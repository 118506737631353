import { SPNProductQuoteDTO } from '../../../core/interfaces/spn-product-quote';
import { UserCart } from '../../../core/interfaces/user-cart';

export class CheckOutPaymentData {

  fullCart = false;
  splittedPayment = false;
  product: SPNProductQuoteDTO | null = null;

  /**
   * Questo campo non è necessario all'api, ma necessario al FE per sapere quali elementi ho evaso in caso di bonifico o checkout diretto del carello
   * */
  UserCart?: UserCart;

  constructor() {
  }

}
