import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiCacheService {

  private cache: { [key: string]: any } = {};

  save(endpoint: string, data: any): void {
    this.cache[endpoint] = data;
  }

  get(endpoint: string): any {
    return this.cache[endpoint];
  }

  clear(endpoint?: string): void {
    if (endpoint) {
      delete this.cache[endpoint];
    } else {
      this.cache = {};
    }
  }

}
