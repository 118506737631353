import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  CELL_TYPE,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter,
  CtDatatableResult,
} from '@ctsolution/ct-framework';
import { EventListFilterDTO } from '../../../core/controllers/event.controller';
import { TableColumn } from '@swimlane/ngx-datatable';
import { SpecialRankingsController } from '../../../core/controllers/special-rankings.controller';
import { SpecialRankingsFiltersComponent } from './special-rankings-filters/special-rankings-filters.component';
import { AthleteTimeResult } from '../../../core/interfaces/athlete-time-result';
import { AthleteCategoryEnum } from '../../../core/enums/athlete-category.enum';
import { EnumHelper } from '../../../core/lib/enum.helper';

@Component({
  selector: 'app-special-rankings',
  templateUrl: './special-rankings.component.html',
})
export class SpecialRankingsComponent {

  @ViewChild('filters') specialRankingsFilters!: SpecialRankingsFiltersComponent;
  @ViewChild('descriptionTemplate') public descriptionCellTemplate!: TemplateRef<any>;
  @ViewChild('rankingTemplate') public rankingCellTemplate!: TemplateRef<any>;

  specialRankings: CtDatatableConfiguration | undefined;

  private params: EventListFilterDTO = new EventListFilterDTO();

  constructor(private specialRankingsController: SpecialRankingsController, private enumHelper: EnumHelper) {
  }

  ngAfterViewInit(): void {

    const columns: TableColumn[] = [
      {
        prop: 'TimeDate',
        name: 'TimeDate',
        cellTemplate: this.rankingCellTemplate,
      },
      {
        prop: 'TimeLimit',
        name: '',
        cellTemplate: this.descriptionCellTemplate,
        sortable: false,
        width: 100,
      },
    ];

    this.specialRankings = CtDatatableConfiguration
      .create()
      .setExternalPaging(true)
      .setHeaderHeight(0)
      .setColumns(columns);

  }

  getCategoryLabel = (value: string) => this.enumHelper.getEnumLabel(AthleteCategoryEnum, value);

  fetch(pagination?: CTDatatablePaginationParameter): void {

    this.params = this.specialRankingsFilters.setFilters(this.params);

    this.specialRankings?.setLoadingIndicator(true);

    this.specialRankingsController
      .getList(this.params, pagination)
      .then((result: CtDatatableResult<AthleteTimeResult>) => {

        this.specialRankings
          ?.setCount(result.ItemTotal)
          .setRows(result.Items)
          .setLoadingIndicator(false);

      });

  }

}
