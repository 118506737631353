import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CtDatatableConfiguration, CTDatatablePaginationParameter, CtDatatableResult } from '@ctsolution/ct-framework';
import { EventFiltersComponent } from '../_components/event-list/event-filters/event-filters.component';
import { EventController, EventListFilterDTO } from '../../../core/controllers/event.controller';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TableColumn } from '@swimlane/ngx-datatable';
import { SPNEventProduct } from '../../../core/classes/SPNEvent';
import { EventFilterStatus } from '../../../core/enums/event-filter-status';
import {
  HistoricalEventDetailComponent,
  HistoricalEventDetailConfiguration,
} from './historical-event-detail/historical-event-detail.component';
import {
  CompareSpecialtyTrend,
} from '../_components/event-card/event-card/event-results-list/event-results-list/event-results-list.component';
import { HistoricalChartComponent } from './historical-chart/historical-chart.component';

@Component({
  selector: 'app-historical',
  templateUrl: './historical.component.html',
  styleUrls: ['./historical.component.scss'],
})
export class HistoricalComponent {

  @ViewChild('filters') eventFiltersComponent!: EventFiltersComponent;
  @ViewChild('dateTemplate') public dateCellTemplate!: TemplateRef<any>;
  @ViewChild('timeTemplate') public timeCellTemplate!: TemplateRef<any>;
  @ViewChild('historicalChart') historicalChart: HistoricalChartComponent | null = null;

  historical: CtDatatableConfiguration | undefined;

  private params: EventListFilterDTO = new EventListFilterDTO();

  get hasActiveFilters(): boolean {

    const value: any = this.eventFiltersComponent?.form.value;

    if (value) {

      return !!value.StartDate || !!value.EndDate || !!value.SwimStyle || !!value.Distance || !!value.PoolLength;

    }

    return false;

  }

  constructor(private eventController: EventController, private bottomSheet: MatBottomSheet) {
  }

  ngAfterViewInit(): void {

    const columns: TableColumn[] = [{
      prop: 'EventProduct.StartDate',
      name: 'Data',
      cellTemplate: this.dateCellTemplate,
    }, {
      prop: 'EventProduct.RaceResultList',
      name: 'Tempo',
      cellTemplate: this.timeCellTemplate,
      cellClass: 'text-right',
    }];

    this.historical = CtDatatableConfiguration
      .create()
      .setExternalPaging(true)
      .setHeaderHeight(0)
      .setColumns(columns)
      .setDetailActionEnabled(true)
      .setLimit(2000); //dopo allineamento interno abbiamo definito che non deve essere possibile inserire un numero nullo (per annullare la paginazione) quindi lasciamo un numero grande come offset di caricamento

  }

  filter(event: any): void {

    this.params = event ?? {};

    this.fetch();

  }

  fetch(pagination?: CTDatatablePaginationParameter): void {

    this.params = this.eventFiltersComponent.setFilters(this.params);

    this.params.Status = EventFilterStatus.History;

    this.historical?.setLoadingIndicator(true);

    this.eventController
      .getList(this.params, pagination)
      .then((result: CtDatatableResult<SPNEventProduct>) => {

        this.historical?.setCount(result.ItemTotal)
          .setRows(result.Items)
          .setLoadingIndicator(false);

        this.historicalChart?.setupChart(result.Items, this.eventFiltersComponent.form.value);

      });

  }

  resetFilters(): void {

    this.eventFiltersComponent.form.reset();

  }

  detail(eventProduct: SPNEventProduct): void {

    const configuration: HistoricalEventDetailConfiguration = HistoricalEventDetailConfiguration
      .create(eventProduct);

    const value: any = this.eventFiltersComponent?.form.value;

    if (value.SwimStyle && value.Distance) {

      configuration.filters.setSwimStyle(value.SwimStyle);
      configuration.filters.setDistance(value.Distance);

    }

    this.bottomSheet.open(HistoricalEventDetailComponent, {
      data: configuration,
      panelClass: 'bottom-sheet-event-detail-cntr',
    }).afterDismissed()
      .subscribe((result?: CompareSpecialtyTrend) => {

        if (result) {

          this.eventFiltersComponent.setTrend(result);

        }

      });

  }

}
