<div class='event-splitted-times-cntr'>

  <div fxLayout='row wrap'>

    <ng-container *ngIf='!result?.EventProductSplitTimes.length; else partialTimeList'>

      <p>Non sono stati caricati tempi parziali per questo evento.</p>

    </ng-container>

    <ng-template #partialTimeList>

      <ng-container *ngFor='let time of result?.EventProductSplitTimes'>

        <div fxFlex='50' class='box-result'>

          <h4><strong>{{time.Name}}</strong></h4>

          <p class='justify-result' *ngIf='time.PartialTimeForGrid'><span>Parziale:</span>
            <i>{{ time.PartialTimeForGrid | splittedTimeFormat }}</i></p>

          <p class='justify-result delta' *ngIf='time.PartialTimeDifferenceForGrid'><span>Delta:</span>
            <i
              [class.minus]='time.PartialTimeDifference < 0'>{{time.PartialTimeDifferenceForGrid | splittedTimeFormat:{ partial: time.PartialTimeDifference < 0 ? '-' : '+' } }}</i>
          </p>

          <p class='justify-result' *ngIf='time.TimeForGrid'><span>Totale:</span>
            <i>{{time.TimeForGrid | splittedTimeFormat }}</i></p>

        </div>

        <ng-container *ngIf='time.PartialTime100ForGrid'>

          <div fxFlex='100'>

            <div class='partial-time-100 text-right'><span>Parziale su 100m:</span>&nbsp;
              <i>{{ time.PartialTime100ForGrid | splittedTimeFormat }}</i>
            </div>
            <mat-divider></mat-divider>

          </div>

        </ng-container>

      </ng-container>

    </ng-template>

  </div>

</div>
