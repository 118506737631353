import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { SPNProductQuoteDTO } from '../../../core/interfaces/spn-product-quote';
import { ProductCategoryMacroTypeEnum } from '../../../core/enums/product-category-macro-type.enum';
import { ProductListService } from '../product-list.service';
import { EventActionsEnum } from './event-actions/event-actions.enum';
import { ProductController } from '../../../core/controllers/product.controller';
import { AddToCartDTO } from '../../../core/classes/add-to-cart';
import { PurchaseDTO } from '../../../core/classes/purchase';
import { CartPaymentMethodEnum } from '../../../core/enums/cart-payment-method.enum';
import { StripeService } from '../../../core/lib/stripe.service';
import { SPNProductDTO } from '../../../core/interfaces/spn-product';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SpnVariation } from '../../../core/interfaces/spn-variation';
import { CartController } from '../../../core/controllers/cart.controller';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
  providers: [ProductController, CartController],
})
export class ProductItemComponent {

  @Input() item!: SPNProductQuoteDTO;
  form: FormGroup;

  ProductCategoryMacroTypes = ProductCategoryMacroTypeEnum;

  constructor(
    private productListService: ProductListService,
    private productController: ProductController,
    private stripeService: StripeService,
    private cartController: CartController,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef) {

    this.form = this.fb.group({});

  }

  ngAfterViewInit() {

    this.setProductFormControls();

  }

  getCoverImage(product: SPNProductDTO): string | null {

    return product.FilePreviewUrls[0] ?? null;

  }

  setProductFormControls() {

    this.item
      .Product
      .forEach((product: SPNProductDTO) => {

        if (product.Optional && this.item.MainProductCategoryMacroType !== ProductCategoryMacroTypeEnum.Subscription) {

          product.AmountControl = new FormControl(1);

          this.form.addControl('AmountControl', product.AmountControl);
          product.AmountControl.setParent(this.form);

        }

        if (product.Variations && product.Variations.length > 0) {

          product.Variations.forEach((element: SpnVariation, idx: number) => {

            element.VariationControl = new FormControl(null, Validators.required);

            this.form.addControl(`${product.Name.replace(/\s/g, '')}_Control${idx}_${element.Name.replace(/\s/g, '')}`, element.VariationControl);
            element.VariationControl.setParent(this.form);

          });

        }

      });

    this.form.updateValueAndValidity();
    this.cdr.detectChanges();

  }

  handleGenericAction(action: EventActionsEnum) {

    if (this.form.invalid) {

      this.form.markAllAsTouched();
      return;

    }

    switch (action) {

      case EventActionsEnum.DIRECT_PAYMENT: // disabilitato al momento, tutti gli acquisti (tranne le subscription) passerranno dal carrello

        const parameter: PurchaseDTO = this.productListService.getPurchasableProductObject(CartPaymentMethodEnum.Stripe, this.item, { FiscalCode: '' });

        this.productController
          .directPurchase(parameter)
          .then((result: any) => {

            this.stripeService.handleCheckOutForm(result.Result);

          });

        break;

      case EventActionsEnum.ADD_TO_CART:

        const items: AddToCartDTO[] = this.productListService.getCartItemsByProduct(this.item);

        // appendo le Variations dei prodotti senza prezzo al loro prodotto
        const childProductsOidWithVariations: number[] = this.item.Product
          .filter((product: SPNProductDTO) => !product.Quote)
          .map((product: SPNProductDTO) => product.Oid);

        const parentItem: SPNProductDTO | undefined = this.item.Product.find((product: SPNProductDTO) => product.Quote);

        if (parentItem && childProductsOidWithVariations.length > 0) {

          const variations: string = items
            .filter((product: AddToCartDTO) => !!product.Variations && childProductsOidWithVariations.includes(product.ProductOid))
            .map((product: AddToCartDTO) => product.Variations).join('|');

          const parentItemInCart: AddToCartDTO | undefined = items.find((product: AddToCartDTO) => product.ProductOid === parentItem.Oid);

          if (parentItemInCart) {

            if (!parentItemInCart.Variations) {

              parentItemInCart.Variations = '';

            }

            parentItemInCart.Variations += variations;

          }

        }

        this.productController
          .addToCart(items)
          .then(() => this.cartController
            .activeCartProductsCount());

        break;

    }

  }

  subscriptionCheckOut = (splittedPayment: boolean) => this.productListService.subscriptionCheckOut(this.item, splittedPayment);

}
