import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { CTDatatablePaginationParameter, CtDatatableResult } from '@ctsolution/ct-framework';
import { RecordFilter } from '../classes/record-filter';
import { Record } from '../interfaces/record';

const RECORD_CONTROLLER = 'Record';

@Injectable()
export class RecordController {

  constructor(private _webapi: CtWebapiService) {
  }

  getList(filters: RecordFilter = RecordFilter.create(), pagination: CTDatatablePaginationParameter = new CTDatatablePaginationParameter()): Promise<CtDatatableResult<Record>> {

    return new Promise<CtDatatableResult<Record>>((resolve) => {

      let params: any = {
        ...filters,
        ...pagination,
        hideSpinner: true,
      };

      params = Object.fromEntries(Object.entries(params).filter(([key, value]) => (!!value || value === 0)));

      const request: DataRequest = new DataRequest(`/${RECORD_CONTROLLER}/GetList`)
        .setQueryParams(params);

      this._webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<CtDatatableResult<Record>>) => {

          resolve(response.Result);

        });

    });

  }

}
