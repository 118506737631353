import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EventController, SwimStyleDTO } from '../../../core/controllers/event.controller';

@Component({
  selector: 'app-spn-specialty-filter',
  templateUrl: './spn-specialty-filter.component.html'
})
export class SpnSpecialtyFilterComponent implements OnInit {

  SWIM_STYLES: SwimStyleDTO[] = [];

  @Input() control: FormControl | null = null;

  get selectedSpecialtyName(): string | null {

    const swimStyle = this.control?.value;
    const swimStyleDTO = this.SWIM_STYLES.find(elm => elm.Oid === swimStyle);
    return swimStyleDTO?.Name ?? null;

  }

  constructor(private eventController: EventController) {
  }

  ngOnInit() {

    this.eventController
      .getSwimStyleList()
      .then(result => this.SWIM_STYLES = result);

  }

}
