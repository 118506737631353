import { Injectable } from '@angular/core';
import { DeleteProfileConfirmComponent } from './delete-profile-confirm.component';
import { CtProfileService } from '@ctsolution/ct-authentication';
import { SnackbarService } from '@ctsolution/ct-framework';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root',
})
export class DeleteProfileConfirmService {

  constructor(private dialog: MatLegacyDialog, private profileService: CtProfileService, private snackbar: SnackbarService) {
  }

  deleteProfile() {

    this.dialog
      .open(DeleteProfileConfirmComponent, { maxWidth: '500px' })
      .afterClosed()
      .subscribe(result => {

        if (result) {

          this.profileService
            .delete()
            .subscribe(() => this.snackbar.open('Il tuo profilo è stato disabilitato con successo!'));

        }

      });

  }

}
