import { Component, Input, OnInit } from '@angular/core';
import { SPNEventProduct } from '../../../../../../../core/classes/SPNEvent';

@Component({
  selector: 'app-event-convocations-list',
  templateUrl: './event-convocations-list.component.html',
  styleUrls: ['./event-convocations-list.component.scss'],
})
export class EventConvocationsListComponent implements OnInit {

  @Input() product: SPNEventProduct | null = null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
