import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCardComponent } from './event-card/event-card.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { EventCardInfoModule } from './event-card/event-card-info/event-card-info.module';
import { MatDividerModule } from '@angular/material/divider';
import { EventCardActionsModule } from './event-card/event-card-actions/event-card-actions.module';
import { SecurePipeModule } from '../../../../core/pipes/secure-pipe.module';
import { EventConvocationsListModule } from './event-card/event-convocations-list/event-convocations-list.module';
import {
  SplittedTimeFormatModule,
} from './event-card/event-results-list/splitted-time-format/splitted-time-format.module';
import { EventResultsListModule } from './event-card/event-results-list/event-results-list.module';

@NgModule({
  declarations: [
    EventCardComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    EventCardInfoModule,
    MatDividerModule,
    EventCardActionsModule,
    SecurePipeModule,
    EventConvocationsListModule,
    SplittedTimeFormatModule,
    EventResultsListModule,
  ],
  exports: [
    EventCardComponent,
  ],
})
export class EventCardModule {
}
