import { SpnVariation } from '../interfaces/spn-variation';
import { SPNProductDTO } from '../interfaces/spn-product';

export class AddToCartDTO {

  ProductOid: number;
  Quantity: number;
  Variations?: string;

  constructor(product: SPNProductDTO) {

    this.ProductOid = product.Oid;
    this.Quantity = product.AmountControl?.value ?? 1;

    if (product.Variations.length > 0) {

      this.Variations = `${product.Name} `;

      this.setVariations(product.Variations);

    }

  }

  setVariations(variations: SpnVariation[]) {

    this.Variations += variations.filter((variation: SpnVariation) => !!variation.VariationControl?.value)
      .map((variation: SpnVariation) => `${variation.Name} ${variation.VariationControl?.value}`).join(',');

  }

}
