import { FormControl, ValidatorFn } from '@angular/forms';
import { FileDTO } from 'src/app/core/interfaces/file';

export class DocumentBoxUploaderConfiguration {

  private _changeAction!: ICtEventFunction;
  public get changeAction(): ICtEventFunction {
    return this._changeAction;
  }

  private set changeAction(value: ICtEventFunction) {
    this._changeAction = value;
  }

  private _documentLabel?: string | undefined;
  public get documentLabel(): string | undefined {
    return this._documentLabel;
  }

  private set documentLabel(value: string | undefined) {
    this._documentLabel = value;
  }

  private _documentControl: FormControl = new FormControl();
  get documentControl(): FormControl {
    return this._documentControl;
  }

  private _documentRetroControl: FormControl = new FormControl();
  get documentRetroControl(): FormControl {
    return this._documentRetroControl;
  }

  private _dateControl: FormControl = new FormControl();
  get dateControl(): FormControl {
    return this._dateControl;
  }

  private _documentCodeControl: FormControl = new FormControl();
  get documentCodeControl(): FormControl {
    return this._documentCodeControl;
  }

  constructor() {
  }

  public static create = (fileTypeDto: FileDTO) => new DocumentBoxUploaderConfiguration();

  /* A method that takes an array of validators and sets them on the documentControl. */
  setDocumentControlValidators = (validators: ValidatorFn | ValidatorFn[]): DocumentBoxUploaderConfiguration => this.setValidators(this.documentControl, validators);
  setDocumentControlValue = (value: any): DocumentBoxUploaderConfiguration => this.setControlValue(this.documentControl, value);

  /* Setting the validators on the dateControl. */
  setDateControlValidators = (validators: ValidatorFn | ValidatorFn[]): DocumentBoxUploaderConfiguration => this.setValidators(this.dateControl, validators);
  setDateControlValue = (value: any): DocumentBoxUploaderConfiguration => this.setControlValue(this.dateControl, value);

  /* Setting the validators on the documentCodeControl. */
  setDocumentCodeControlValidators = (validators: ValidatorFn | ValidatorFn[]): DocumentBoxUploaderConfiguration => this.setValidators(this.documentCodeControl, validators);
  setDocumentCodeControlValue = (value: any): DocumentBoxUploaderConfiguration => this.setControlValue(this.documentCodeControl, value);

  /* Setting the validators on the documentRetroControl. */
  setDocumentRetroControlValidators = (validators: ValidatorFn | ValidatorFn[]): DocumentBoxUploaderConfiguration => this.setValidators(this.documentRetroControl, validators);
  setDocumentRetroControlValue = (value: any): DocumentBoxUploaderConfiguration => this.setControlValue(this.documentRetroControl, value);

  setDocumentLabel = (value: string): string => {
    this.documentLabel = value;
    return this.documentLabel;
  };

  onChange(changeEvent: ICtEventFunction): DocumentBoxUploaderConfiguration {
    this.changeAction = changeEvent;
    return this;
  }

  /**
   * It sets the validators for a given form control
   * @param {FormControl} control - FormControl - the control to set the validators on
   * @param {ValidatorFn | ValidatorFn[]} validators - ValidatorFn | ValidatorFn[]
   * @returns The DocumentBoxUploaderConfiguration object.
   */
  private setValidators(control: FormControl, validators: ValidatorFn | ValidatorFn[]): DocumentBoxUploaderConfiguration {

    control.setValidators(validators);
    control.updateValueAndValidity();

    return this;

  }

  private setControlValue(control: FormControl, value: any): DocumentBoxUploaderConfiguration {

    control.setValue(value);
    control.updateValueAndValidity();

    return this;

  }

}

export type ICtEventFunction = (event: any, genericString: string, date?: Date) => void;
