import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AthletesFiltersComponent } from './athletes-filters.component';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { SpnFiltersModule } from '../../../../_components/spn-filters/spn-filters.module';

@NgModule({
  declarations: [
    AthletesFiltersComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    FormsModule,
    MatExpansionModule,
    MatLegacyCheckboxModule,
    ReactiveFormsModule,
    SpnFiltersModule,
  ],
  exports: [
    AthletesFiltersComponent,
  ],
})
export class AthletesFiltersModule {
}
