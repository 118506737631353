import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum } from '@ctsolution/ct-webapi';
import { AthleteFilterParameter } from '../classes/athlete-filter-parameter';
import { Observable } from 'rxjs';
import { CTDatatablePaginationParameter, CtDatatableResult } from '@ctsolution/ct-framework';
import { Athlete } from '../classes/athlete';
import { AthleteBestTimes } from '../classes/athlete-best-times';
import { AthleteTimeResult } from '../interfaces/athlete-time-result';

const USER_CONTROLLER: string[] = ['user'];

const STANDARD_REQUEST = (): DataRequest => DataRequest
  .create()
  .setController(USER_CONTROLLER);

@Injectable()
export class UserController {

  constructor(private webapi: CtWebapiService) {
  }

  getAthleteCardList(param: AthleteFilterParameter = AthleteFilterParameter.create()): Observable<CtWebapiGenericResponse<CtDatatableResult<Athlete>>> {

    const request = STANDARD_REQUEST()
      .setAction('GetAthleteCardList')
      .setMethod(MethodEnum.GET)
      .setQueryParams({ ...param, hideSpinner: true });

    return this.webapi
      .request(request);

  }

  athleteBestTimes(param: AthleteBestTimes = AthleteBestTimes.create(), pagination: CTDatatablePaginationParameter = new CTDatatablePaginationParameter()): Observable<CtWebapiGenericResponse<CtDatatableResult<AthleteTimeResult>>> {

    let params: any = {
      ...param,
      ...pagination,
      hideSpinner: true,
    };

    const request = STANDARD_REQUEST()
      .setAction('AthleteBestTimes')
      .setMethod(MethodEnum.GET)
      .setQueryParams(params);

    return this.webapi
      .request(request);

  }

}
