import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CtDatatableModule, CtSearchFiltersModule } from '@ctsolution/ct-framework';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { HistoricalEventDetailComponent } from './historical-event-detail/historical-event-detail.component';
import { EventCardModule } from '../_components/event-card/event-card.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { EventFiltersModule } from '../_components/event-list/event-filters/event-filters.module';
import { LineChartModule } from '@swimlane/ngx-charts';
import { MatIconModule } from '@angular/material/icon';
import {
  SplittedTimeFormatModule,
} from '../_components/event-card/event-card/event-results-list/splitted-time-format/splitted-time-format.module';
import { HistoricalComponent } from './historical.component';
import { HistoricalChartComponent } from './historical-chart/historical-chart.component';

@NgModule({
  declarations: [
    HistoricalComponent,
    HistoricalEventDetailComponent,
    HistoricalChartComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: HistoricalComponent,
        data: {
          title: 'Storico',
          urls: [{
            title: 'Storico',
            url: '/competitions-and-events/historical',
          }, { title: 'Storico' }],
        },
      },
    ]),
    CtSearchFiltersModule,
    MatCardModule,
    CtDatatableModule,
    MatBottomSheetModule,
    EventCardModule,
    MatButtonModule,
    EventFiltersModule,
    LineChartModule,
    MatIconModule,
    RouterModule,
    SplittedTimeFormatModule,
  ],
})
export class HistoricalModule {
}
