import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsViewTemplate, NotificationsWidgetConfiguration } from './notifications-widget.configuration';
import { DataRequest } from '@ctsolution/ct-webapi';
import { FileService } from '../../core/lib/file.service';
import { GlobalNotificationController, SPNNotification } from '../../core/controllers/global-notification.controller';

@Component({
  selector: 'app-notifications-widget',
  templateUrl: './notifications-widget.component.html',
  styleUrls: ['./notifications-widget.component.scss'],
})
export class NotificationsWidgetComponent {

  @Input() configuration: NotificationsWidgetConfiguration | null = null;
  @Output() onEdit: EventEmitter<SPNNotification> = new EventEmitter<SPNNotification>();
  @Output() onDelete: EventEmitter<SPNNotification> = new EventEmitter<SPNNotification>();

  constructor(private _file: FileService) {
  }

  download(notification: SPNNotification): void {

    if (!notification.DownloadUrl) return;

    this._file
      .getPDFFile(new DataRequest(notification.DownloadUrl), notification.FileName ?? `SPN${new Date().getTime()}`);

  }

  edit(notification: SPNNotification) {

    this.onEdit.emit(notification);

  }

  delete(notification: SPNNotification) {

    this.onDelete.emit(notification);

  }

  protected readonly NotificationsViewTemplate = NotificationsViewTemplate;
}
