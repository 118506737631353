<mat-accordion class='event-filters-cntr'>

  <mat-expansion-panel [expanded]='expandedFilterPanel'>

    <mat-expansion-panel-header>
      <mat-panel-title>
        Filtra la tua ricerca
      </mat-panel-title>
    </mat-expansion-panel-header>

    <form
      [formGroup]='form'
      fxLayout='row wrap'>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-name-surname-filter
          [control]="form.get('NameSurname')"></app-spn-name-surname-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-company-business-name-filter
          [parentName]="'athletesFilterComponent'"
          [control]="form.get('CompanyBusinessName')"></app-spn-company-business-name-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-athlete-category-filter
          [control]="form.get('Category')"></app-spn-athlete-category-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-gender-filter
          [control]="form.get('Gender')"></app-spn-gender-filter>

      </div>

    </form>

  </mat-expansion-panel>

</mat-accordion>

