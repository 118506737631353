import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouponsListComponent } from './coupons-list/coupons-list.component';
import { RouterModule } from '@angular/router';
import { UserCouponsComponent } from './coupons-list/user-coupons/user-coupons.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    CouponsListComponent,
    UserCouponsComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: '',
            component: CouponsListComponent,
            data: {
              title: 'I miei coupon',
              urls: [{
                title: 'I miei coupon',
                url: '/personal-area/coupons',
              }, { title: 'I miei coupon' }],
            },
          }],
      },
    ]),
  ],
})
export class CouponsModule {
}
