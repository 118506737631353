import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs';
import { EventListFilterDTO } from '../../../../core/controllers/event.controller';
import {
  NotificationEventSelectControlConfiguration,
} from '../../../notifications/generate-notification/generate-notification-form-dialog/notification-event-select-control/notification-event-select-control.configuration';
import { FilterCacheService } from '../../../../core/lib/filter-cache.service';

@Component({
  selector: 'app-qualifications-filters',
  templateUrl: './qualifications-filters.component.html',
})
export class QualificationsFiltersComponent {

  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  expandedFilterPanel = true;
  form: FormGroup;
  notificationEventSelectControlConfiguration = NotificationEventSelectControlConfiguration
    .create()
    .setAppearance('outline')
    .setTimeLimitOnly(true)
    .setNullValueEnabled(false);

  constructor(private fb: FormBuilder, private cacheService: FilterCacheService) {

    this.form = this.fb.group({
      SwimStyle: new FormControl(null),
      Distance: new FormControl(null),
      PoolLength: new FormControl(null),
      AthleteCategories: new FormControl(null),
      CompanyBusinessName: new FormControl(null),
      Gender: new FormControl(null),
      EventProductOid: new FormControl(null, Validators.required),
    });

    this.form
      .valueChanges
      .pipe(
        skip(1), // Salta il primo cambio
        debounceTime(500), distinctUntilChanged())
      .subscribe(() => {

        const value = this.form.value;

        this.cacheService.saveFormValue(QualificationsFiltersComponent, value);

        this.update.emit(value);

      });

    const prevValue = this.cacheService.getFormValue(QualificationsFiltersComponent);

    if (prevValue) {

      this.form.patchValue(prevValue);
      this.expandedFilterPanel = true;

    }

  }

  setFilters(parameter: EventListFilterDTO): EventListFilterDTO {

    const value: any = this.form.value;

    parameter = Object.assign({}, value);

    return parameter;

  }

}
