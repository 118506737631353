import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EventController } from '../../../core/controllers/event.controller';

@Component({
  selector: 'app-spn-distance-filter',
  templateUrl: './spn-distance-filter.component.html',
})
export class SpnDistanceFilterComponent {

  DISTANCES: number[] = [];

  @Input() control: FormControl | null = null;

  constructor(private eventController: EventController) {
  }

  ngOnInit(): void {

    this.eventController
      .getDistanceList()
      .then(result => this.DISTANCES = result);

  }

}
