<div class='limit-times-cntr'>

  <div class='m-t-5' fxLayout='row wrap' fxLayoutAlign='space-between center'>

    <h2 class='title-min-size'
        style='margin: 0 !important;'> {{ athleteQualifiedRaceDetail ? ('Qualifica ' + athleteQualifiedRaceDetail.QualifiedRaceName + ' : ' + athleteQualifiedRaceDetail.UserInfoFullName) : 'I miei tempi limite' }} </h2>

    <button mat-raised-button color='primary' style='margin-bottom: 10px' (click)='back()'>
      <mat-icon>arrow_backword</mat-icon>
      Indietro
    </button>

  </div>

  <div class='container'>

    <div class='m-b-10 m-t-25' [hidden]='!!athleteQualifiedRaceDetail'>

      <app-event-filters [parentName]="'limitTimesComponent'" #filters (update)='fetch()'></app-event-filters>

    </div>

    <ct-datatable
      *ngIf='limitTimes'
      [configuration]='limitTimes'
      (reload)='fetch($event)'
      (onDetail)='detail($event)'></ct-datatable>

  </div>

</div>

<ng-template #resultTemplate let-row='row' let-value='value' let-i='index'>

  <p> {{ value['TimeForGrid'] ? (value['TimeForGrid']  | splittedTimeFormat) : 'N.D.' }}</p>

</ng-template>

<ng-template #descriptionTemplate let-row='row' let-value='value' let-i='index'>

  <div>

    <p><strong> {{ value['RaceSpecialtySwimStyleName'] ?? '' }} {{ value['RaceSpecialtyDistance'] ?? '' }} -
      TL: {{ value['TimeForGrid'] | splittedTimeFormat }}</strong></p>

    <p> {{ row?.TimeLimit?.SPNEventProductProductDescription ?? '' }} </p>

  </div>

</ng-template>
