<mat-list role='list' class='user-purchases-container'>

  <ng-container *ngFor='let cart of list; let i = index;'>

    <app-purchase-item-subheader [item]='cart'></app-purchase-item-subheader>

    <ng-container *ngFor='let product of cart.CartProducts'>

      <app-purchase-item [item]='product'></app-purchase-item>

    </ng-container>

    <mat-chip-list *ngIf='waitingPayments(cart).length === cart.Payments.length; else invoicesTemplate'>

      <mat-chip selected [color]="'warn'" style='margin-left: auto'>
        Pagamento in attesa di verifica.
      </mat-chip>

    </mat-chip-list>

    <ng-template #invoicesTemplate>

      <div
        *ngIf='successfulPayments(cart).length > 0'
        [align]="'end'">

        <button
          mat-stroked-button
          color='primary'
          #tooltip='matTooltip'
          style='margin-right: 5px'
          matTooltip="Attenzione: ti ricordiamo che le ricevute saranno disponibili 24H dopo l'acquisto."
          [matTooltipDisabled]='availableInvoice(payment)'
          (click)='downloadInvoice(payment); tooltip.toggle()'
          *ngFor='let payment of successfulPayments(cart); let i = index;'
        > Ricevuta {{ i > 0 ? i + 1 : '' }}
          <mat-icon>receipt_long</mat-icon>
        </button>

      </div>

    </ng-template>

    <mat-divider style='margin-top: 25px' *ngIf='list.length > 1 && i < (list.length - 1)'></mat-divider>

  </ng-container>

</mat-list>
