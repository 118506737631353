<div class='athletes-best-times-list-container'>

  <div class='m-t-5' fxLayout='row wrap' fxLayoutAlign='space-between center'>

    <h2 class='title-min-size' style='margin: 0 !important;'>

      <ng-container *ngIf='viewModel.athleteName; else defaultTitle'>

        Tempi migliori <b>{{ viewModel.athleteName }}</b>

      </ng-container>

      <ng-template #defaultTitle>

        I miei tempi migliori

      </ng-template>

    </h2>

    <button mat-raised-button color='primary' (click)='back()'>
      <mat-icon>arrow_backword</mat-icon>
      {{ viewModel.athleteName ? 'Scheda atleti' : 'Indietro'}}
    </button>

  </div>

  <div class='container'>

    <div class='best-times-list-container m-t-25'>

      <ct-datatable
        *ngIf='bestTimes'
        [configuration]='bestTimes'
        (reload)='fetch($event)'
        (onDetail)='detail($event)'></ct-datatable>

    </div>

  </div>

</div>


<ng-template #timeTemplate let-row='row' let-value='value' let-i='index'>

  <p *ngIf='value?.length'>
    <strong> {{ value | splittedTimeFormat }} </strong>
  </p>

</ng-template>

<ng-template #poolLengthTemplate let-row='row' let-value='value' let-i='index'>

  <p>

    {{ poolLengthFormat(value) }}

  </p>

</ng-template>
