import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { EventListModule } from '../_components/event-list/event-list.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { UpcomingComponent } from './upcoming.component';

@NgModule({
  declarations: [
    UpcomingComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    EventListModule,
    MatButtonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UpcomingComponent,
        data: {
          title: 'Calendario gare ed eventi',
          urls: [{
            title: 'Calendario gare ed eventi',
            url: '/competitions-and-events/upcoming',
          }, { title: 'Calendario gare ed eventi' }],
        },
      },
    ]),
    MatIconModule,
  ],
})
export class UpcomingModule {
}
