import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { EventListFilterDTO } from '../../../../core/controllers/event.controller';
import { CTDateAdapter } from '@ctsolution/ct-framework';
import {
  NotificationEventSelectControlConfiguration,
} from '../../../notifications/generate-notification/generate-notification-form-dialog/notification-event-select-control/notification-event-select-control.configuration';
import { FilterCacheService } from '../../../../core/lib/filter-cache.service';

@Component({
  selector: 'app-special-rankings-filters',
  templateUrl: './special-rankings-filters.component.html',
})
export class SpecialRankingsFiltersComponent {

  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  notificationEventSelectControlConfiguration = NotificationEventSelectControlConfiguration
    .create()
    .setAppearance('outline');
  expandedFilterPanel = true;

  constructor(
    private fb: FormBuilder, private cacheService: FilterCacheService) {

    this.form = this.fb.group({
      StartDate: new FormControl(null),
      EndDate: new FormControl(null),
      SwimStyle: new FormControl(null),
      Distance: new FormControl(null),
      PoolLength: new FormControl(null),
      AthleteCategories: new FormControl(null),
      CompanyBusinessName: new FormControl(null),
      Gender: new FormControl(null),
      EventProductOid: new FormControl(null),
      TopTimesOnly: new FormControl(null),
    });

    this.form
      .valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {

        const value = this.form.value;

        this.cacheService.saveFormValue(SpecialRankingsFiltersComponent, value);

        this.update.emit();

      });

    const prevValue = this.cacheService.getFormValue(SpecialRankingsFiltersComponent);

    if (prevValue) {

      this.form.patchValue(prevValue);
      this.expandedFilterPanel = true;

    }

  }

  setFilters(parameter: EventListFilterDTO): EventListFilterDTO {

    const value: any = this.form.value;

    parameter = Object.assign({}, value);

    const dateAdapter: CTDateAdapter = CTDateAdapter.create();

    parameter.FromEventStartDate = value.StartDate ? dateAdapter.transformDateToCurrentTimezone(value.StartDate) : undefined;
    parameter.ToEventStartDate = value.EndDate ? dateAdapter.transformDateToCurrentTimezone(value.EndDate) : undefined;

    return parameter;

  }

}
