<mat-form-field *ngIf='control' appearance='outline'>

  <mat-label>Distanza</mat-label>

  <mat-select [formControl]='control'>

    <mat-option *ngFor='let distance of DISTANCES' [value]='distance'>
      {{distance}}
    </mat-option>
    <mat-option [value]='null'>Vedi tutto</mat-option>

  </mat-select>

</mat-form-field>
