<mat-form-field *ngIf='control' appearance='outline'>

  <mat-label>Categoria</mat-label>

  <mat-select [formControl]='control' [multiple]='multiple'>
    <mat-option *ngFor='let category of values' [value]='category'>
      {{ getLabel(category) }}
    </mat-option>
    <mat-option [value]='null'>Vedi tutto</mat-option>
  </mat-select>

</mat-form-field>
