import { CTBase } from '@ctsolution/ct-base';

export class UserProfileUpdateParameter extends CTBase<UserProfileUpdateParameter> {

  Phone: string | null = null;
  Address: string | null = null;
  ZipCode: string | null = null;
  City: string | null = null;
  Province: string | null = null;

  private constructor();
  private constructor(model?: UserProfileUpdateParameter);
  private constructor(model?: UserProfileUpdateParameter) {

    super();

    if (model) {

      this.getClass(model);

    }

  }

  public static create = (model?: UserProfileUpdateParameter): UserProfileUpdateParameter => new UserProfileUpdateParameter(model);

}
