import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsWidgetComponent } from './notifications-widget.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FlexModule } from '@angular/flex-layout';
import { NotificationListItemComponent } from './notification-list-item/notification-list-item.component';
import { NotificationPostItComponent } from './notification-post-it/notification-post-it.component';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { CTExpandableTextModule } from '@ctsolution/ct-framework';
import { NotificationSenderComponent } from './notification-sender/notification-sender.component';

@NgModule({
  declarations: [
    NotificationsWidgetComponent,
    NotificationListItemComponent,
    NotificationPostItComponent,
    NotificationSenderComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    FlexModule,
    MatLegacyChipsModule,
    CTExpandableTextModule,
  ],
  exports: [
    NotificationsWidgetComponent,
  ],
})
export class NotificationsWidgetModule {
}
