<ng-container *ngIf='list.length > 0; else emptyTemplate'>

  <div fxLayout='row wrap' fxLayoutAlign='center start' class='pricing-box'>

    <ng-container *ngFor='let product of list'>

      <div fxFlex.gt-sm='100' fxFlex.gt-md='33.33' fxFlex='100' class='text-center'>

        <app-product-item
          [item]='product'></app-product-item>

      </div>

    </ng-container>

  </div>

</ng-container>

<ng-template #emptyTemplate>

  <p class='text-center'> Nessun prodotto disponibile. </p>

</ng-template>
