import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventResultsListComponent } from './event-results-list/event-results-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EventSplittedTimesComponent } from './event-splitted-times/event-splitted-times.component';
import { MatDividerModule } from '@angular/material/divider';
import { SplittedTimeFormatModule } from './splitted-time-format/splitted-time-format.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [EventResultsListComponent, EventSplittedTimesComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatDividerModule,
    SplittedTimeFormatModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [EventResultsListComponent],
})
export class EventResultsListModule {
}
