<mat-form-field *ngIf='control' appearance='outline'>

  <mat-label>Specialità</mat-label>

  <mat-select [formControl]='control'>

    <mat-select-trigger>{{selectedSpecialtyName}}</mat-select-trigger>

    <mat-option *ngFor='let style of SWIM_STYLES' [value]='style.Oid'>
      {{style.Name}}
    </mat-option>
    <mat-option [value]='null'>Vedi tutto</mat-option>

  </mat-select>

</mat-form-field>
