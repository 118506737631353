import { FileDTO } from '../interfaces/file';

export class SpnUserFile {

  Oid = -1;
  File?: any;
  EmissionDate?: string;
  ExpireDate?: string;
  ValidationDate?: Date;
  Position?: number;
  Type?: string;
  PreviewUrl?: Array<string>;

  Name?: string;
  Extension?: string;

  constructor(public SPNType: FileDTO) {
  }

}
