import { Component, Input, OnInit } from '@angular/core';
import { DocumentBoxUploaderConfiguration } from './document-box-uploader.configuration';
import { Validators } from '@angular/forms';
import { FileSideEnum } from '../../../core/enums/file-side.enum';

@Component({
  selector: 'app-document-box-uploader',
  templateUrl: './document-box-uploader.component.html',
  styleUrls: ['./document-box-uploader.component.scss'],
})
export class DocumentBoxUploaderComponent {

  @Input() configuration!: DocumentBoxUploaderConfiguration;
  releaseDateMaxValue: Date = new Date();

  DOCUMENT_SIDE = FileSideEnum;

  constructor() {
  }

  isDateControlRequired = (): boolean => this.configuration.dateControl.hasValidator(Validators.required);

  isDocumentRetroControlRequired = (): boolean => this.configuration.documentRetroControl.hasValidator(Validators.required);

  isDocumentCodeControlAvailable = (): boolean => this.configuration.documentLabel?.toLowerCase().includes('fin') ?? false;

}
