<mat-card style='padding: 15px 5px !important;'>

  <ng-container *ngFor='let product of item.Product; let i = index;'>

    <mat-card style='box-shadow: none !important;'>

      <mat-card-title-group>
        <mat-card-title class='text-left'>{{ product.Name }}</mat-card-title>
        <mat-card-subtitle class='text-left' *ngIf='product.Description'>{{ product.Description }}</mat-card-subtitle>
        <img *ngIf='getCoverImage(product)' mat-card-sm-image style='width: auto !important;'
             [src]='getCoverImage(product) | secureImage | async'>
      </mat-card-title-group>

      <mat-card-content [formGroup]='form'>

        <h1
          *ngIf='product.Quote?.FinalPrice; else freePaymentTemplate'>{{ product.Quote.FinalPrice | currency:'EUR':'symbol':'1.0' }}</h1>

        <ng-template #freePaymentTemplate>

          <ng-container *ngIf='item.MainProductCategoryMacroType === ProductCategoryMacroTypes.Event'>

            <mat-card-subtitle> Iscrizione Gratuita</mat-card-subtitle>

          </ng-container>

        </ng-template>

        <mat-form-field
          *ngIf='product.AmountControl'
          appearance='outline'>
          <mat-label> Quantità</mat-label>
          <mat-select [formControl]='product.AmountControl'>
            <mat-option *ngFor='let count of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]' [value]='count'>
              {{count}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf='product.Variations && product.Variations.length > 0'>

          <div fxLayout='row wrap'>

            <div style='padding: 5px' [fxFlex.gt-md]='product.Variations.length === 1 ? 100 : 50' fxFlex='100'
                 *ngFor='let variation of product.Variations'>

              <mat-form-field
                *ngIf='variation.VariationControl'
                appearance='outline'>
                <mat-label> {{ variation.Name }} </mat-label>
                <mat-select [formControl]='variation.VariationControl'>
                  <mat-option *ngFor='let value of variation.Value.split(",")' [value]='value'>
                    {{value}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="variation.VariationControl.hasError('required')"> * Campo obbligatorio</mat-error>
              </mat-form-field>

            </div>

          </div>

        </ng-container>

        <mat-divider *ngIf='i < item.Product.length - 1'></mat-divider>

      </mat-card-content>

    </mat-card>

  </ng-container>

  <ng-container [ngSwitch]='item.MainProductCategoryMacroType'>

    <ng-container *ngSwitchCase='ProductCategoryMacroTypes.Subscription'>

      <app-subscription-payment-actions (checkOut)='subscriptionCheckOut($event)'></app-subscription-payment-actions>

    </ng-container>

    <ng-container *ngSwitchDefault>

      <app-event-actions (action)='handleGenericAction($event)'></app-event-actions>

    </ng-container>

  </ng-container>

</mat-card>
