import { Routes } from '@angular/router';
import { SubscriptionsModule } from './subscriptions/subscriptions.module';
import { CompetitionsAndEventsModule } from './competitions-and-events/competitions-and-events.module';
import { MarketPlaceModule } from './market-place/market-place.module';
import { PersonalAreaModule } from './personal-area/personal-area.module';
import { CartModule } from './cart/cart.module';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { NotificationsModule } from './notifications/notifications.module';
import { HomeModule } from './home/home.module';
import { AthletesModule } from './athletes/athletes.module';

export const PagesRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        loadChildren: () => HomeModule,
      },
      {
        path: 'subscriptions',
        loadChildren: () => SubscriptionsModule,
      },
      {
        path: 'competitions-and-events',
        loadChildren: () => CompetitionsAndEventsModule,
      },
      {
        path: 'athletes',
        loadChildren: () => AthletesModule,
      },
      {
        path: 'market-place',
        loadChildren: () => MarketPlaceModule,
      },
      {
        path: 'cart',
        loadChildren: () => CartModule,
      },
      {
        path: 'personal-area',
        loadChildren: () => PersonalAreaModule,
      },
      {
        path: 'notifications',
        loadChildren: () => NotificationsModule,
      },
      {
        path: 'thank-you',
        component: ThankYouPageComponent,
        data: {
          title: 'Il tuo acquisto',
          urls: [{ title: 'Il tuo acquisto', url: '/thank-you' }, { title: 'Il tuo acquisto' }],
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
    ],
  },
];
