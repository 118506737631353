import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileController } from 'src/app/core/controllers/profile.controller';
import { DeleteProfileConfirmService } from './delete-profile-confirm/delete-profile-confirm.service';
import { CtWebviewService } from '@ctsolution/ct-webview';
import { UserProfileUpdateParameter } from '../../../core/classes/user-profile-update.parameter';
import { SnackbarService } from '@ctsolution/ct-framework';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  public form: FormGroup = Object.create(null);

  constructor(
    private formBuilder: FormBuilder,
    private profileController: ProfileController,
    private deleteProfileConfirm: DeleteProfileConfirmService,
    public ctwebview: CtWebviewService,
    private snackbar: SnackbarService,
  ) {

    this.form = this.formBuilder
      .group({
        Name: new FormControl({ value: null, disabled: true }),
        Surname: new FormControl({ value: null, disabled: true }),
        DateOfBirth: new FormControl({ value: null, disabled: true }),
        Email: new FormControl({ value: null, disabled: true }),
        FiscalCode: new FormControl({ value: null, disabled: true }),
        Phone: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        Address: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        ZipCode: new FormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
        City: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        Province: new FormControl(null, [Validators.required, Validators.maxLength(2), Validators.minLength(2)]),
      });

  }

  ngOnInit() {

    this.getUserData();

  }

  deleteProfile() {

    this.deleteProfileConfirm
      .deleteProfile();

  }

  getUserData(): void {

    this.profileController
      .getUserData()
      .then(data => {

        this.form.patchValue(data.Result);

        if (data.Result.DateOfBirth) {

          this.form.patchValue({ DateOfBirth: new Date(data.Result.DateOfBirth ?? -1).toLocaleDateString() });

        }

      });

  }

  updateProfile() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const parameter: UserProfileUpdateParameter = UserProfileUpdateParameter
        .create(this.form.value);

      this.profileController
        .updateProfile(parameter)
        .subscribe(() => this.snackbar.open('Il tuo profilo è stato aggiornato con successo!'));

    }

  }

}
