<mat-list class='event-convocations-list-cntr'>

  <ng-container *ngFor='let convocation of product?.EventProduct?.ConvocationDataList'>

    <mat-list-item class='mat-elevation-z4' *ngIf='convocation.SwimStyleName'>
      <div mat-line>{{convocation.SwimStyleName}}</div>
      <div mat-line class='justify-result'><small>{{convocation.Distance}}
        m</small><small *ngIf='convocation.Time'>TI: {{ convocation.TimeForGrid | splittedTimeFormat }}</small>
      </div>
    </mat-list-item>

  </ng-container>

</mat-list>
