import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorService } from '../../../core/lib/validator.service';
import { CheckoutConfirmComponent } from './checkout-confirm/checkout-confirm.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProductController } from '../../../core/controllers/product.controller';
import { CheckOutPaymentData } from './check-out-payment-data';
import {
  FINANCIG_PAYMENT,
  ICheckoutPaymentBoxData,
  PAYMENTS,
  SPLITTED_PAYMENTS,
} from './checkout-payment-box/checkout-payment-box-data';
import { UserService } from '../../../core/lib/user.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ParentRedirectConfirmComponent } from '../parent-redirect-confirm/parent-redirect-confirm.component';
import { GeneralService } from '../../../core/lib/general.service';
import { CartPaymentMethodEnum } from '../../../core/enums/cart-payment-method.enum';
import { PurchaseDTO } from '../../../core/classes/purchase';
import { ProductListService } from '../../product-list/product-list.service';
import { CartCheckoutParameter } from '../../../core/classes/cart-checkout-parameter';
import { CartController } from '../../../core/controllers/cart.controller';
import { of } from 'rxjs';
import { CartPaymentDTO } from '../../../core/interfaces/cart-payment';

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss'],
  providers: [ProductController, CartController],
})
export class CheckoutPaymentComponent implements OnInit {

  @Input() couponAmount: number = 0;
  @Input() checkOutPaymentData!: CheckOutPaymentData;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPaymentClosed: EventEmitter<CartPaymentMethodEnum | null> = new EventEmitter<CartPaymentMethodEnum | null>();

  form: FormGroup;
  payments: ICheckoutPaymentBoxData[] = [];

  viewModel = {

    isUserInstallment: false,

  };

  constructor(
    private fb: FormBuilder,
    private validatorService: ValidatorService,
    private dialog: MatDialog,
    private productController: ProductController,
    public userService: UserService,
    private _bottom: MatBottomSheet,
    private generalService: GeneralService,
    private productListService: ProductListService,
    private cartController: CartController,
  ) {

    this.form = this.fb.group({
      ApplyCoupon: new FormControl(false),
      FiscalCode: new FormControl(null, [Validators.required, this.validatorService.fiscalCode]),
    });

  }

  ngOnInit() {

    this.setPayments();

  }

  get enableZeroAmountPayment(): boolean {

    let enable: boolean = false;

    if (this.form.value.ApplyCoupon) {

      enable = (this.checkOutPaymentData.UserCart?.FinalAmount ?? 0) - this.couponAmount < 0;

    }

    return enable;

  }

  private async setPayments() {

    if (this.checkOutPaymentData.splittedPayment) {

      this.payments = SPLITTED_PAYMENTS;

      const nextPayment = await this.userService.userNextPayment();

      if (nextPayment) {

        this.viewModel.isUserInstallment = true;
        this.payments = this.payments.filter((payment) => payment.type === nextPayment.PaymentMethodDescription);

      }

    } else {

      this.payments = [...PAYMENTS, FINANCIG_PAYMENT];


    }

  }

  checkOutWithoutPayment() {

    this.payments = PAYMENTS;
    this.checkOut(CartPaymentMethodEnum.Stripe);

  }

  parentNeededAlert = () => this._bottom
    .open(ParentRedirectConfirmComponent, { disableClose: true })
    .afterDismissed()
    .subscribe((result: boolean) => {

      if (result) {

        this.generalService
          .navigateTo(['personal-area', 'familiar'])
          .then(() => {
            this.onPaymentClosed.emit(null);
          });

      }

    });

  async checkOut(method: CartPaymentMethodEnum) {

    if (this.form.valid) {

      const payment: ICheckoutPaymentBoxData = this.payments.find((payment: ICheckoutPaymentBoxData) => payment.type === method)!;

      this.confirm(method)
        .subscribe((paymentConfirmed: boolean) => {

          if (paymentConfirmed) {

            this.handlePurchaseMethod(method)
              .then((result: any) => {

                payment.loader = false;

                this.productListService
                  .handlePurchaseResult(method, result)
                  .then(() => {

                    this.cartController
                      .activeCartProductsCount()
                      .then(() => {

                        this.onPaymentClosed.emit(method);

                      });

                  });

              });

          } else {

            payment.loader = false;

          }

        });

    }

  }

  async handlePurchaseMethod(method: CartPaymentMethodEnum) {

    if (this.checkOutPaymentData.fullCart) {

      return this.checkOutFullCart(method);

    } else {

      return await this.directPurchase(method);

    }

  }

  async directPurchase(method: CartPaymentMethodEnum) {

    const objectToPurchase: PurchaseDTO = this.createObjectToPurchase(method);

    const nextPayment: CartPaymentDTO | null = await this.userService.userNextPayment();

    if (nextPayment) {

      objectToPurchase.CartPaymentOid = nextPayment.Oid;

    }

    return this.productController
      .directPurchase(objectToPurchase);

  }

  checkOutFullCart(method: CartPaymentMethodEnum) {

    const values: PurchaseAdditionalValues = this.getFormValues();
    const parameter: CartCheckoutParameter = new CartCheckoutParameter(method, values.FiscalCode);

    if (values.CouponAmount) {

      parameter.CouponAmount = values.CouponAmount;

    }

    return this.productController
      .cartCheckOut(parameter);

  }

  confirm = (method: CartPaymentMethodEnum) => {

    this.form.markAllAsTouched();

    if (this.enableZeroAmountPayment) {

      return of(true);

    }

    let amount: number = 0;

    if (this.checkOutPaymentData.product) {

      amount = this.checkOutPaymentData.product.Quote.FinalPrice -= (this.form.value.ApplyCoupon ? this.couponAmount : 0);

    }

    return this.dialog
      .open(CheckoutConfirmComponent, {
        disableClose: true,
        data: {
          method,
          amount,
          checkOutPaymentData: this.checkOutPaymentData,
        },
      })
      .afterClosed();

  };

  createObjectToPurchase = (method: CartPaymentMethodEnum): PurchaseDTO => this.productListService.getPurchasableProductObject(method, this.checkOutPaymentData.product!, this.getFormValues());

  private getFormValues(): PurchaseAdditionalValues {

    const values: PurchaseAdditionalValues = {
      FiscalCode: this.form.value.FiscalCode.toUpperCase(),
      CouponAmount: 0,
    };

    if (this.form.value.ApplyCoupon) {

      values.CouponAmount = this.couponAmount;

    }

    return values;

  }

}

export interface PurchaseAdditionalValues {

  FiscalCode: string,
  CouponAmount?: number;

}
