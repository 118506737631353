<div class='athletes-list-container'>

  <h2 class='title-min-size'> Scheda atleti </h2>

  <div class='event-list-container'>

    <div class='container p-10'>

      <app-athletes-filters #filters (update)='getList($event)'></app-athletes-filters>

    </div>

    <div fxLayout='row wrap' fxLayoutAlign='center start'>

      <ng-container
        *ctSkeleton="viewModel.loadingList;
           repeat: 3;
           className: 'athlete-card-skeleton';">

        <ng-container *ngIf='athletes.length > 0; else emptyListTemplate'>

          <ng-container *ngFor='let athlete of athletes'>

            <div
              #athleteCardContainer
              fxFlex.gt-xs='100'
              fxFlex.gt-sm='50'
              fxFlex.gt-md='50'
              fxFlex='100'
              [attr.oid]='athlete.info.Oid'> <!-- Assuming athlete has an 'oid' property -->

              <app-athlete-card [configuration]='athlete'></app-athlete-card>

            </div>

          </ng-container>

        </ng-container>
        <ng-template #emptyListTemplate>

          <p class='text-center'> Nessun atleta disponibile. </p>

        </ng-template>

      </ng-container>

    </div>

  </div>


</div>
