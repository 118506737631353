<button *ngIf='!viewModel.isTechnical' mat-icon-button class='m-r-15' routerLink='/cart'>
  <mat-icon [matBadge]='cartService.counter' matBadgeColor='warn'>shopping_cart</mat-icon>
</button>

<app-user-profile-image [matMenuTriggerFor]='profile'></app-user-profile-image>

<mat-menu #profile='matMenu' class='mymegamenu'>
  <button mat-menu-item (click)='logout()'>
    <mat-icon>exit_to_app</mat-icon>
    Logout
  </button>
</mat-menu>
