<div class='coupons-container'>

  <h2> I miei coupon </h2>

  <div>

    <ng-container *ngIf='coupons.length > 0; else emptyTemplate'>

      <div>

        <h4 class='m-t-0'> In questa sezione potrai consultare la lista dei tuoi coupon disponibili. <br /> <small>Attenzione,
          ricordati di prestare attenzione alla validità temporale del coupon</small></h4>

      </div>

      <app-user-coupons [list]='coupons'></app-user-coupons>

    </ng-container>
    <ng-template #emptyTemplate>

      <p class='text-center'> Nessun coupon disponibile.</p>

    </ng-template>

  </div>

</div>
