import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListComponent } from './product-list/product-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ProductItemModule } from './product-item/product-item.module';

@NgModule({
  declarations: [
    ProductListComponent,
  ],
  exports: [
    ProductListComponent,
  ],
  imports: [
    CommonModule,
    ProductItemModule,
    FlexLayoutModule,
  ],
})
export class ProductListModule {
}
