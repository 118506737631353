<div class='my-competitions-container'>

  <h2 class="title-min-size"> Mie gare </h2>

  <mat-tab-group mat-stretch-tabs='false' mat-align-tabs='center'>
    <mat-tab label='Confermate'>
      <app-event-list [caller]='getConfirmed'></app-event-list>
    </mat-tab>
    <mat-tab label='Da confermare'>
      <app-event-list [caller]='getPending'></app-event-list>
    </mat-tab>
  </mat-tab-group>

</div>
