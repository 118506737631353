import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsUploaderComponent } from './documents-uploader.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DocumentBoxUploaderModule} from './document-box-uploader/document-box-uploader.module';
import { FileService } from 'src/app/core/lib/file.service';
import { DateService } from 'src/app/core/lib/date.service';

@NgModule({
    declarations: [
        DocumentsUploaderComponent
    ],
    exports: [
        DocumentsUploaderComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DocumentBoxUploaderModule
  ],
  providers: [
    DateService,
    FileService
  ]
})
export class DocumentsUploaderModule { }
