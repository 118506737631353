import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { LoginController } from 'src/app/core/controllers/login.controller';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {

  public form: FormGroup = Object.create(null);

  constructor(
    private formBuilder: FormBuilder,
    private loginController: LoginController
  ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      // @ts-ignore
      email: [null, Validators.compose([Validators.required, CustomValidators.email])],
    });

  }

  onSubmit(): void {

    if (this.form.valid) {

      const linkUrl = window.location.origin + '/authentication/reset-password';

      this.loginController.forgotPassword(this.form.get('email')?.value, linkUrl);

    }

  }

}
