import { Component, Input } from '@angular/core';
import { UserCart } from '../../../../../core/interfaces/user-cart';
import { CartController } from '../../../../../core/controllers/cart.controller';
import { CartPaymentDTO } from '../../../../../core/interfaces/cart-payment';
import { CartPaymentStateEnum } from '../../../../../core/enums/cart-payment-state.enum';

@Component({
  selector: 'app-user-purchases',
  templateUrl: './user-purchases.component.html',
  styleUrls: ['./user-purchases.component.scss'],
  providers: [CartController],
})
export class UserPurchasesComponent {

  @Input() list: UserCart[] = [];

  constructor(private cartController: CartController) {
  }

  downloadInvoice = (payment: CartPaymentDTO) => {

    if (this.availableInvoice(payment)) {

      this.cartController.getReceipt(payment);

    }

  };

  waitingPayments = (cart: UserCart) => this.filterByPaymentState(cart, CartPaymentStateEnum.Waiting);

  successfulPayments = (cart: UserCart) => this.filterByPaymentState(cart, CartPaymentStateEnum.Success);

  filterByPaymentState = (cart: UserCart, state: CartPaymentStateEnum) => cart.Payments.filter((payment: CartPaymentDTO) => payment.State === state);

  availableInvoice = (payment: CartPaymentDTO) => !!payment.ReceiptNumber;

}
