<mat-form-field *ngIf='startDateControl && endDateControl' appearance='outline' (click)='picker.open()'>

  <mat-label>Stagione</mat-label>

  <mat-icon matPrefix *ngIf='startDateControl.value || endDateControl?.value' style='cursor: pointer' color='accent'
            (click)='resetRangeDate(startDateControl, endDateControl)'>cancel
  </mat-icon>

  <mat-date-range-input [rangePicker]='picker'>
    <input #startDate matStartDate [formControl]='startDateControl' placeholder='Data inizio'>
    <input #endDate matEndDate [formControl]='endDateControl' placeholder='Data fine'>
  </mat-date-range-input>

  <mat-hint class='date-range-hint w-100' fxLayout='row wrap' fxLayoutAlign='space-between center'>

    <span (click)='setCurrentSeason()'> Imposta stagione corrente </span>

  </mat-hint>

  <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>

</mat-form-field>
