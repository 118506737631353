import { CTBase } from '@ctsolution/ct-base';

export class GlobalNotificationParameter extends CTBase<GlobalNotificationParameter> {

  Oid: number | null = null;
  Companies: number[] = new Array<number>();
  EventProducts: number[] = new Array<number>();
  Title: string | null = null;
  Body: string | null = null;
  EnabledDate: Date | string | null = null;
  TechnicalOnly: boolean | null = null;
  GlobalNotificationFileUpload: File | null = null;

  override createListElementClassByProperty(value: any, attribute: string): number | null {

    switch (attribute) {

      case 'Companies':
      case 'EventProducts':
        return +value;

      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  private constructor();
  private constructor(model?: GlobalNotificationParameter);
  private constructor(model?: GlobalNotificationParameter) {

    super();

    if (model) {

      this.getClass(model);

    }

  }

  public static create = (model?: GlobalNotificationParameter) => new GlobalNotificationParameter(model);

}
