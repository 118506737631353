import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { SPNUserCouponDTO } from '../interfaces/spn-user-coupon';

@Injectable()
export class CouponController {

  controller: string = '/Coupon/';

  params: any = {};

  constructor(private _webapi: CtWebapiService) {
  }

  list(): Promise<SPNUserCouponDTO[]> {

    return new Promise<SPNUserCouponDTO[]>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}GetAvailableList`);

      this._webapi
        .get(request)
        .subscribe(
          (r: CtWebapiGenericResponse<any>) => resolve(<SPNUserCouponDTO[]>r.Result));

    });

  }

}
