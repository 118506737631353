import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutModule } from '../../_components/checkout/checkout.module';
import { RouterModule } from '@angular/router';
import { ProductListModule } from '../../_components/product-list/product-list.module';
import { SubscriptionsListComponent } from './subscriptions-list/subscriptions-list.component';

@NgModule({
  declarations: [
    SubscriptionsListComponent,
  ],
  imports: [
    CommonModule,
    CheckoutModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: '',
            component: SubscriptionsListComponent,
            data: {
              title: 'Tesseramenti e Quota',
              urls: [{ title: 'Tesseramenti e Quota', url: '/subscriptions' }, { title: 'Tesseramenti e Quota' }],
            },
          }],
      },
    ]),
    ProductListModule,
  ],
})
export class SubscriptionsModule {
}
