<div class='stripe-payment-container m-b-20'>

  <button mat-raised-button
          color='primary'
          class='stripe-btn-spinner'
          [disabled]='disabled'
          (click)='select()'>

    <span class='payment-button-name'> {{ data.name }}</span>
    <mat-spinner diameter='15' *ngIf='data.loader'></mat-spinner>

  </button>

  <p>{{ data.description}}</p>

</div>
