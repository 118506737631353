import { Injectable } from '@angular/core';
import { CtAuthenticationGuard, CtAuthenticationService } from '@ctsolution/ct-authentication';

@Injectable({
  providedIn: 'root',
})
export class RoleService {

  constructor(
    private CTAuthenticationService: CtAuthenticationService,
    private _CTAuthenticationGuard: CtAuthenticationGuard) {
  }

  async isTechnical() {

    await this.CTAuthenticationService.claimsUploaded();
    return this._CTAuthenticationGuard.userHasRole(['TECHNICAL']);

  }

}
