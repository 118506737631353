export class AthleteBestTimes {

  UserInfoOid: number | null = null;

  private constructor() {
  }

  public static create = (): AthleteBestTimes => new AthleteBestTimes();

  setUserInfoOid(value: number | null): AthleteBestTimes {

    this.UserInfoOid = value;
    return this;

  }

}
