import { Component, OnInit } from '@angular/core';
import { PasswordStrengthValidator } from '../../password-strength.validator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserInfo } from '../../../core/interfaces/user-info';
import { CustomValidators } from 'ngx-custom-validators';
import { AccountController } from '../../../core/controllers/account.controller';
import { CompanyController } from 'src/app/core/controllers/company.controller';
import { SPNCompanyDTO } from 'src/app/core/interfaces/company';
import { ValidatorService } from '../../../core/lib/validator.service';
import { DateService } from '../../../core/lib/date.service';
import { IT_CELLPHONE_PATTERN } from '../../../shared/validation.patterns';
import { IUBENDA_CONFIG } from '../../../app.module';

const password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), PasswordStrengthValidator]));
// @ts-ignore
const confirmPassword = new FormControl('', CustomValidators.equalTo(password));

@Component({
  selector: 'app-registration-step1',
  templateUrl: './registration-step1.component.html',
  styleUrls: ['./registration-step1.component.scss'],
  providers: [AccountController],
})
export class RegistrationStep1Component implements OnInit {

  companiesList: Array<any> = [];

  submitted = false;
  public form: FormGroup = Object.create(null);

  birthDateInitialValue: Date = new Date();
  birthDateMaxValue: Date = new Date();

  hide = true;
  hideConf = true;

  viewModel = {

    termsAndConditions: IUBENDA_CONFIG.TermsAndConditions,

  };

  constructor(
    private accountController: AccountController,
    private companyController: CompanyController,
    private formBuilder: FormBuilder,
    private validatorService: ValidatorService,
    private dateService: DateService,
  ) {

    this.companyController
      .list()
      .then(data => this.companiesList = data.Result);

    this.birthDateInitialValue.setFullYear(this.birthDateInitialValue.getFullYear() - 10);

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required, Validators.maxLength(250)])],
      surname: [null, Validators.compose([Validators.required, Validators.maxLength(250)])],
      // @ts-ignore
      email: [null, Validators.compose([Validators.required, Validators.maxLength(250), CustomValidators.email])],
      phone: [null, Validators.compose([Validators.required, Validators.pattern(IT_CELLPHONE_PATTERN)])],
      fiscalCode: [null, Validators.compose([Validators.required, this.validatorService.fiscalCode])],
      birthDate: [this.birthDateInitialValue, Validators.compose([Validators.required])],
      company: [null, Validators.compose([Validators.required])],
      password,
      confirmPassword,
      termsAndCondition: [false, Validators.compose([Validators.requiredTrue])],
      newsletter: [false],
    });

  }

  onSubmit(): void {

    this.submitted = true;

    const registrationDate: Date = new Date();

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const companyInfo: SPNCompanyDTO = {

        Oid: this.form.get('company')?.value,

      };

      const birthDate: Date = this.form.get('birthDate')?.value;

      const userObj: UserInfo = {
        Name: this.form.get('name')?.value,
        Surname: this.form.get('surname')?.value,
        Email: this.form.get('email')?.value,
        Phone: this.form.get('phone')?.value,
        FiscalCode: this.form.get('fiscalCode')?.value,
        DateOfBirth: this.dateService.timeZoneOffset(birthDate),
        Company: companyInfo,
        Password: this.form.get('password')?.value,
        TermsAndConditions: this.form.get('termsAndCondition')?.value,
        DateTermsAndConditionsAccepted: registrationDate,
        Newsletter: this.form.get('newsletter')?.value,
      };

      this.accountController.registration(userObj);

    }

  }

}
