import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  timeZoneOffset(date: Date): Date {

    let d: Date = new Date(date.getTime());
    let offset = d.getTimezoneOffset();

    return new Date(d.getTime() - offset * 60 * 1000);

  }

}
