<ul id='notes'>

  <li *ngFor='let notification of (configuration?.list ?? [])'>

    <div class='tape'></div>

    <div><b>{{ notification.Title }}</b></div>

    <ct-expandable-text [configuration]='getExpandableTextConfiguration(notification)'></ct-expandable-text>

    <div fxLayout='row wrap' fxLayoutAlign='space-between center'>
      <div>
        <i>
          <small [hidden]='!notificationSender.sender'>
            <app-notification-sender #notificationSender [notification]='notification'></app-notification-sender>,
          </small>
          <small
            *ngIf='notification.EnabledDate && !notification.ForExpiringDocument'>
            {{ notification.EnabledDate  | date:'dd/MM/yy' }}
          </small>
        </i>
      </div>
      <div align='end'>
        <button *ngIf='notification.DownloadUrl' (click)='onDownload.emit(notification)' mat-icon-button
                color='primary'>
          <mat-icon>download</mat-icon>
        </button>
      </div>

    </div>

  </li>

</ul>


