<div>

  <h1> Benvenuto in SuperbaNuoto </h1>

</div>

<ng-container *ngIf='(notificationsWidgetConfiguration?.list ?? []).length'>

  <div
    *ngIf='(notificationsWidgetConfiguration?.list ?? []).length'
    fxLayout='row wrap' class='home-container'>

    <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>

      <h3>Ultime notifiche</h3>

    </div>

    <div class='card-container' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>

      <app-notifications-widget
        [configuration]='notificationsWidgetConfiguration'></app-notifications-widget>

    </div>

  </div>

</ng-container>

<ng-container *ngIf='viewModel.isTechnical; else defaultTemplate'>

  <div fxLayout='row wrap'>

    <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>

      <h3>La tua dashboard</h3>

    </div>

    <div
      class='card-container'
      fxFlex.gt-sm='100'
      fxFlex.gt-xs='100'
      fxFlex.gt-md='50'
      fxFlex.gt-lg='50'
      fxFlex='100'>

      <mat-card>

        <mat-card-content>

          <div fxLayout='row wrap' fxLayoutAlign='space-between center'>

            <span>Scarica calendario {{ viewModel.currentSeason.startYear }}/{{viewModel.currentSeason.endYear}} </span>

            <mat-icon color='primary'>event</mat-icon>

          </div>

          <button mat-raised-button class='btn-block btn-lg m-t-20 backgroundColor'
                  (click)='calendarService.downloadCalendar()'>
            Scarica
          </button>

        </mat-card-content>

      </mat-card>

    </div>

    <div
      class='card-container'
      fxFlex.gt-sm='100'
      fxFlex.gt-xs='100'
      fxFlex.gt-md='50'
      fxFlex.gt-lg='50'
      fxFlex='100'>

      <mat-card>

        <mat-card-content>

          <div fxLayout='row wrap' fxLayoutAlign='space-between center'>

            <span>Visualizza tempi limite</span>

            <mat-icon color='primary'>timer</mat-icon>

          </div>
          <button mat-raised-button class='btn-block btn-lg m-t-20 backgroundColor' (click)='redirectToEventsWithTL()'>
            Tempi limite
          </button>

        </mat-card-content>

      </mat-card>

    </div>

  </div>

</ng-container>

<ng-template #defaultTemplate>

  <div fxLayout='row wrap' class='home-container'>

    <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>

      <h3>Caricamento dati generali e documenti</h3>

    </div>

    <ng-container *ngIf='!(viewModel.needsParentalSupervision || viewModel.needsDocuments); else uploadSection'>

      <mat-card class='success-message' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex.gt-md='100'
                fxFlex.gt-lg='100' fxFlex='100'>

        <mat-card-content>

          <img class='check' src='assets/images/green_check.png'><span>Congratulazioni, hai completato correttamente il caricamento dei tuoi documenti.</span>

        </mat-card-content>

      </mat-card>

    </ng-container>

    <ng-template #uploadSection>

      <div class='card-container' *ngIf='viewModel.needsParentalSupervision' fxFlex.gt-sm='100' fxFlex.gt-xs='100'
           fxFlex.gt-md='50'
           fxFlex.gt-lg='50' fxFlex='100'>

        <mat-card>

          <mat-card-content>

            <span>Non hai ancora terminato il caricamento dei dati del genitore</span>

            <button mat-raised-button class='btn-block btn-lg m-t-20 backgroundColor ' (click)='goToParentData()'>
              Completa
            </button>

          </mat-card-content>

        </mat-card>

      </div>

      <div class='card-container' *ngIf='viewModel.needsDocuments' fxFlex.gt-sm='100' fxFlex.gt-xs='100'
           fxFlex.gt-md='50'
           fxFlex.gt-lg='50' fxFlex='100'>

        <mat-card>

          <mat-card-content>

            <span>Non hai ancora terminato il caricamento dei tuoi documenti</span>

            <button mat-raised-button class='btn-block btn-lg m-t-20 backgroundColor' (click)='goToDocumentsUpload()'>
              Completa
            </button>

          </mat-card-content>

        </mat-card>

      </div>

    </ng-template>

  </div>

  <hr>

  <div fxLayout='row wrap' class='home-container'>

    <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>

      <h3> Tesseramenti e Quota </h3>

    </div>

    <div class='card-container' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>

      <div
        fxFlex.gt-sm='100'
        fxFlex.gt-xs='100'
        fxFlex.gt-md='50'
        fxFlex.gt-lg='50'
        fxFlex='100'>

        <mat-card
          [class.warning-message]='subscriptionState === SubscriptionStates.WAITING || viewModel.missingSubscription'>

          <mat-card-content>

            <ng-container *ngIf='viewModel.showSubscriptionRedirectTemplate; else subscriptionStateTemplate'>

              <ng-container *ngIf='viewModel.missingSubscription; else defaultLabel'>

                <img class='check'
                     src='assets/images/alert_check.png'><span>Il tuo pagamento è stato rifiutato e non è stato possibile attivare il tuo tesseramento.</span>

              </ng-container>

              <ng-template #defaultLabel>

                <span>Non hai ancora sottoscritto alcun tesseramento per la stagione corrente.</span>

              </ng-template>

              <button mat-raised-button class='btn-block btn-lg m-t-20 backgroundColor' (click)='goToSubscription()'>
                Attiva
              </button>

            </ng-container>

            <ng-template #subscriptionStateTemplate>

              <ng-container [ngSwitch]='subscriptionState'>

                <ng-container *ngSwitchCase='SubscriptionStates.PURCHASED'>

                  <div>

                    <img class='check' src='assets/images/green_check.png'><span>Il tuo tesseramento è attivo. </span>

                  </div>

                </ng-container>

                <ng-container *ngSwitchCase='SubscriptionStates.WAITING'>

                  <img class='check'
                       src='assets/images/alert_check.png'><span>Il tuo tesseramento è in fase di validazione</span>

                </ng-container>

              </ng-container>

            </ng-template>

          </mat-card-content>

        </mat-card>


      </div>


    </div>

    <ng-container *ngIf='viewModel.pendingPayments.length > 0'>

      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>

        <h3> Prossimi pagamenti </h3>

      </div>

      <div
        class='card-container'
        fxFlex.gt-sm='100'
        fxFlex.gt-xs='100'
        fxFlex='100'
        fxLayout='row wrap'>

        <ng-container *ngFor='let nextPayment of viewModel.pendingPayments; let i = index;'>

          <div
            fxFlex.gt-sm='100'
            fxFlex.gt-xs='100'
            fxFlex.gt-md='50'
            fxFlex.gt-lg='50'
            fxFlex='100'>

            <mat-card [class.disabled]='userService.installmentPendingApproval(nextPayment)'>

              <mat-card-content>

                <ng-container>

                  <div>

                    <p class='text-center'>
                      Al momento del tesseramento hai optato per il pagamento a rate. Ricordati che
                      il {{ nextPayment.RateDate | date }} scade
                      la {{ nextPayment.InstallmentIndex }}° rata
                      di <b>{{ nextPayment.Amount |currency:'EUR':'symbol':'1.0' }}</b>
                    </p>

                  </div>

                  <button
                    mat-raised-button
                    class='btn-block btn-lg m-t-20 backgroundColor'
                    [disabled]='userService.installmentPendingApproval(nextPayment)'
                    (click)='payInstallment(nextPayment)'>

                    <ng-container *ngIf='userService.installmentPendingApproval(nextPayment); else defaultMessage'>

                      PAGAMENTO IN FASE DI VALIDAZIONE

                    </ng-container>
                    <ng-template #defaultMessage>

                      PAGA {{ nextPayment.InstallmentIndex }}° RATA

                    </ng-template>

                  </button>

                </ng-container>

              </mat-card-content>

            </mat-card>

          </div>

        </ng-container>

      </div>

    </ng-container>

  </div>

</ng-template>
