import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SPNEventProduct } from '../../../../../../../core/classes/SPNEvent';

@Component({
  selector: 'app-event-card-info',
  templateUrl: './event-card-info.component.html',
  styleUrls: ['./event-card-info.component.scss'],
})
export class EventCardInfoComponent {

  @Input() product: SPNEventProduct | null = null;
  @Output() download: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadLimitTimes: EventEmitter<any> = new EventEmitter<any>();

  viewModel: any = {

    subscribable: false,
    poolLength: null,

  };

  constructor() {
  }

  ngAfterViewInit() {

    if (this.product?.EventProduct) {

      this.viewModel.subscribable = this.product?.EventProduct?.subscribable() ?? false;
      this.viewModel.poolLength = this.product?.EventProduct?.getPoolLengthValue();

    }

  }

}
