<form [formGroup]='form'>

  <p [hidden]='couponAmount <= 0 || viewModel.isUserInstallment'>

    <mat-checkbox formControlName='ApplyCoupon'> Usa il saldo coupon
      di {{ couponAmount | currency:'EUR':'symbol':'1.0' }} </mat-checkbox>

  </p>

  <div>

    <h3> Dati del soggetto che effettua il pagamento: </h3>

    <mat-form-field>

      <input matInput placeholder='Codice Fiscale' type='text' formControlName='FiscalCode' />

      <mat-error *ngIf="form.get('FiscalCode')?.hasError('required')">Campo obbligatorio</mat-error>
      <mat-error
        *ngIf="!form.get('FiscalCode')?.hasError('required') && form.get('FiscalCode')?.hasError('FiscalCode')">
        Campo non valido
      </mat-error>

    </mat-form-field>

  </div>

</form>

<ng-container *ngIf='enableZeroAmountPayment; else defaultPaymentsTemplate'>

  <button mat-raised-button color='primary' style='margin-top: 15px;' (click)='checkOutWithoutPayment()'
          [disabled]='form.invalid'> CONCLUDI
  </button>

</ng-container>

<ng-template #defaultPaymentsTemplate>

  <div class='payment-method-choice-container'>

    <h3> Seleziona il metodo di pagamento: </h3>

    <app-checkout-payment-box
      *ngFor='let payment of payments'
      [data]='payment'
      [disabled]='form.invalid'
      (onSelected)='checkOut($event)'></app-checkout-payment-box>

  </div>

</ng-template>
