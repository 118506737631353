import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilterCacheService {

  private formValues: { [key: string]: any } = {};
  private defaultValues: { [key: string]: any } = {};

  constructor() {
  }

  saveFormValue(component: Function, value: any, opt?: { suffix: string | null }): void {

    const componentName = this.getComponentName(component, opt);
    this.formValues[componentName] = value;

  }

  saveDefaultValue(key: string, value: any): void {

    this.defaultValues[key] = value;

  }

  getFormValue(component: Function, opt?: { suffix: string | null }): any {

    const componentName = this.getComponentName(component, opt);
    return this.formValues[componentName];

  }

  getDefaultValue(key: string): any {

    return this.defaultValues[key];

  }


  private getComponentName(constructor: Function, opt?: { suffix: string | null }): string {

    const componentName = constructor.toString().match(/class (\w+)/);

    let toReturn = componentName && componentName[1] ? componentName[1] : 'UnknownComponent';

    if (opt?.suffix) toReturn += `_${opt.suffix}`;

    return toReturn;

  }


}
