import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SPNEventProduct } from '../../../../../../../core/classes/SPNEvent';
import { SPNEventRaceResult } from '../../../../../../../core/classes/SPNEventRaceResult';
import {
  HistoricalEventDetailFilter,
} from '../../../../../historical/historical-event-detail/historical-event-detail.component';

@Component({
  selector: 'app-event-results-list',
  templateUrl: './event-results-list.component.html',
  styleUrls: ['./event-results-list.component.scss'],
})
export class EventResultsListComponent {

  @Input() product: SPNEventProduct | null = null;
  @Input() historicalDetailFilters?: HistoricalEventDetailFilter;

  @Output() compare: EventEmitter<CompareSpecialtyTrend> = new EventEmitter<CompareSpecialtyTrend>();

  viewModel: any = {

    list: [],

  };

  constructor() {
  }

  ngOnInit(): void {

    this.viewModel.list = this.product?.EventProduct?.RaceResultList;

  }

  ngAfterViewInit(): void {

    if (this.historicalDetailFilters?.SwimStyle && this.historicalDetailFilters?.Distance) {

      this.viewModel.list = this.product
        ?.EventProduct
        ?.RaceResultList
        ?.filter((raceResult: SPNEventRaceResult) => raceResult.RaceSpecialtySwimStyleOid === this.historicalDetailFilters?.SwimStyle && raceResult.RaceSpecialtyDistance === this.historicalDetailFilters?.Distance);

    }

  }

  trend(result: SPNEventRaceResult): void {

    const parameter: CompareSpecialtyTrend = new CompareSpecialtyTrend(result.RaceSpecialtySwimStyleOid ?? 0, result.RaceSpecialtyDistance ?? 0);
    this.compare.emit(parameter);

  }

}

export class CompareSpecialtyTrend {

  constructor(
    public SpecialtyOid: number,
    public Distance: number,
  ) {
  }

}
