import { Pipe, PipeTransform } from '@angular/core';
import { mm_TIME_SEPARATOR, TIME_SEPARATOR } from '../../../../../../../core/lib/time.service';


@Pipe({
  name: 'splittedTimeFormat',
})
export class SplittedTimeFormatPipe implements PipeTransform {

  transform(value: string | null, ...args: unknown[]): string {

    if (!value) return '';

    const SPLITTED_STRING_TIME: string[] = value.split(TIME_SEPARATOR);

    const hours: string = SPLITTED_STRING_TIME[0];
    const minutes: string = SPLITTED_STRING_TIME[1];

    let seconds: string = SPLITTED_STRING_TIME[2];
    seconds = seconds.substring(0, seconds.indexOf(mm_TIME_SEPARATOR) + 3);

    let toReturn: string = '';

    if (args.length > 0) {

      if ((<any>args[0]).partial) {

        toReturn = (<any>args[0]).partial;

      }

    }

    if (!hours.includes('00')) {

      toReturn = hours + TIME_SEPARATOR;

    }

    toReturn += `${minutes}:${seconds}`;

    if (toReturn.indexOf(mm_TIME_SEPARATOR) < 0) {

      toReturn += `${mm_TIME_SEPARATOR}00`;

    }

    return toReturn;

  }

}
