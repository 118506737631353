import { Injectable } from '@angular/core';

export const TIME_SEPARATOR = ':';
export const mm_TIME_SEPARATOR = ',';

@Injectable({
  providedIn: 'root',
})
export class TimeService {

  constructor() {
  }

  msToTime(s: number | undefined, showMs: boolean = true): string {

    if (!s) return '';

    const decimal = s.toFixed(4).split('.')[1];

    // Convert to integer without fractional part
    s = Math.floor(s);

    // Pad to 2 or 3 digits, default is 2
    const pad = (n: number, z?: number) => {

      z = z || 2;
      return ('00' + n).slice(-z);

    };

    const ms = s % 1000;
    s = (s - ms) / 1000;

    const secs = s % 60;
    s = (s - secs) / 60;

    const mins = s % 60;

    let returnValue = pad(mins) + TIME_SEPARATOR + pad(secs);

    if (showMs && decimal.length) {

      returnValue += mm_TIME_SEPARATOR + Number(decimal);

    }

    return returnValue;

  }

  timeStringToMs(value: string | null): number {

    if (!value) return 0;

    const hmsToSecondsOnly = (str: string): number => {

      const p: string[] = str.split(TIME_SEPARATOR);
      let s = 0;
      let m = 1;

      while (p.length > 0) {

        const popped = p.pop();

        if (popped) {

          s += m * parseInt(popped, 10);
          m *= 60;

        }

      }

      return s;
    };

    const splitMs: any[] = value.split(mm_TIME_SEPARATOR);

    let hms2ms: number = hmsToSecondsOnly(splitMs[0]) * 1000;

    if (splitMs.length) {

      const ms: string | null = splitMs[1];

      if (ms) {

        hms2ms += (+ms / 10000);

      }

    }

    return hms2ms;

  }

}
