import { CTBase } from '@ctsolution/ct-base';

export class SPNEventRaceResult extends CTBase<SPNEventRaceResult> {

  Oid: number | null = null;
  SPNUserInfoOid: number | null = null;
  SPNEventProductOid: number | null = null;
  AthleteCategoryOid: number | null = null;
  RaceSpecialtyOid: number | null = null;
  RaceSpecialtySwimStyleName: string | null = null;
  RaceSpecialtyDistance: number | null = null;
  RaceSpecialtySwimStyleOid: number | null = null;
  RaceCode: string | null = null;
  PoolLength: number | null = null;
  Placement: number | null = null;
  TimeDate: string | null = null;
  Time: number | null = null;
  TimeForGrid: string | null = null;
  CreateDate: Date | null = null;
  UpdateDate: Date | null = null;
  EventProductSplitTimes: SPNEventProductSplitTimes[] | null = new Array<SPNEventProductSplitTimes>();

  override createListElementClassByProperty(value: any, attribute: string): SPNEventProductSplitTimes | null {

    switch (attribute) {

      case 'EventProductSplitTimes':
        return SPNEventProductSplitTimes.create(value);

      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  private constructor();
  private constructor(model?: SPNEventRaceResult);
  private constructor(model?: SPNEventRaceResult) {

    super();
    if (model) this.getClass(model);

  }

  public static create = (model?: SPNEventRaceResult): SPNEventRaceResult => new SPNEventRaceResult(model);

  setRaceSpecialtySwimStyleName(value: string | null): SPNEventRaceResult {

    this.RaceSpecialtySwimStyleName = value;
    return this;

  }

  setEventProductSplitTimes(value: SPNEventProductSplitTimes[] | null): SPNEventRaceResult {

    this.EventProductSplitTimes = value;
    return this;

  }


  setRaceSpecialtyDistance(value: number | null): SPNEventRaceResult {

    this.RaceSpecialtyDistance = value;
    return this;

  }


  setTimeForGrid(value: string | null): SPNEventRaceResult {

    this.TimeForGrid = value;
    return this;

  }

}

export class SPNEventProductSplitTimes extends CTBase<SPNEventRaceResult> {

  Oid: number | null = null;
  SPNUserInfoEventProductTimeOid: number | null = null;
  Number: number | null = null;
  Name: number | null = null;
  Time: string | null = null;
  PartialTime: string | null = null;
  PartialTimeForGrid: string | null = null;
  PartialTimeDifference: string | null = null;
  PartialTimeDifferenceForGrid: string | null = null;
  PartialTime100: string | null = null;
  PartialTime100ForGrid: string | null = null;

  private constructor();
  private constructor(model?: SPNEventProductSplitTimes);
  private constructor(model?: SPNEventProductSplitTimes) {

    super();
    if (model) this.getClass(model);

  }

  public static create = (model?: SPNEventProductSplitTimes): SPNEventProductSplitTimes => new SPNEventProductSplitTimes(model);


}
