<mat-form-field *ngIf='control' appearance='outline'>

  <mat-label>Vasca</mat-label>

  <mat-select [formControl]='control'>
    <mat-option *ngFor='let length of POOL_LENGTHS' [value]='length.Oid'>
      {{length.Value}}
    </mat-option>
    <mat-option [value]='null'>Vedi tutto</mat-option>
  </mat-select>

</mat-form-field>
