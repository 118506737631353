export enum CartPaymentMethodEnum {

  Stripe = 'Stripe',
  Bank = 'BankTransfer',
  /**
   * @deprecated
   * */
  Financig = 'Financing',
  /**
   * @deprecated
   * */
  StripeSplitted3 = 'StripeSplitted3',
  /**
   * @deprecated
   * */
  BankTransferSplitted3 = 'BankTransferSplitted3',
  StripeSplitted2 = 'StripeSplitted2',
  BankTransferSplitted2 = 'BankTransferSplitted2'

}
