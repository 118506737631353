<div class='qualifications-container'>

  <h2 class='title-min-size'> Qualifiche </h2>

  <div class='container'>

    <div class='m-b-10 m-t-10'>

      <app-qualifications-filters #filters (update)='fetch()'></app-qualifications-filters>

    </div>

    <ct-datatable
      *ngIf='qualifications'
      [configuration]='qualifications'
      (reload)='fetch($event)'></ct-datatable>

  </div>

</div>

<ng-template #rankingTemplate let-row='row' let-value='value' let-i='index'>

  <div>

    <div>
      {{ row['SPNUserInfoName'] ?? '' }} {{ row['SPNUserInfoSurname'] ?? '' }}
      <small>({{ getCategoryLabel(row['AthleteCategoryCategory']) }})</small>
    </div>

    <div><small><i>{{ row['TimeDate'] | date }}</i> | {{ row['SPNEventProductProductDescription'] ?? '' }}</small></div>

  </div>

</ng-template>

<ng-template #descriptionTemplate let-row='row' let-value='value' let-i='index'>

  <div>

    <div><strong> {{ row['RaceSpecialtySwimStyleName'] ?? '' }} {{ row['RaceSpecialtyDistance'] ?? '' }}</strong></div>
    <div><strong>TL: {{ row['TimeLimitTimeForGrid'] | splittedTimeFormat }}</strong></div>
    <div><strong>TG: {{ row['TimeForGrid'] | splittedTimeFormat }}</strong></div>

  </div>

</ng-template>
