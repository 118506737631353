<div class='historical-container'>

  <h2 class='title-min-size'> Storico </h2>

  <div align='end' class='title-cta'>

    <button mat-raised-button class='m-r-10' color='primary'
            [routerLink]="['/',  'competitions-and-events', 'limit-times']">I miei
      tempi limite
      <mat-icon>content_paste_search</mat-icon>
    </button>

    <button mat-raised-button color='primary' [routerLink]="['/',  'athletes', 'list', 'best-times']">I miei
      tempi migliori
      <mat-icon>workspace_premium</mat-icon>
    </button>

  </div>

  <div class='container'>

    <div class='m-b-10 m-t-10'>

      <app-event-filters [parentName]="'historicalComponent'" #filters (update)='fetch()'></app-event-filters>

    </div>

    <div class='m-b-10 chart-container'>

      <app-historical-chart #historicalChart></app-historical-chart>

    </div>

    <ct-datatable
      *ngIf='historical'
      [configuration]='historical'
      (reload)='fetch($event)'
      (onDetail)='detail($event)'></ct-datatable>

    <button
      class='filter-remove-button'
      *ngIf='hasActiveFilters'
      (click)='resetFilters()'
      mat-fab
      color='warn'
      aria-label='Rimuovi filtri'>
      <mat-icon>filter_alt_off</mat-icon>
    </button>

  </div>

</div>

<ng-template #dateTemplate let-row='row' let-value='value' let-i='index'>

  <p *ngIf='value'>
    <strong>{{ value | date:'dd/MM/yy' }}</strong>
    <ng-container *ngIf='row.ParentProductDescription'> - {{ row.ParentProductDescription }}</ng-container>
  </p>

</ng-template>

<ng-template #timeTemplate let-row='row' let-value='value' let-i='index'>

  <p *ngIf='value?.length'>
    <strong> {{ value[0].TimeForGrid | splittedTimeFormat }} </strong>
  </p>

</ng-template>
