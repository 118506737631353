import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckOutComponent } from './check-out.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CheckoutConfirmComponent } from './checkout-payment/checkout-confirm/checkout-confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { CheckoutRecapComponent } from './checkout-recap/checkout-recap.component';
import { CheckoutPaymentComponent } from './checkout-payment/checkout-payment.component';
import { CheckoutPaymentBoxComponent } from './checkout-payment/checkout-payment-box/checkout-payment-box.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {
  CheckoutPaymentMessageComponent,
} from './checkout-payment-message/checkout-payment-message.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { CheckoutSplittedWarningComponent } from './checkout-splitted-warning/checkout-splitted-warning.component';
import { ParentRedirectConfirmComponent } from './parent-redirect-confirm/parent-redirect-confirm.component';

@NgModule({
  declarations: [CheckOutComponent, CheckoutConfirmComponent, CheckoutRecapComponent, CheckoutPaymentComponent, CheckoutPaymentBoxComponent, CheckoutPaymentMessageComponent, CheckoutSplittedWarningComponent, ParentRedirectConfirmComponent],
  imports: [
    CommonModule,
    ClipboardModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [CheckOutComponent],
})
export class CheckoutModule {
}
