import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AthleteCardComponent } from './athlete-card.component';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { SecurePipeModule } from '../../../../core/pipes/secure-pipe.module';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    AthleteCardComponent,
  ],
  imports: [
    CommonModule,
    MatLegacyCardModule,
    SecurePipeModule,
    MatLegacyListModule,
    MatIconModule,
    MatLegacyButtonModule,
    RouterModule,
    FlexModule,
  ],
  exports: [
    AthleteCardComponent,
  ],
})
export class AthleteCardModule {
}
