import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PoolLengthDTO } from '../../../core/controllers/event.controller';

export const POOL_LENGTHS: PoolLengthDTO[] = [
  { Oid: 0, Value: 25 },
  { Oid: 1, Value: 50 },
];

@Component({
  selector: 'app-spn-pool-length-filter',
  templateUrl: './spn-pool-length-filter.component.html',
})
export class SpnPoolLengthFilterComponent {

  @Input() control: FormControl | null = null;

  protected readonly POOL_LENGTHS = POOL_LENGTHS;

}
