import { Injectable } from '@angular/core';
import { CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { UserService } from './user.service';
import { CurrentSeason } from './season.service';
import { CtFileManagementService } from '@ctsolution/ct-file-management';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(
    private userService: UserService,
    private webapi: CtWebapiService,
    private fileManager: CtFileManagementService) {
  }

  async downloadCalendar() {

    const eventCalendar: string | null = await this.userService.getEventCalendar();

    if (!eventCalendar) {
      return;
    }

    const request: DataRequest = new DataRequest(eventCalendar);
    request.setResponseType('blob');

    this.webapi.get(request)
      .subscribe((result: any) => this.fileManager.downloadPdfFromApiResult(result, `calendario-spn-${new CurrentSeason().startYear}`));

  }

}
