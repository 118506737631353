<mat-form-field *ngIf='control' appearance='outline'>

  <mat-label>Gruppo di allenamento</mat-label>

  <mat-select [formControl]='control'>

    <mat-option *ngFor='let company of values' [value]='company.BusinessName'>
      {{company.BusinessName}}
    </mat-option>
    <mat-option [value]='null'>Vedi tutto</mat-option>
  </mat-select>

</mat-form-field>
