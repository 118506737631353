import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationCompaniesSelectControlComponent } from './notification-companies-select-control.component';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    NotificationCompaniesSelectControlComponent,
  ],
  imports: [
    CommonModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    ReactiveFormsModule,
  ],
  exports: [
    NotificationCompaniesSelectControlComponent,
  ],
})
export class NotificationCompaniesSelectControlModule {
}
