<div class='send-notifications-container'>


  <h2 class='title-min-size'> Le mie comunicazioni </h2>

  <div align='end'>

    <button mat-raised-button color='primary' (click)='newNotification()'>Nuova notifica
      <mat-icon>send</mat-icon>
    </button>

  </div>

  <div class='m-t-15'>

    <app-notifications-widget
      [configuration]='sendNotificationsWidgetConfiguration'
      (onEdit)='newNotification($event)'
      (onDelete)='delete($event)'></app-notifications-widget>

  </div>

</div>
