import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '../../../core/lib/user.service';
import { CompanyController } from '../../../core/controllers/company.controller';
import { FilterCacheService } from '../../../core/lib/filter-cache.service';

@Component({
  selector: 'app-spn-company-business-name-filter[parentName]',
  templateUrl: './spn-company-business-name-filter.component.html',
  providers: [CompanyController],
})
export class SpnCompanyBusinessNameFilterComponent {

  @Input() control: FormControl | null = null;
  @Input() parentName: string | null = null;
  values: any[] = [];

  constructor(private userService: UserService, private companyController: CompanyController, private filterService: FilterCacheService) {

    this.setupValues();

  }


  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const componentKey = `${this.constructor.name + (this.parentName ? ('_' + this.parentName) : '')}SetupCompleted`;

    if (this.filterService.getDefaultValue(componentKey)) return;

    const value = await this.userService.getCompanyBusinessName();

    if (!value || this.control?.value) return;

    this.control?.setValue(value.toUpperCase());

    this.filterService.saveDefaultValue(componentKey, 'true');

  }


  setupValues() {

    this.companyController
      .list()
      .then(data => this.values = data.Result);

  }

}
