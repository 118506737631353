import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {

  constructor(private router: Router, private location: Location) {
  }

  navigateTo = (commands: string[], extras: NavigationExtras = {}) => this.router.navigate(commands, extras);

  navigateToRoot = () => this.navigateTo(['/']);

  back = () => this.location.back();

  upperCamelCase(stringToUp: string): string {

    const splitString = stringToUp.toLowerCase().split(' ');

    for (let i = 0; i < splitString.length; i++) {

      splitString[i] = splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);

    }

    return splitString.join(' ');

  }

}
