<div class='profile-page-cntr'>

  <section>

    <h2> Profilo </h2>

    <div class='profile-cntr mb-5'>

      <div class='profile-cntr-box'>

        <mat-card>

          <mat-card-content>

            <form [formGroup]='form'>

              <div class='text-center m-b-15'>

                <app-user-profile-image></app-user-profile-image>

              </div>

              <div fxLayout='row wrap'>

                <div fxFlex.gt-md='50' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>
                  <mat-form-field>
                    <input matInput placeholder='Nome' formControlName='Name' />
                  </mat-form-field>
                </div>

                <div fxFlex.gt-md='50' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>
                  <mat-form-field>
                    <input matInput placeholder='Cognome' formControlName='Surname' />
                  </mat-form-field>
                </div>

                <div fxFlex.gt-md='50' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>
                  <mat-form-field>
                    <input matInput placeholder='Data di nascita' formControlName='DateOfBirth' />
                  </mat-form-field>
                </div>

                <div fxFlex.gt-md='50' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>
                  <mat-form-field>
                    <input matInput placeholder='Codice fiscale' formControlName='FiscalCode' />
                  </mat-form-field>
                </div>

                <div fxFlex.gt-md='100' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>
                  <mat-form-field>
                    <input matInput placeholder='Email' formControlName='Email' />
                  </mat-form-field>
                </div>

                <div fxFlex.gt-md='100' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>
                  <mat-form-field>
                    <input matInput type='tel' placeholder='Cellulare' formControlName='Phone' />
                  </mat-form-field>
                </div>

              </div>

              <h3> Dati di residenza </h3>

              <div fxLayout='row wrap'>

                <div fxFlex.gt-sm='100' fxFlex.gt-md='100' fxFlex='100' class='p-10'>
                  <mat-form-field>
                    <input matInput placeholder='Indirizzo' formControlName='Address' />
                    <mat-error *ngIf="form.get('Address')?.hasError('required')"> * Campo obbligatorio</mat-error>
                    <mat-error *ngIf="form.get('Address')?.hasError('maxlength')"> * La lunghezza del campo non può
                      superare
                      i {{ form.get('Address')?.getError('maxlength').requiredLength }} caratteri
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex.gt-sm='100' fxFlex.gt-md='100' fxFlex='100' class='p-10'>

                  <mat-form-field>
                    <mat-label>Città</mat-label>
                    <input type='text' matInput formControlName='City'>
                    <mat-error *ngIf="form.get('City')?.hasError('required')"> * Campo obbligatorio</mat-error>
                    <mat-error *ngIf="form.get('City')?.hasError('maxlength')"> * Campo non valido</mat-error>
                  </mat-form-field>

                </div>

                <div fxFlex.gt-sm='100' fxFlex.gt-md='50' fxFlex='100' class='p-10'>
                  <mat-form-field>

                    <mat-label>CAP</mat-label>
                    <input type='text' matInput formControlName='ZipCode'>
                    <mat-error *ngIf="form.get('ZipCode')?.hasError('required')">* Campo obbligatorio</mat-error>
                    <mat-error
                      *ngIf="form.get('ZipCode')?.hasError('maxlength') || form.get('ZipCode')?.hasError('minlength') || form.get('ZipCode')?.hasError('numericOnly')">
                      * Campo non valido.
                    </mat-error>
                  </mat-form-field>

                </div>

                <div fxFlex.gt-sm='100' fxFlex.gt-md='50' fxFlex='100' class='p-10'>

                  <mat-form-field>
                    <mat-label>Provincia</mat-label>
                    <input type='text' matInput formControlName='Province' maxlength='2'>
                    <mat-error *ngIf="form.get('Province')?.hasError('required')"> * Campo obbligatorio</mat-error>
                    <mat-error *ngIf="form.get('Province')?.hasError('maxlength')"> * Campo non valido</mat-error>
                  </mat-form-field>

                </div>

                <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='text-center'>
                  <p (click)='updateProfile()'>
                    <a class='text-info link'>Salva profilo</a>
                  </p>
                </div>

              </div>

              <mat-divider></mat-divider>

              <div fxLayout='row wrap'>

                <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='text-center'>
                  <p>
                    <a [routerLink]="['change-password']"
                       class='text-info link'>Modifica la password</a>

                  </p>
                </div>

                <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='text-center'
                     *ngIf='ctwebview.isCtWebView()'>
                  <p>
                    <a (click)='deleteProfile()' class='text-info link danger'>Cancella profilo</a>
                  </p>
                </div>

              </div>

            </form>

          </mat-card-content>

        </mat-card>

      </div>

    </div>

  </section>

  <section>

    <mat-divider></mat-divider>

    <app-privacy-policy></app-privacy-policy>

  </section>

</div>
