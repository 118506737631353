import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SPNEventProduct } from '../../../../../core/classes/SPNEvent';
import { ProfileController } from '../../../../../core/controllers/profile.controller';
import { DataRequest } from '@ctsolution/ct-webapi';
import { CompareSpecialtyTrend } from './event-results-list/event-results-list/event-results-list.component';
import {
  HistoricalEventDetailFilter,
} from '../../../historical/historical-event-detail/historical-event-detail.component';
import { FileService } from '../../../../../core/lib/file.service';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent {

  @Input() product: SPNEventProduct | null = null;

  @Input() historicalDetailFilters?: HistoricalEventDetailFilter;

  @Output() updateList: EventEmitter<any> = new EventEmitter<any>();
  @Output() compare: EventEmitter<CompareSpecialtyTrend> = new EventEmitter<CompareSpecialtyTrend>();

  viewModel: any = {

    athleteImagePreviewUrl: null,
    subscribable: false,
    confirmed: false,
    icon: null,

  };

  constructor(private profileController: ProfileController, private _file: FileService, private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    if (this.product?.EventProduct) {

      this.viewModel.subscribable = this.product.EventProduct.subscribable();
      this.viewModel.confirmed = this.product.EventProduct.confirmed();
      this.viewModel.icon = this.product.EventProduct.getIcon();

      if (this.viewModel.confirmed) {

        this.getUserProfilePic();

      }

    }

    this.cdr.detectChanges();

  }

  getUserProfilePic(): void {

    this.profileController
      .getPhotoPreviewUrl()
      .then((response: string | null) => {

        if (response) {

          this.viewModel.athleteImagePreviewUrl = response;

        }

      });

  }

  downloadBrochure(): void {

    if (!this.product?.UrlEventPosterDocument) return;

    this._file
      .getPDFFile(new DataRequest(this.product.UrlEventPosterDocument), `${this.product.Name}`);

  }

  downloadLimitTimes(): void {

    if (!this.product?.UrlEventTimeLimitDocument) return;

    this._file
      .getPDFFile(new DataRequest(this.product.UrlEventTimeLimitDocument), `TL-${this.product.Name}`);

  }

  downloadConvocationDocument(): void {

    if (!this.product?.UrlEventConvocationDocument) return;

    this._file
      .getPDFFile(new DataRequest(this.product.UrlEventConvocationDocument), `${this.product.Name}-calls`);

  }

}
