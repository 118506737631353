import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CtWidgetModule } from '@ctsolution/ct-framework';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    PrivacyPolicyComponent,
  ],
  exports: [
    PrivacyPolicyComponent,
  ],
  imports: [
    CommonModule,
    CtWidgetModule,
    FlexLayoutModule,
  ],
})
export class PrivacyPolicyModule {
}
