<div [hidden]='!results.length'>

  <h3> {{ chart.title }}</h3>

  <div #ContainerRef>

    <ngx-charts-line-chart
      [view]='[ContainerRef.offsetWidth, ContainerRef.offsetHeight]'
      [results]='results'
      [yAxis]='chart.yAxis'
      [xAxis]='chart.xAxis'
      [legend]='chart.legend'
      [legendPosition]='chart.legendPosition'
      [yAxisTickFormatting]='chart.yAxisTickFormatting'
      [xAxisTickFormatting]='chart.xAxisTickFormatting'
      [showYAxisLabel]='chart.showYAxisLabel'
      [showXAxisLabel]='chart.showXAxisLabel'
      [yScaleMin]='chart.yScaleMin'
      [yAxisLabel]='chart.yAxisLabel'
      [xAxisLabel]='chart.xAxisLabel'
      [referenceLines]='chart.referenceLines'
      [showRefLines]='chart.showRefLines'
      [showRefLabels]='chart.showRefLabels'>

      <ng-template #tooltipTemplate let-model='model'>

        {{ model[0]?.series }} {{ timeService.msToTime(model[0]?.value ?? 0)}}

      </ng-template>

      <ng-template #seriesTooltipTemplate let-model='model'>

        {{ model[0]?.series }} {{ timeService.msToTime(model[0]?.value ?? 0)}}

      </ng-template>

    </ngx-charts-line-chart>

  </div>

</div>
