import { GenderEnum } from '../enums/gender.enum';

export class RecordFilter {

  PersonalOnly: boolean | null = null;
  Type: RecordType | null = null;
  Category: RecordCategory | null = null;
  SwimStyleOid: number | null = null;
  Distance: number | null = null;
  Gender: GenderEnum | null = null;
  PoolLength: number | null = null;

  private constructor() {
  }

  public static create = (): RecordFilter => new RecordFilter();

}

export enum RecordType {

  Societario,
  Regionale,
  Nazionale,
  Europeo,
  Mondiale

}

export enum RecordCategory {

  Ragazzi14,
  Ragazzi,
  Juniores,
  Cadetti,
  Assoluti,
  Giovanili

}
