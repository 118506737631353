import { ProductCategoryMacroTypeEnum } from '../enums/product-category-macro-type.enum';

export class ProductListFilter {

  ProductCategoryOID?: number;
  ProductOIDs?: number[];

  constructor(
    public CategoryMacroType: ProductCategoryMacroTypeEnum
  ) {
  }

}
