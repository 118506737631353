import { Component } from '@angular/core';
import { EventController, EventListFilterDTO } from '../../../../core/controllers/event.controller';
import { SPNEventProduct } from '../../../../core/classes/SPNEvent';
import { EventFilterStatus } from '../../../../core/enums/event-filter-status';
import { CtDatatableResult } from '@ctsolution/ct-framework';

@Component({
  selector: 'app-my-competitions',
  templateUrl: './my-competitions.component.html',
  styleUrls: ['./my-competitions.component.scss']
})
export class MyCompetitionsComponent {

  constructor(private eventController: EventController) {
  }

  getConfirmed = (params: EventListFilterDTO = new EventListFilterDTO()): Promise<CtDatatableResult<SPNEventProduct>> => this.eventController.getList(this.getEventFilter(params, EventFilterStatus.Confirmed));

  getPending = (params: EventListFilterDTO = new EventListFilterDTO()): Promise<CtDatatableResult<SPNEventProduct>> => this.eventController.getList(this.getEventFilter(params, EventFilterStatus.AvailableForUser));

  private getEventFilter(params: EventListFilterDTO, status: EventFilterStatus): EventListFilterDTO {

    params.Status = status;
    return params;

  }

}
