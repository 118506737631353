import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentBoxUploaderComponent} from './document-box-uploader.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {FlexLayoutModule} from '@angular/flex-layout';
import { DocumentSingleBoxUploaderComponent } from './document-single-box-uploader/document-single-box-uploader.component';
import { SecurePipeModule } from '../../../core/pipes/secure-pipe.module';

@NgModule({
  declarations: [
    DocumentBoxUploaderComponent,
    DocumentSingleBoxUploaderComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    SecurePipeModule
  ],
  exports: [
    DocumentBoxUploaderComponent
  ]
})
export class DocumentBoxUploaderModule {
}
