<app-event-card
  *ngIf='data.eventProduct?.EventProduct'
  [product]='data.eventProduct'
  [historicalDetailFilters]='data.filters'
  (compare)='compare($event)'>
</app-event-card>

<div class='historical-event-detail-actions container'>

  <button
    mat-raised-button
    class='w-100'
    (click)='close()'
    color='primary'> Chiudi
  </button>

</div>
