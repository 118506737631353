import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { CTDatatablePaginationParameter, CtDatatableResult } from '@ctsolution/ct-framework';
import { EventListFilterDTO } from './event.controller';
import { TimeLimitResult } from '../interfaces/time-limit';

const TIME_LIMIT_CONTROLLER = 'TimeLimit';

@Injectable()
export class TimeLimitController {

  constructor(private _webapi: CtWebapiService) {
  }

  getList(filters?: EventListFilterDTO, pagination: CTDatatablePaginationParameter = new CTDatatablePaginationParameter()): Promise<CtDatatableResult<TimeLimitResult>> {

    if (!filters) filters = new EventListFilterDTO();

    return new Promise<CtDatatableResult<TimeLimitResult>>((resolve) => {

      let params: any = {
        ...filters,
        ...pagination,
        hideSpinner: true,
      };

      params = Object.fromEntries(Object.entries(params).filter(([key, value]) => (!!value || value === 0)));

      const request: DataRequest = new DataRequest(`/${TIME_LIMIT_CONTROLLER}/GetFulfilledList`)
        .setQueryParams(params);

      this._webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<CtDatatableResult<TimeLimitResult>>) => {

          resolve(response.Result);

        });

    });

  }

}
