<form [formGroup]="form">

  <ng-container formArrayName="formArray" *ngIf="form.get('formArray')">

    <app-document-box-uploader
      *ngFor="let configuration of documentBoxUploaderConfigurationsArray"
      [configuration]="configuration"></app-document-box-uploader>

  </ng-container>

</form>
