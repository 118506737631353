import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { RecordCategory, RecordFilter } from '../../../../core/classes/record-filter';
import { RoleService } from '../../../../core/lib/role.service';
import {
  SpnRecordCategoryFilterComponent,
} from '../../../../_components/spn-filters/spn-record-category-filter/spn-record-category-filter.component';
import { GenderEnum } from '../../../../core/enums/gender.enum';
import { FilterCacheService } from '../../../../core/lib/filter-cache.service';

@Component({
  selector: 'app-record-filter',
  templateUrl: './record-filters.component.html',
})
export class RecordFiltersComponent {

  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(SpnRecordCategoryFilterComponent) recordCategoryFilterComponent: SpnRecordCategoryFilterComponent | null = null;

  form!: FormGroup;
  expandedFilterPanel = true;

  viewModel: any = {

    isTechnical: null,

  };

  constructor(
    private fb: FormBuilder,
    private cacheService: FilterCacheService,
    private roleService: RoleService) {

    this.form = this.fb.group({
      Type: new FormControl(null),
      Category: new FormControl(null),
      SwimStyleOid: new FormControl(null),
      Distance: new FormControl(null),
      PoolLength: new FormControl(null),
      Gender: new FormControl(null),
      PersonalOnly: new FormControl(null),
    });

    this.form
      .valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {

        if (value.Gender === GenderEnum.Femminile) {

          if (value.Category === RecordCategory.Ragazzi14) {

            this.form.get('Category')?.setValue(null);

          }

          if (this.recordCategoryFilterComponent) {

            this.recordCategoryFilterComponent.values = this.recordCategoryFilterComponent?.values.filter(elm => (elm as any) !== RecordCategory.Ragazzi14);

          }

        } else {

          this.recordCategoryFilterComponent?.setupValues();

        }

        const formValue = this.form.value;

        this.cacheService.saveFormValue(RecordFiltersComponent, formValue);

        this.update.emit();

      });

    const prevValue = this.cacheService.getFormValue(RecordFiltersComponent);

    if (prevValue) {

      this.form.patchValue(prevValue);
      this.expandedFilterPanel = true;

    } else {

      this.setup()
        .then();

    }

  }

  async setup() {

    this.viewModel.isTechnical = await this.roleService.isTechnical();

    this.form.get('PersonalOnly')?.setValue(!this.viewModel.isTechnical);

  }

  setFilters(parameter: RecordFilter): RecordFilter {

    const value: any = this.form?.value;

    parameter = Object.assign({}, value);

    return parameter;

  }

}
