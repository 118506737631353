import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SPNEvent, SPNEventProduct } from '../../../../core/classes/SPNEvent';
import { EventController } from '../../../../core/controllers/event.controller';
import {
  CompareSpecialtyTrend,
} from '../../_components/event-card/event-card/event-results-list/event-results-list/event-results-list.component';
import { GetEventDetailParameter } from '../../../../core/classes/get-event-detail-parameter';

@Component({
  selector: 'app-historical-event-detail',
  templateUrl: './historical-event-detail.component.html',
  styleUrls: ['./historical-event-detail.component.scss'],
})
export class HistoricalEventDetailComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: HistoricalEventDetailConfiguration,
    private _bottomSheet: MatBottomSheetRef<HistoricalEventDetailComponent>,
    private eventController: EventController) {
  }

  ngOnInit(): void {

    if (this.data.bypassFetch) return;

    this.getResults();

  }

  getResults(): void {

    const parameter: GetEventDetailParameter = GetEventDetailParameter
      .create()
      .setProductOid(this.data.eventProduct.Oid);

    if (this.data.userInfoOid) {

      parameter
        .setUserInfoOid(this.data.userInfoOid);

    }

    this.eventController
      .getDetail(parameter)
      .then((result: SPNEventProduct) => {

        this.data.eventProduct = SPNEventProduct.create(result);

      });

  }

  close = (trend?: CompareSpecialtyTrend) => this._bottomSheet.dismiss(trend);

  compare = (event: CompareSpecialtyTrend) => this.close(event);

}

export class HistoricalEventDetailConfiguration {

  filters: HistoricalEventDetailFilter = new HistoricalEventDetailFilter();
  bypassFetch: boolean | null = null;
  userInfoOid: number | null = null;

  private constructor(public eventProduct: SPNEventProduct) {
  }

  public static create = (model: SPNEventProduct): HistoricalEventDetailConfiguration => new HistoricalEventDetailConfiguration(model);

  setBypassFetch(value: boolean | null): HistoricalEventDetailConfiguration {

    this.bypassFetch = value;
    return this;

  }

  setUserInfoOid(value: number | null): HistoricalEventDetailConfiguration {

    this.userInfoOid = value;
    return this;

  }

}

export class HistoricalEventDetailFilter {

  SwimStyle: number | null = null;
  Distance: number | null = null;
  BypassTrend: boolean | null = null;

  constructor() {
  }

  setSwimStyle(value: number | null): HistoricalEventDetailFilter {

    this.SwimStyle = value;
    return this;

  }

  setDistance(value: number | null): HistoricalEventDetailFilter {

    this.Distance = value;
    return this;

  }

  setBypassTrend(value: boolean | null): HistoricalEventDetailFilter {

    this.BypassTrend = value;
    return this;

  }

}
