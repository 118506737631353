<h2>Informative</h2>

<div fxLayout='row wrap'>

  <div
    fxFlex.gt-sm='33.33'
    fxFlex.gt-xs='100'
    fxFlex='100'
    *ngFor='let widget of widgets'>

    <ct-widget [configuration]='widget'></ct-widget>

  </div>

</div>
