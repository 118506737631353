import { Component } from '@angular/core';

@Component({
  selector: 'app-file-max-size-warning-dialog',
  template: `
    <h1 mat-dialog-title>Attenzione</h1>
    <div mat-dialog-content>
      <p>Il file che stai provando a caricare è troppo pesante.</p>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button [mat-dialog-close]>
        Chiudi
      </button>
    </div>
  `
})
export class FileMaxSizeWarningDialogComponent {

}
