<mat-list-item role='listitem' class='purchase-item'>

  <mat-icon mat-list-icon color='primary'>shopping_cart_checkout</mat-icon>
  <div mat-line class='purchaseTitle'>
    <span class='productName'>{{item.Product.Name}}
      <ng-container *ngIf='viewModel.multipleItems '> x {{ item.Quantity }}</ng-container></span>
    <span class='productPrice' *ngIf='item.FinalAmount'>
     <small *ngIf='viewModel.multipleItems '>{{ item.Quantity }} x</small> {{item.FinalAmount | currency:'EUR':'symbol':'1.0' }}
    </span>
  </div>
  <div mat-line>{{item.Product.Description}}</div>
  <div mat-line *ngIf='item.Variations'><small style="color: #67757c">{{item.Variations}}</small></div>

</mat-list-item>
