import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CtWebviewService } from '@ctsolution/ct-webview';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  constructor(private snackbar: MatSnackBar, private translate: TranslateService, private webViewService: CtWebviewService, private renderer: Renderer2) {

    this.webViewService.subscribeToInAppOpeningIntents();
    translate.setDefaultLang('it');

  }

  ngOnInit() {

    if (!environment.production) {

      this.snackbar.open('Modalità di Sviluppo Attiva', 'X', {
        duration: 3000,
        panelClass: 'general-snackbar-panel',
      });

    }

    if (this.webViewService.isCtWebView()) {

      this.renderer.addClass(document.body, 'webview-container');

    }

  }



}
