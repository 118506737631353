import { Component, Inject, OnInit } from '@angular/core';
import {
  GlobalNotificationController,
  SPNNotification,
} from '../../../../core/controllers/global-notification.controller';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalNotificationParameter } from '../../../../core/classes/global-notification-parameter';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { CTDateAdapter } from '@ctsolution/ct-framework';
import {
  FileMaxSizeWarningDialogComponent,
} from './file-max-size-warning-dialog/file-max-size-warning-dialog.component';
import {
  NotificationEventSelectControlConfiguration,
} from './notification-event-select-control/notification-event-select-control.configuration';

const FILE_MAX_SIZE_IN_BYTES = 10 * 1024 * 1024; // 10MB

@Component({
  selector: 'app-generate-notification-form-dialog',
  templateUrl: './generate-notification-form-dialog.component.html',
  styleUrls: ['./generate-notification-form-dialog.component.scss'],
})
export class GenerateNotificationFormDialogComponent implements OnInit {

  form: FormGroup;
  notificationEventSelectControlConfiguration = NotificationEventSelectControlConfiguration
    .create()
    .setMultiple(true);

  constructor(
    private dialogRef: MatDialogRef<GenerateNotificationFormDialogComponent>,
    private globalNotificationController: GlobalNotificationController,
    private dialog: MatLegacyDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: SPNNotification) {

    this.form = this.fb
      .group({
        Oid: new FormControl(0),
        EventProducts: new FormControl([]),
        Companies: new FormControl([]),
        Title: new FormControl(null, [Validators.required]),
        Body: new FormControl(null, [Validators.required, Validators.maxLength(512)]),
        EnabledDate: new FormControl(null, [Validators.required]),
        TechnicalOnly: new FormControl(false),
        GlobalNotificationFileUploadName: new FormControl(null),
        GlobalNotificationFileUpload: new FormControl(null),
      });

  }

  ngOnInit() {

    if (this.data) {


      this.form.patchValue(this.data);

      if (this.form.value.EnabledDate) {

        this.form.get('EnabledDate')?.setValue(new Date(this.form.value.EnabledDate));

      }

      if (this.data.FileName) {

        this.form.get('GlobalNotificationFileUploadName')?.setValue(this.data.FileName);
        this.form.get('GlobalNotificationFileUploadName')?.updateValueAndValidity();
        this.form.get('GlobalNotificationFileUploadName')?.disable();

        this.form.get('GlobalNotificationFileUpload')?.disable();

      }

    }

  }

  send() {

    if (this.form.valid) {

      const parameter = GlobalNotificationParameter
        .create(this.form.value);

      if (parameter.EnabledDate) {

        parameter.EnabledDate = CTDateAdapter.create().transformDateToCurrentTimezone(parameter.EnabledDate as Date).toISOString();

      }

      if (!parameter.Companies) parameter.Companies = new Array<number>();

      this.globalNotificationController
        .insertGlobalNotification(parameter)
        .subscribe(() => this.dialogRef.close(true));

    }

  }

  onFileSelected(event: any): void {

    if (event.target.files && event.target.files.length > 0) {

      const selectedFile = event.target.files[0] as File;

      if (selectedFile) {

        if (selectedFile.size > FILE_MAX_SIZE_IN_BYTES) {

          this.dialog.open(FileMaxSizeWarningDialogComponent);
          return;

        }

        this.form.get('GlobalNotificationFileUpload')?.setValue(selectedFile);
        this.form.get('GlobalNotificationFileUpload')?.updateValueAndValidity();


        this.form.get('GlobalNotificationFileUploadName')?.setValue(selectedFile.name);
        this.form.get('GlobalNotificationFileUploadName')?.updateValueAndValidity();

      }

    } else {

      // Se non viene selezionato alcun file, puoi gestire questa condizione come desideri.
      console.error('Nessun file selezionato');

    }

  }

}
