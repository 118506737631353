import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileController } from 'src/app/core/controllers/profile.controller';
import { GeneralService } from '../../../core/lib/general.service';
import {
  DocumentsUploaderConfiguration,
} from '../../../_components/documents-uploader/documents-uploader.configuration';
import { DocumentsUploaderComponent } from '../../../_components/documents-uploader/documents-uploader.component';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { UserService } from '../../../core/lib/user.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FileMacroTypeEnum } from '../../../core/enums/file-macro-type.enum';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {

  documentsUploaderConfiguration: DocumentsUploaderConfiguration = DocumentsUploaderConfiguration.create();
  @ViewChild('documentsUploader') documentsUploader!: DocumentsUploaderComponent;

  skipEnabled = false;
  comesFromRegistration = false;

  constructor(
    private generalService: GeneralService,
    private profileController: ProfileController,
    private ctAuthenticationService: CtAuthenticationService,
    private route: ActivatedRoute,
    private userService: UserService,
    private matSnackbar: MatSnackBar,
  ) {

    this.route
      .queryParams
      .subscribe(qp => {

        this.comesFromRegistration = qp.registration;

      });

  }

  ngOnInit(): void {

    this.setup()
      .then(() => this.getRequestedDocuments());

  }

  async setup() {

    this.skipEnabled = await this.userService.adultUser();

  }

  getRequestedDocuments(): void {

    this.profileController
      .getRegistrationDocuments()
      .then(data => {

        const athleteDocs = data
          .filter((doc: any) => doc.MacroType !== FileMacroTypeEnum.PARENT_DOCUMENT)
          .sort((a: any, b: any) => a.MacroType - b.MacroType);

        this.documentsUploaderConfiguration.setValues(athleteDocs);
        this.documentsUploader.buildConfigurationValues();

      });

  }

  goToProducts(): void {

    this.documentsUploader.form.markAllAsTouched();

    if (this.documentsUploader.form.valid) {

      this.ctAuthenticationService
        .getClaims()
        .then(() => {

          this.generalService
            .navigateTo(['/'], { queryParams: { registration: true } })
            .then(() => {

              let message: string = 'Documenti salvati correttamente!';

              if (this.comesFromRegistration) {

                message = 'Profilo configurato correttamente.';

              }

              this.matSnackbar.open(message, 'X', {
                duration: 3000,
                panelClass: 'general-snackbar-panel',
              });

            });

        });

    }

  }

  skip(): void {

    const extras: NavigationExtras = {};

    if (this.comesFromRegistration) {

      extras.queryParamsHandling = 'merge';

    }

    this.generalService.navigateTo(['/'], extras);

  }

}
