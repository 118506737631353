import { Component } from '@angular/core';
import {
  NotificationsWidgetConfiguration,
} from '../../../_components/notifications-widget/notifications-widget.configuration';
import {
  GlobalNotificationController,
  SPNNotification,
} from '../../../core/controllers/global-notification.controller';
import {
  GenerateNotificationFormDialogService,
} from './generate-notification-form-dialog/generate-notification-form-dialog.service';
import { DeleteConfirmService } from '@ctsolution/ct-framework';

@Component({
  selector: 'app-generate-notification',
  templateUrl: './generate-notification.component.html',
  styleUrls: ['./generate-notification.component.scss'],
  providers: [DeleteConfirmService],
})
export class GenerateNotificationComponent {

  sendNotificationsWidgetConfiguration: NotificationsWidgetConfiguration = NotificationsWidgetConfiguration
    .create()
    .setTechnicalSentView(true);

  constructor(
    private notificationsController: GlobalNotificationController,
    private deleteConfirmService: DeleteConfirmService,
    private generateNotificationFormDialogService: GenerateNotificationFormDialogService) {
  }

  ngOnInit() {

    this.getList();

  }

  getList() {

    this.notificationsController
      .getTechnicalList()
      .then((result: SPNNotification[]) => this.sendNotificationsWidgetConfiguration.setList(result));

  }

  newNotification(parameter?: SPNNotification) {

    let ref = this.generateNotificationFormDialogService.open(parameter);

    ref
      .beforeClosed()
      .subscribe(refresh => {

        if (refresh) this.getList();

      });

  }

  delete(notification: SPNNotification) {

    if (!notification.Oid) return;

    this.deleteConfirmService
      .open()
      .afterClosed()
      .subscribe(confirm => {

        if (confirm) {

          this.notificationsController
            .deleteGlobalNotification(notification.Oid!)
            .subscribe(() => this.getList());

        }

      });

  }

}
