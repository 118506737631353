import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse,
} from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { ApiCacheService } from './api-cache.service';

@Injectable()
export class ApiCacheInterceptor implements HttpInterceptor {

  private excludedUrls: string[] = ['/login/', '/profile/', '/account/', '/cart/', '/coupon/'];

  constructor(private apiCacheService: ApiCacheService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    const key = this.createCacheKey(request);

    // Se l'URL inizia o contiene una delle stringhe escluse, non salvare nella cache
    if (this.shouldExcludeUrl(request.urlWithParams)) {

      return next.handle(request);

    }

    const cachedData = this.apiCacheService.get(key);

    if (cachedData) {
      // Serve la risposta dalla cache e completa la richiesta
      return of(new HttpResponse({ body: cachedData }));
    }

    // Continua con la richiesta e salva la risposta nella cache
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.apiCacheService.save(key, event.body);
        }
      }),
    );
  }

  private createCacheKey(request: HttpRequest<unknown>): string {
    return request.urlWithParams;
  }

  private shouldExcludeUrl(url: string): boolean {
    return this.excludedUrls.some((excludedUrl) => url.toLowerCase().startsWith(excludedUrl) || url.toLowerCase().includes(excludedUrl));
  }

}
