import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CtDatatableModule } from '@ctsolution/ct-framework';
import { RecordComponent } from './record.component';
import { RecordFilterModule } from './record-filter/record-filter.module';
import {
  SplittedTimeFormatModule
} from '../_components/event-card/event-card/event-results-list/splitted-time-format/splitted-time-format.module';

@NgModule({
  declarations: [
    RecordComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: RecordComponent,
        data: {
          title: 'Record',
        },
      },
    ]),
    CtDatatableModule,
    RecordFilterModule,
    SplittedTimeFormatModule,
  ],
})
export class RecordModule {
}
