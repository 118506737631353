<mat-accordion
  class='event-results-list-cntr'
  [multi]='true'>

  <mat-expansion-panel
    *ngFor='let result of viewModel.list'>

    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ result.RaceSpecialtySwimStyleName }}
        <ng-container *ngIf='result.RaceSpecialtyDistance'>{{ result.RaceSpecialtyDistance }}m</ng-container>
      </mat-panel-title>
      <mat-panel-description *ngIf='result.RaceSpecialtyDistance'>
        Tempo totale: {{ result.TimeForGrid | splittedTimeFormat }}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <app-event-splitted-times [result]='result'></app-event-splitted-times>

    <div class='event-result-actions-cntr'
         [hidden]='(historicalDetailFilters?.SwimStyle && historicalDetailFilters?.Distance) || historicalDetailFilters.BypassTrend'>

      <button mat-button color='primary' (click)='trend(result)'>
        Andamento {{ result.RaceSpecialtySwimStyleName }} {{ result.RaceSpecialtyDistance }}m
        <mat-icon>compare_arrows</mat-icon>
      </button>

    </div>

  </mat-expansion-panel>

</mat-accordion>
