import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { SPNCompanyDTO } from '../interfaces/company';

@Injectable()
export class CompanyController {

  controller: string = '/Company/';

  params: any = {};

  constructor(private ctWebapiService: CtWebapiService) {
  }

  list(): Promise<CtWebapiGenericResponse<SPNCompanyDTO[]>> {

    return new Promise<CtWebapiGenericResponse<SPNCompanyDTO[]>>((resolve) => {

      this.ctWebapiService.get(new DataRequest(`${this.controller}List`)).subscribe(r => resolve(r as CtWebapiGenericResponse<SPNCompanyDTO[]>));

    });

  }

}
