import { Component, EventEmitter, Output } from '@angular/core';
import { EventActionsEnum } from './event-actions.enum';

@Component({
  selector: 'app-event-actions',
  templateUrl: './event-actions.component.html',
  styleUrls: ['./event-actions.component.scss']
})
export class EventActionsComponent {

  @Output() action: EventEmitter<EventActionsEnum> = new EventEmitter<EventActionsEnum>();

  constructor() { }

  directPurchase() {

    this.action.emit(EventActionsEnum.DIRECT_PAYMENT);

  }

  addToCart() {

    this.action.emit(EventActionsEnum.ADD_TO_CART);

  }

}
