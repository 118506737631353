<h1 mat-dialog-title>Invio comunicazione</h1>
<div mat-dialog-content>

  <form [formGroup]='form'>

    <mat-form-field>

      <mat-label>Titolo</mat-label>

      <input matInput formControlName='Title' />
      <mat-error *ngIf="form.get('Title')?.hasError('required')">* Campo obbligatorio
      </mat-error>

    </mat-form-field>

    <mat-form-field>
      <mat-label>Contenuto</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        formControlName='Body'
        #autosize='cdkTextareaAutosize'
        cdkAutosizeMinRows='3'
        cdkAutosizeMaxRows='5'></textarea>
      <mat-error *ngIf="form.get('Body')?.hasError('required')">* Campo obbligatorio
      </mat-error>
      <mat-error *ngIf="form.get('Body')?.hasError('maxlength')">* Campo non valido. Hai a disposizione 512
        caratteri.
      </mat-error>
    </mat-form-field>

    <div fxLayout='row wrap'>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        style='padding: 10px'>

        <mat-form-field (click)='fileInput.click()'>
          <mat-label>Allegato</mat-label>
          <input matInput
                 readonly
                 formControlName='GlobalNotificationFileUploadName'>
          <input hidden #fileInput type='file' [disabled]='form.get("GlobalNotificationFileUpload")?.disabled'
                 (change)='onFileSelected($event)'>
          <mat-icon matSuffix style='cursor: pointer'>attachment</mat-icon>
        </mat-form-field>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        style='padding: 10px'>

        <mat-form-field (click)='picker.open()'>

          <mat-label>Data di invio</mat-label>
          <input matInput
                 [matDatepicker]='picker'
                 formControlName='EnabledDate'
                 readonly>

          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>

          <mat-error *ngIf="form.get('EnabledDate')?.hasError('required')">* Campo obbligatorio
          </mat-error>

        </mat-form-field>

      </div>

    </div>

    <mat-divider></mat-divider>

    <h3 class='m-t-10' mat-dialog-title>Destinatari</h3>

    <app-notification-companies-select-control
      [control]="form.get('Companies')"></app-notification-companies-select-control>

    <app-notification-event-select-control
      [configuration]='notificationEventSelectControlConfiguration'
      [control]="form.get('EventProducts')"></app-notification-event-select-control>

    <p>
      <mat-checkbox formControlName='TechnicalOnly'> Solo tecnici</mat-checkbox>
    </p>

  </form>

</div>
<div mat-dialog-actions style='display: flex; justify-content: space-between'>
  <button mat-button mat-dialog-close cdkFocusInitial>Annulla</button>
  <button mat-raised-button color='primary' (click)='send()'>Invia</button>
</div>
