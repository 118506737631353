import { Component, Input } from '@angular/core';
import { DocumentBoxUploaderConfiguration } from '../document-box-uploader.configuration';
import { FileSideEnum } from '../../../../core/enums/file-side.enum';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-document-single-box-uploader',
  templateUrl: './document-single-box-uploader.component.html',
  styleUrls: ['./document-single-box-uploader.component.scss'],
})
export class DocumentSingleBoxUploaderComponent {

  @Input() configuration!: DocumentBoxUploaderConfiguration;
  @Input() side: FileSideEnum = FileSideEnum.REAR;

  DOCUMENT_SIDE = FileSideEnum;

  constructor() {
  }

  get needsDataBeforeFileUpload() {

    return (this.isDateControlRequired() && this.configuration.dateControl.invalid) || (this.isDocumentCodeControlRequired() && this.configuration.documentCodeControl.invalid);

  };

  get showDocumentsLable() {

    return this.isDocumentRetroControlRequired() && !this.needsDataBeforeFileUpload;

  };

  get _background(): string | null {

    return this._control.value ?? null;

  }

  get _control(): FormControl {

    switch (this.side) {

      case FileSideEnum.REAR:
        return this.configuration.documentRetroControl;

      case FileSideEnum.FRONT:
      default:
        return this.configuration.documentControl;

    }

  }

  onChange = (event: any) => this.configuration.changeAction(event, this.side, this.configuration.dateControl.value);

  isDateControlRequired = (): boolean => this.configuration.dateControl.hasValidator(Validators.required);

  isDocumentRetroControlRequired = (): boolean => this.configuration.documentRetroControl.hasValidator(Validators.required);

  isDocumentCodeControlRequired = (): boolean => this.configuration.documentCodeControl.hasValidator(Validators.required);

}
