import { SPNNotification } from 'src/app/core/controllers/global-notification.controller';

export enum NotificationsViewTemplate {

  default,
  post_it

}

export class NotificationsWidgetConfiguration {

  get viewTemplate(): NotificationsViewTemplate | null {
    return this._viewTemplate;
  }

  private set viewTemplate(value: NotificationsViewTemplate | null) {
    this._viewTemplate = value;
  }

  get technicalSentView(): boolean | null {
    return this._technicalSentView;
  }

  private set technicalSentView(value: boolean | null) {
    this._technicalSentView = value;
  }


  get list(): SPNNotification[] {
    return this._list;
  }

  private set list(value: SPNNotification[]) {
    this._list = value;
  }

  private _technicalSentView: boolean | null = null;
  private _list: SPNNotification[] = [];
  private _viewTemplate: NotificationsViewTemplate | null = NotificationsViewTemplate.default;

  private constructor() {
  }

  public static create = (): NotificationsWidgetConfiguration => new NotificationsWidgetConfiguration();

  setList(value: SPNNotification[] = []): NotificationsWidgetConfiguration {

    this.list = value;
    return this;

  }

  setTechnicalSentView(value: boolean | null): NotificationsWidgetConfiguration {

    this.technicalSentView = value;
    return this;

  }

  setViewTemplate(value: NotificationsViewTemplate | null): NotificationsWidgetConfiguration {

    this.viewTemplate = value;
    return this;

  }

}
