import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EventController, EventProductLightListParameter } from '../../../../../core/controllers/event.controller';
import { SPNLightEventProduct } from '../../../../../core/classes/SPNEvent';
import { NotificationEventSelectControlConfiguration } from './notification-event-select-control.configuration';

@Component({
  selector: 'app-notification-event-select-control',
  templateUrl: './notification-event-select-control.component.html',
  styleUrls: ['./notification-event-select-control.component.scss'],
})
export class NotificationEventSelectControlComponent {

  @Input() control: FormControl | null = null;
  @Input() configuration: NotificationEventSelectControlConfiguration = NotificationEventSelectControlConfiguration
    .create();

  values: SPNLightEventProduct[] = [];

  constructor(private eventController: EventController) {
  }

  ngAfterViewInit() {

    this.getList();

  }

  getList() {

    const parameter: EventProductLightListParameter = new EventProductLightListParameter(this.configuration.timeLimitOnly);

    this.eventController
      .getEventProductLightList(parameter)
      .then((result: SPNLightEventProduct[]) => {

        this.values = result
          .sort((a, b) =>  new Date(b.StartDate ?? 0).getTime() - new Date(a.StartDate ?? 0).getTime())

      });

  }

}
