<div class='upcoming-container'>

  <h2 class='title-min-size'> Calendario gare ed eventi </h2>

  <div align='end'>

    <button mat-raised-button color='primary' (click)='calendarService.downloadCalendar()'>Scarica calendario
      <mat-icon>event</mat-icon>
    </button>

  </div>

  <mat-tab-group mat-stretch-tabs='false' mat-align-tabs='center'>
    <mat-tab label='Gare'>
      <app-event-list [caller]='getCompetitions'></app-event-list>
    </mat-tab>
    <mat-tab label='Eventi'>
      <app-event-list [caller]='getEvents'></app-event-list>
    </mat-tab>
  </mat-tab-group>

</div>
