import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartListComponent } from './cart-list/cart-list.component';
import { RouterModule } from '@angular/router';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import {
  PurchaseItemModule
} from '../personal-area/purchases/purchases-list/user-purchases/purchase-item/purchase-item.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    CartListComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    PurchaseItemModule,
    MatButtonModule,
    MatIconModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: '',
            component: CartListComponent,
            data: {
              title: 'Carrello',
              urls: [{ title: 'Carrello', url: '/cart' }, { title: 'Carrello' }],
            },
          }],
      },
    ]),
  ],
})
export class CartModule {
}
