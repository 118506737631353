import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { UserCartProductDTO } from '../../../../../../core/interfaces/user-cart-product';

@Component({
  selector: 'app-purchase-item',
  templateUrl: './purchase-item.component.html',
  styleUrls: ['./purchase-item.component.scss'],
})
export class PurchaseItemComponent {

  @Input() item!: UserCartProductDTO;

  viewModel = {

    multipleItems: false

  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.viewModel.multipleItems = this.item.Quantity > 1;
    this.cdr.detectChanges();

  }

}
