import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CartService {

  counter: number | null = null;

  constructor() {
  }

  setCount = (count: number) => this.counter = count && count > 0 ? count :  null;

}
