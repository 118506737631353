<mat-accordion class='record-filters-cntr'>

  <mat-expansion-panel [expanded]='expandedFilterPanel'>

    <mat-expansion-panel-header>
      <mat-panel-title>
        Filtra la tua ricerca
      </mat-panel-title>
    </mat-expansion-panel-header>

    <form
      [formGroup]='form'
      fxLayout='row wrap'>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-gender-filter [control]="form.get('Gender')"></app-spn-gender-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-pool-length-filter [control]="form.get('PoolLength')"></app-spn-pool-length-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-specialty-filter [control]="form.get('SwimStyleOid')"></app-spn-specialty-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-distance-filter [control]="form.get('Distance')"></app-spn-distance-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-record-type-filter [control]="form.get('Type')"></app-spn-record-type-filter>


      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-record-category-filter [control]="form.get('Category')"></app-spn-record-category-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <mat-checkbox formControlName='PersonalOnly' class='m-b-10'> I miei record </mat-checkbox>

      </div>

    </form>

  </mat-expansion-panel>

</mat-accordion>

