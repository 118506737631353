import { Injectable } from '@angular/core';
import { CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { CtFileManagementService } from '@ctsolution/ct-file-management';

@Injectable({
  providedIn: 'root',
})
export class FileService {

  constructor(
    private _webapi: CtWebapiService,
    private _file: CtFileManagementService) {
  }

  setFormData(objToSend: any): FormData {

    const formData: FormData = new FormData();

    // ciclo tutte le chiavi dell'oggetto (documento) da inviare
    // se una delle chiavi è SPNType ciclo anch'essa in quanto oggetto
    Object.keys(objToSend)
      .forEach(key => {

        if (key === 'SPNType') {

          for (let previewKey in objToSend[key]) {
            formData.append(`SPNType[${previewKey}]`, objToSend[key][previewKey]);
          }

        } else {

          formData.append(key, objToSend[key]);

        }

      });

    const value: any = formData.get('File');
    formData.set('File', value, objToSend.File.name);

    return formData;

  }

  getPDFFile(request: DataRequest, fileName: string) {

    request.setResponseType('blob');

    this._webapi.get(request)
      .subscribe((result: any) => this._file.downloadPdfFromApiResult(result, fileName));

  }

}
