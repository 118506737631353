<div class='notifications-widget-cntr'>

  <ng-container *ngIf='(configuration?.list ?? []).length; else EMPTY_CONTAINER'>

    <ng-container [ngSwitch]='configuration.viewTemplate'>

      <ng-container *ngSwitchCase='NotificationsViewTemplate.post_it'>

        <app-notification-post-it
          [configuration]='configuration'
          (onDownload)='download($event)'></app-notification-post-it>

      </ng-container>

      <ng-container *ngSwitchDefault>

        <mat-card>

          <mat-list>

            <ng-container *ngFor='let notification of (configuration?.list ?? [])'>

              <app-notification-list-item
                [notification]='notification'
                [technicalSentView]='configuration.technicalSentView'
                (onEdit)='edit($event)'
                (onDelete)='delete($event)'
                (onDownload)='download($event)'></app-notification-list-item>

              <hr>

            </ng-container>

          </mat-list>

        </mat-card>

      </ng-container>

    </ng-container>

  </ng-container>

  <ng-template #EMPTY_CONTAINER>

    <p> Nessuna notifica</p>

  </ng-template>

</div>
