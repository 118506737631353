import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CtDatatableConfiguration, CTDatatablePaginationParameter, CtDatatableResult } from '@ctsolution/ct-framework';
import { EventListFilterDTO } from '../../../core/controllers/event.controller';
import { EnumHelper } from '../../../core/lib/enum.helper';
import { QualificationsController } from '../../../core/controllers/qualifications.controller';
import { TableColumn } from '@swimlane/ngx-datatable';
import { AthleteCategoryEnum } from '../../../core/enums/athlete-category.enum';
import { AthleteTimeResult } from '../../../core/interfaces/athlete-time-result';
import { QualificationsFiltersComponent } from './qualifications-filters/qualifications-filters.component';

@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
})
export class QualificationsComponent {

  @ViewChild('filters') qualificationsFilters!: QualificationsFiltersComponent;
  @ViewChild('descriptionTemplate') public descriptionCellTemplate!: TemplateRef<any>;
  @ViewChild('rankingTemplate') public rankingCellTemplate!: TemplateRef<any>;

  qualifications: CtDatatableConfiguration | undefined;

  private params: EventListFilterDTO = new EventListFilterDTO();

  constructor(private qualificationsController: QualificationsController, private enumHelper: EnumHelper) {
  }

  ngAfterViewInit(): void {

    const columns: TableColumn[] = [
      {
        prop: 'TimeDate',
        name: 'TimeDate',
        cellTemplate: this.rankingCellTemplate,
      },
      {
        prop: 'TimeLimit',
        name: '',
        cellTemplate: this.descriptionCellTemplate,
        sortable: false,
        width: 100,
      },
    ];

    this.qualifications = CtDatatableConfiguration
      .create()
      .setExternalPaging(true)
      .setHeaderHeight(0)
      .setColumns(columns);

  }

  getCategoryLabel = (value: string) => this.enumHelper.getEnumLabel(AthleteCategoryEnum, value);

  fetch(pagination?: CTDatatablePaginationParameter): void {

    this.params = this.qualificationsFilters.setFilters(this.params);

    if (!this.params.EventProductOid) {

      this.qualifications?.setLoadingIndicator(false);
      return;

    }

    this.qualifications?.setLoadingIndicator(true);

    this.qualificationsController
      .getList(this.params, pagination)
      .then((result: CtDatatableResult<AthleteTimeResult>) => {

        this.qualifications
          ?.setCount(result.ItemTotal)
          .setRows(result.Items)
          .setLoadingIndicator(false);

      });

  }

}
