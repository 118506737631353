import { ProductCategoryMacroTypeEnum } from '../../core/enums/product-category-macro-type.enum';

export class ProductListConfiguration {

  public ProductOIDs?: number[];

  constructor(public ProductCategoryMacroType: ProductCategoryMacroTypeEnum) {
  }

  public static create = (productCategoryMacroType: ProductCategoryMacroTypeEnum): ProductListConfiguration => new ProductListConfiguration(productCategoryMacroType);

}
