import { Injectable } from '@angular/core';

const SPN_SEASON_END_DATE: Date = new Date();
SPN_SEASON_END_DATE.setMonth(8, 30); // l'indice dei mesi parte da 0

@Injectable({
  providedIn: 'root',
})
export class SeasonService {

  constructor() {
  }

}

export class CurrentSeason {

  startYear: number | null = null;
  endYear: number | null = null;

  constructor() {

    const currentDate: Date = new Date();
    const currentYear: number = currentDate.getFullYear();

    this.startYear = currentDate.getTime() > SPN_SEASON_END_DATE.getTime() ? currentYear : currentYear - 1;
    this.endYear = this.startYear + 1;

  }

}
