import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { CartListFilter } from '../classes/cart-list-filter';
import { CartListFilterStateEnum } from '../enums/cart-list-filter-state.enum';
import { UserCart } from '../interfaces/user-cart';
import { CartPaymentDTO } from '../interfaces/cart-payment';
import { CartService } from '../lib/cart.service';

@Injectable()
export class CartController {

  controller: string = '/Cart/';

  constructor(private _webapi: CtWebapiService, private cartService: CartService) {
  }

  getActiveCart = (): Promise<UserCart | null> => new Promise<UserCart | null>((resolve) => {

    const parameter: CartListFilter = new CartListFilter(CartListFilterStateEnum.Active);

    this.getList(parameter)
      .then((carts: UserCart[]) => {

        resolve(carts[0]);

      });

  });

  getPaidCarts(): Promise<UserCart[]> {

    const parameter: CartListFilter = new CartListFilter(CartListFilterStateEnum.Paid);
    return this.getList(parameter);

  }

  deleteActive(): Promise<CtWebapiGenericResponse<any>> {

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}DeleteActive`);

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<any>) => {

          this.activeCartProductsCount()
            .then(() => resolve(r));

        });

    });

  }

  removeProductFromActive(productOid: number): Promise<CtWebapiGenericResponse<any>> {

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}RemoveProductFromActive`, { productOid });

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<any>) => {

          this.activeCartProductsCount()
            .then(() => resolve(r));

        });

    });

  }

  activeCartProductsCount(): Promise<CtWebapiGenericResponse<any>> {

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}ActiveCartProductsCount`);

      this._webapi
        .post(request, {})
        .subscribe((r: CtWebapiGenericResponse<any>) => {

          this.cartService.setCount(r.Result);
          resolve(r);

        });

    });

  }

  private getList(parameter: CartListFilter): Promise<UserCart[]> {

    return new Promise<UserCart[]>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}GetList`, parameter);

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<any>) => resolve(<UserCart[]>r.Result));

    });

  }

  getReceipt(cartPayment: CartPaymentDTO) {

    const request: DataRequest = new DataRequest(`${this.controller}GetReceipt`, { cartPaymentOid: cartPayment.Oid });

    request.setResponseType('blob');

    this._webapi
      .get(request)
      .subscribe((result: any) => {

        const blob = new Blob([result], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);

        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = cartPayment.TransactionCode;
        fileLink.click();

      });

  }

}
