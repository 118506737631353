import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCardActionsComponent } from './event-card-actions/event-card-actions.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { EventCancelConfirmComponent } from './event-cancel-confirm/event-cancel-confirm.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    EventCardActionsComponent,
    EventCancelConfirmComponent,
  ],
  exports: [
    EventCardActionsComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FlexModule,
    FlexLayoutModule,
  ],
})
export class EventCardActionsModule {
}
