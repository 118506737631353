import { Component, Input } from '@angular/core';
import { AthleteCardConfiguration } from './athlete-card.configuration';
import { SPNEventProduct } from '../../../../core/classes/SPNEvent';
import {
  HistoricalEventDetailComponent,
  HistoricalEventDetailConfiguration,
} from '../../../competitions-and-events/historical/historical-event-detail/historical-event-detail.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'app-athlete-card',
  templateUrl: './athlete-card.component.html',
  styleUrls: ['./athlete-card.component.scss'],
})
export class AthleteCardComponent {

  @Input() configuration: AthleteCardConfiguration | null = null;

  constructor(private bottomSheet: MatBottomSheet, private router: Router) {
  }

  eventDetail(eventProduct: SPNEventProduct): void {

    const SPNEvent: SPNEventProduct = SPNEventProduct.create(eventProduct);

    const configuration: HistoricalEventDetailConfiguration = HistoricalEventDetailConfiguration
      .create(SPNEvent)
      .setUserInfoOid(this.configuration?.info?.Oid ?? 0);

    configuration
      .filters
      .setBypassTrend(true);

    this.bottomSheet
      .open(HistoricalEventDetailComponent, {
        data: configuration,
        panelClass: 'bottom-sheet-event-detail-cntr',
      }).afterDismissed();

  }

  limitTimes(eventProduct: SPNEventProduct) {

    this.router
      .navigate(['competitions-and-events', 'limit-times'], {
        queryParams: {
          QualifiedRaceOid: eventProduct.Oid,
          QualifiedRaceName: eventProduct.Name,
          UserInfoOid: this.configuration?.info?.Oid,
          UserInfoFullName: this.configuration?.info?.FullName,
        },
      });

  }

}
