<mat-list-item *ngIf='notification' class='notification-list-item-cntr'>

  <div mat-line fxLayout='row wrap' fxLayoutAlign='space-between center'>

    <div>

      <b> {{ notification.Title }}</b>

    </div>

    <div>

      <i>
        <small [hidden]='!notificationSender.sender'>
          <app-notification-sender #notificationSender [notification]='notification'></app-notification-sender>,
        </small>
        <small
          *ngIf='notification.EnabledDate && !notification.ForExpiringDocument'>
          {{ notification.EnabledDate  | date:'dd/MM/yy' }}
        </small>
      </i>

    </div>

  </div>

  <div class='notification-body' mat-line *ngIf='notification.Body'>

    <p
      [innerHtml]='notification.Body'></p>

  </div>

  <div mat-line class='notification-actions' fxLayout='row wrap' fxLayoutAlign='space-between center'>

    <div>

      <ng-container *ngIf='technicalSentView && notification.CompaniesBusinessName'>

        <div>

          Gruppi di allenamento: {{notification.CompaniesBusinessName}}

        </div>

      </ng-container>

    </div>

    <div class='actions'>

      <div *ngIf='technicalSentView'>

        <ng-container *ngIf='notification.editEnabled; else readConfirmTemplate'>

          <button
            mat-button
            (click)='onEdit.emit(notification)'
            color='accent'>
            Modifica
            <mat-icon
              matListItemIcon>edit
            </mat-icon>
          </button>

          <button mat-icon-button color='warn' (click)='onDelete.emit(notification)'>
            <mat-icon>delete</mat-icon>
          </button>

        </ng-container>

        <ng-template #readConfirmTemplate>

          <mat-chip-list *ngIf='notification.GlobalNotificationTrackingsTotal'>

            <mat-chip selected [color]="missingReadCount() ? 'accent' : 'primary'">
              <mat-icon matChipAvatar>done_all</mat-icon>
              {{ getBadgeValue()}}</mat-chip>
          </mat-chip-list>

        </ng-template>

      </div>

      <button
        *ngIf='notification.DownloadUrl'
        mat-button
        (click)='onDownload.emit(notification)'
        color='primary'>
        Download allegati
        <mat-icon
          matListItemIcon>download
        </mat-icon>
      </button>

    </div>

  </div>

</mat-list-item>
