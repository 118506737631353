<div class="login-register">

    <div class="login-register-box">

        <mat-card>

            <mat-card-content>

                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="text-center">
                        <img class="logo-icon-format" alt="superbanuoto" src="assets/images/logo_superbanuoto_icon.svg" />
                        <h4 class="m-t-0">Login</h4>
                    </div>

                    <div fxLayout="row wrap">

                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field>
                                <input matInput placeholder="Email" [formControl]="form.controls['Email']" />
                            </mat-form-field>
                            <small *ngIf="form.controls['Email'].hasError('required') && form.controls['Email'].touched" class="text-danger support-text">Campo obbligatorio.</small>
                        </div>

                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field>
                                <input matInput type="password" placeholder="Password" [formControl]="form.controls['Password']" [type]="hidePassword ? 'password' : 'text'" />
                                <mat-icon matSuffix  (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </mat-form-field>
                            <small *ngIf="form.controls['Password'].hasError('required') && form.controls['Password'].touched" class="text-danger support-text">Campo obbligatorio.</small>
                        </div>

                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.xs="100">
                            <mat-checkbox color="warn" formControlName="RememberMe">Ricordami</mat-checkbox>
                        </div>

                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.xs="100" class="text-right m-t-25">
                            <a [routerLink]="['/authentication/forgot']" class="link">Password dimenticata?</a>
                        </div>

                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <button mat-raised-button  class="btn-block btn-lg m-t-20 m-b-20 backgroundColor" type="submit" [disabled]="!form.valid">
                                Login
                            </button>
                        </div>

                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="text-center">
                            <span>Non hai ancora un account?
                                <a [routerLink]="['/authentication/registration']" class="secondaryColor link">Registrati</a>
                            </span>
                        </div>

                    </div>

                </form>

            </mat-card-content>

        </mat-card>

    </div>

</div>
