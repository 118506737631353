import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CtDatatableConfiguration, CTDatatablePaginationParameter } from '@ctsolution/ct-framework';
import { TableColumn } from '@swimlane/ngx-datatable';
import { UserController } from '../../../core/controllers/user.controller';
import { AthleteBestTimes } from '../../../core/classes/athlete-best-times';
import { AthleteTimeResult } from '../../../core/interfaces/athlete-time-result';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HistoricalEventDetailComponent,
  HistoricalEventDetailConfiguration,
} from '../../competitions-and-events/historical/historical-event-detail/historical-event-detail.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SPNEventProduct } from '../../../core/classes/SPNEvent';
import { SPNEventRaceResult } from '../../../core/classes/SPNEventRaceResult';
import { PoolLengthDTO } from '../../../core/controllers/event.controller';
import { POOL_LENGTHS } from '../../../_components/spn-filters/spn-pool-length-filter/spn-pool-length-filter.component';
import { GeneralService } from '../../../core/lib/general.service';
import { TechnicalHelperService } from '../../../core/lib/technical.helper.service';

@Component({
  selector: 'app-athlete-best-times',
  templateUrl: './athlete-best-times.component.html',
  styleUrls: ['./athlete-best-times.component.scss'],
  providers: [UserController],
})
export class AthleteBestTimesComponent {

  @ViewChild('timeTemplate') public timeCellTemplate!: TemplateRef<any>;
  @ViewChild('poolLengthTemplate') public poolLengthTemplate!: TemplateRef<any>;

  filter: AthleteBestTimes = AthleteBestTimes.create();
  bestTimes: CtDatatableConfiguration | undefined;

  viewModel = {

    athleteName: null,

  };

  constructor(
    private userController: UserController,
    private route: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private technicalService: TechnicalHelperService,
    public general: GeneralService) {
  }

  ngAfterViewInit(): void {

    this.route
      .queryParams
      .subscribe(qp => {

        if (qp.Oid) {

          this.filter
            .setUserInfoOid(Number(qp.Oid));

        }

        if (qp.Name) {

          this.viewModel.athleteName = qp.Name;

        }

        this.setupTable();

      });

  }

  setupTable() {

    const columns: TableColumn[] = [
      {
        prop: 'RaceSpecialtySwimStyleName',
        name: 'Stile',
        width: 100,
      },
      {
        prop: 'RaceSpecialtyDistance',
        name: 'Distanza',
        width: 80,
      },
      {
        prop: 'PoolLength',
        name: 'Vasca',
        width: 80,
        cellTemplate: this.poolLengthTemplate,
      },
      {
        prop: 'TimeForGrid',
        name: 'Tempo',
        cellTemplate: this.timeCellTemplate,
      },
    ];

    this.bestTimes = CtDatatableConfiguration
      .create()
      .setExternalPaging(true)
      .setColumns(columns)
      .setDetailActionEnabled(true);

  }

  back() {

    this.technicalService.backToAthleteList(this.filter?.UserInfoOid);

  }

  poolLengthFormat(value: number | null) {

    const poolLength: PoolLengthDTO | undefined = POOL_LENGTHS.find((elm: PoolLengthDTO) => elm.Oid === value);

    return poolLength ? poolLength.Value : POOL_LENGTHS[0].Value;

  }

  fetch(pagination?: CTDatatablePaginationParameter): void {

    this.bestTimes?.setLoadingIndicator(true);

    this.userController
      .athleteBestTimes(this.filter, pagination)
      .subscribe({
        next: (response) => {

          this.bestTimes
            ?.setCount(response.Result.ItemTotal)
            .setRows(response.Result.Items)
            .setLoadingIndicator(false);

        },
      });

  }

  detail(value: AthleteTimeResult | SPNEventRaceResult): void {

    const SPNEvent: SPNEventProduct = SPNEventProduct
      .create()
      .setupFromAthleteTimeResult(<AthleteTimeResult>value);

    SPNEvent
      .EventProduct
      ?.setRaceResultListFromAthleteResult(value);

    const configuration: HistoricalEventDetailConfiguration = HistoricalEventDetailConfiguration
      .create(SPNEvent)
      .setBypassFetch(true);

    configuration
      .filters
      .setBypassTrend(true);

    this.bottomSheet
      .open(HistoricalEventDetailComponent, {
        data: configuration,
        panelClass: 'bottom-sheet-event-detail-cntr',
      }).afterDismissed();

  }

}
