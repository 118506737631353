import { Component, Input, OnInit } from '@angular/core';
import { UserCart } from '../../../../../../core/interfaces/user-cart';

@Component({
  selector: 'app-purchase-item-subheader',
  templateUrl: './purchase-item-subheader.component.html',
  styleUrls: ['./purchase-item-subheader.component.scss'],
})
export class PurchaseItemSubheaderComponent {

  @Input() item!: UserCart;

  constructor() {
  }

}
