import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileController } from '../../../core/controllers/profile.controller';
import { CustomValidators } from 'ngx-custom-validators';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { UserService } from '../../../core/lib/user.service';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { GeneralService } from '../../../core/lib/general.service';
import { ValidatorService } from '../../../core/lib/validator.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { IT_CELLPHONE_PATTERN } from '../../../shared/validation.patterns';

@Component({
  selector: 'app-familiar',
  templateUrl: './familiar.component.html',
  styleUrls: ['./familiar.component.scss'],
})
export class FamiliarComponent implements OnInit {

  public form: FormGroup = Object.create(null);

  skipEnabled = false;
  comesFromRegistration = false;

  constructor(
    private formBuilder: FormBuilder,
    private profileController: ProfileController,
    private ctAuthenticationService: CtAuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private general: GeneralService,
    private validatorService: ValidatorService,
    private matSnackbar: MatSnackBar,
  ) {

    // @ts-ignore
    this.form = this.formBuilder.group({
      // @ts-ignore
      ParentEmail: [null, Validators.compose([Validators.required, Validators.maxLength(250), CustomValidators.email])],
      ParentPhone: [null, Validators.compose([Validators.required, Validators.pattern(IT_CELLPHONE_PATTERN)])],
      ParentName: [null, Validators.compose([Validators.required])],
      ParentSurname: [null, Validators.compose([Validators.required])],
      ParentFiscalCode: [null, Validators.compose([Validators.required, this.validatorService.fiscalCode])],
    });


    this.route
      .queryParams
      .subscribe(qp => {

        this.comesFromRegistration = qp.registration;

      });

  }

  ngOnInit(): void {

    this.setup()
      .then(() => this.getUserData());

  }

  async setup() {

    this.skipEnabled = await this.userService.adultUser();

  }

  saveParentData(): void {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      this.profileController
        .setFamilyData(this.form.value)
        .then(() => {

          this.ctAuthenticationService
            .getClaims()
            .then(() => {

              let path: string[] = ['/'];
              const extras: NavigationExtras = {};

              if (this.comesFromRegistration) {

                path = ['personal-area', 'documents'];
                extras.queryParamsHandling = 'merge';

              }

              this.matSnackbar.open('Dati genitore salvati correttamente!', 'X', {
                duration: 3000,
                panelClass: 'general-snackbar-panel',
              });

              this.general.navigateTo(path, extras);

            });

        });

    }

  }

  getUserData(): void {

    this.profileController
      .getUserData()
      .then(data => {

        this.form.get('ParentEmail')?.setValue(data?.Result?.ParentEmail ?? null);
        this.form.get('ParentPhone')?.setValue(data?.Result?.ParentPhone ?? null);
        this.form.get('ParentName')?.setValue(data?.Result?.ParentName ?? null);
        this.form.get('ParentSurname')?.setValue(data?.Result?.ParentSurname ?? null);
        this.form.get('ParentFiscalCode')?.setValue(data?.Result?.ParentFiscalCode ?? null);

      });

  }

  skip(): void {

    const extras: NavigationExtras = {};

    if (this.comesFromRegistration) {

      extras.queryParamsHandling = 'merge';

    }

    this.general.navigateTo(['personal-area', 'documents'], extras);

  }

}
