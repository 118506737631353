import { Component } from '@angular/core';
import { GeneralService } from 'src/app/core/lib/general.service';
import { UserService } from 'src/app/core/lib/user.service';
import { SubscriptionStateEnum } from '../../core/enums/subscription-state.enum';
import { CartPaymentDTO } from '../../core/interfaces/cart-payment';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  NotAvailableInstallmentDialogComponent,
} from './not-available-installment-dialog/not-available-installment-dialog.component';
import { GlobalNotificationController, SPNNotification } from '../../core/controllers/global-notification.controller';
import {
  NotificationsViewTemplate,
  NotificationsWidgetConfiguration,
} from 'src/app/_components/notifications-widget/notifications-widget.configuration';
import { RoleService } from '../../core/lib/role.service';
import { CalendarService } from '../../core/lib/calendar.service';
import { CurrentSeason } from '../../core/lib/season.service';
import { BannerService } from 'src/app/shared/banner/banner-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [GlobalNotificationController],
})
export class HomeComponent {

  subscriptionState: SubscriptionStateEnum | null = null;

  SubscriptionStates = SubscriptionStateEnum;
  notificationsWidgetConfiguration: NotificationsWidgetConfiguration = NotificationsWidgetConfiguration
    .create()
    .setViewTemplate(NotificationsViewTemplate.post_it);

  viewModel: any = {

    showSubscriptionRedirectTemplate: false,
    missingSubscription: false, // used for invalid payments
    needsParentalSupervision: false,
    needsDocuments: false,
    pendingPayments: [],
    isTechnical: false,
    currentSeason: new CurrentSeason(),

  };

  constructor(
    private generalService: GeneralService,
    public userService: UserService,
    private dialog: MatDialog,
    private notificationsController: GlobalNotificationController,
    private roleService: RoleService,
    public calendarService: CalendarService,
    private bannerService : BannerService
  ) {

  }

  ngOnInit() {

    this.notificationsController
      .getGeneralList(3)
      .then((result: SPNNotification[]) => this.notificationsWidgetConfiguration.setList(result));

    this.setup();

    this.bannerService.open();

  }

  async setup() {

    this.subscriptionState = await this.userService.subscriptionState();

    this.viewModel.missingSubscription = this.subscriptionState === SubscriptionStateEnum.MISSING;

    const subscriptionExpired = await this.userService.subscriptionExpired();

    this.viewModel.showSubscriptionRedirectTemplate = subscriptionExpired || !this.subscriptionState || this.viewModel.missingSubscription;

    this.viewModel.pendingPayments = await this.userService.missingInstallments();

    this.viewModel.needsParentalSupervision = await this.userService.needsParentalSupervision();

    this.viewModel.needsDocuments = await this.userService.userNeedsDocuments();

    this.viewModel.isTechnical = await this.roleService.isTechnical();

  }

  goToDocumentsUpload(): void {

    this.generalService.navigateTo(['personal-area', 'documents']);

  }

  goToParentData(): void {

    this.generalService.navigateTo(['personal-area', 'familiar']);

  }

  goToSubscription(): void {

    this.generalService.navigateTo(['/subscriptions']);

  }

  redirectToEventsWithTL() {

    this.generalService.navigateTo(['/', 'competitions-and-events', 'upcoming'], { queryParams: { MustHaveTimeLimit: true } });

  }

  async payInstallment(payment: CartPaymentDTO) {

    const nextPayment: CartPaymentDTO | null = await this.userService.userNextPayment();

    if (nextPayment) {

      const userPendingPayment = await this.userService.userPendingPayment();

      if (!!userPendingPayment || payment.InstallmentIndex! > nextPayment.InstallmentIndex!) {

        this.dialog.open(NotAvailableInstallmentDialogComponent);

      } else {

        this.goToSubscription();

      }

    }

  }

}
