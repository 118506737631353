import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpnDaterangeFilterComponent } from './spn-daterange-filter/spn-daterange-filter.component';
import { SpnSpecialtyFilterComponent } from './spn-specialty-filter/spn-specialty-filter.component';
import { SpnDistanceFilterComponent } from './spn-distance-filter/spn-distance-filter.component';
import { SpnPoolLengthFilterComponent } from './spn-pool-length-filter/spn-pool-length-filter.component';
import { SpnRecordCategoryFilterComponent } from './spn-record-category-filter/spn-record-category-filter.component';
import { SpnRecordTypeFilterComponent } from './spn-record-type-filter/spn-record-type-filter.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SpnGenderFilterComponent } from './spn-gender-filter/spn-gender-filter.component';
import {
  SpnCompanyBusinessNameFilterComponent,
} from './spn-company-business-name-filter/spn-company-business-name-filter.component';
import { SpnNameSurnameFilterComponent } from './spn-name-surname-filter/spn-name-surname-filter.component';
import { SpnAthleteCategoryFilterComponent } from './spn-athlete-category-filter/spn-athlete-category-filter.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    SpnDaterangeFilterComponent,
    SpnSpecialtyFilterComponent,
    SpnDistanceFilterComponent,
    SpnPoolLengthFilterComponent,
    SpnRecordCategoryFilterComponent,
    SpnRecordTypeFilterComponent,
    SpnGenderFilterComponent,
    SpnCompanyBusinessNameFilterComponent,
    SpnNameSurnameFilterComponent,
    SpnAthleteCategoryFilterComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    FlexModule,
  ],
  exports: [
    SpnDaterangeFilterComponent,
    SpnSpecialtyFilterComponent,
    SpnDistanceFilterComponent,
    SpnRecordCategoryFilterComponent,
    SpnRecordTypeFilterComponent,
    SpnGenderFilterComponent,
    SpnPoolLengthFilterComponent,
    SpnCompanyBusinessNameFilterComponent,
    SpnNameSurnameFilterComponent,
    SpnAthleteCategoryFilterComponent,
  ]
})
export class SpnFiltersModule {
}
