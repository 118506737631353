import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AthleteBestTimesComponent } from './athlete-best-times.component';
import { CtDatatableModule } from '@ctsolution/ct-framework';
import {
  SplittedTimeFormatModule
} from '../../competitions-and-events/_components/event-card/event-card/event-results-list/splitted-time-format/splitted-time-format.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    AthleteBestTimesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule
      .forChild([
        {
          path: '',
          component: AthleteBestTimesComponent,
          data: {
            title: 'Tempi migliori',
            urls: [{
              title: 'Tempi migliori',
              url: '/athletes',
            }, { title: 'Tempi migliori' }],
          },
        },
      ]),
    CtDatatableModule,
    SplittedTimeFormatModule,
    MatIconModule,
    MatLegacyButtonModule,
    FlexModule,
  ],
})
export class AthleteBestTimesModule {
}
