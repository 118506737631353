import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { PurchaseDTO } from '../classes/purchase';
import { ProductListFilter } from '../classes/product-list-filter';
import { SPNProductQuoteDTO } from '../interfaces/spn-product-quote';
import { AddToCartDTO } from '../classes/add-to-cart';
import { CartCheckoutParameter } from '../classes/cart-checkout-parameter';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { GeneralService } from '../lib/general.service';

@Injectable()
export class ProductController {

  controller = '/Product/';

  constructor(private _webapi: CtWebapiService, private snackbar: MatSnackBar, private _general: GeneralService) {
  }

  getAvailableList = (parameter: ProductListFilter): Promise<SPNProductQuoteDTO[]> => new Promise<SPNProductQuoteDTO[]>((resolve) => {

    const request: DataRequest = new DataRequest(`${this.controller}GetAvailableList`, parameter);

    this._webapi
      .get(request)
      .subscribe((r: CtWebapiGenericResponse<any>) => resolve(r.Result as SPNProductQuoteDTO[]));

  })

  directPurchase = (purchaseDto: PurchaseDTO): Promise<any> => new Promise<any>((resolve) => {

    this._webapi
      .post(new DataRequest(`${this.controller}DirectPurchase`), purchaseDto)
      .subscribe((r: any) => resolve(r as any));

  })

  cartCheckOut = (parameter: CartCheckoutParameter): Promise<any> => new Promise<any>((resolve) => {

    this._webapi
      .post(new DataRequest(`${this.controller}CartCheckOut`), parameter)
      .subscribe((r: any) => resolve(r as any));

  })

  addToCart(cart: AddToCartDTO[]): Promise<any> {

    return new Promise((resolve) => {

      this._webapi
        .post(new DataRequest(`${this.controller}AddToCart`), cart)
        .subscribe((r: any) => {

          this.snackbar.open('Elemento aggiunto correttamente!', 'Vai al carrello', {
            duration: 3000,
            panelClass: 'addToCartSnackbar',
          })
            .onAction()
            .subscribe(() => this._general.navigateTo(['cart']));

          resolve(r);

        });

    });

  }

}
