import { Component, EventEmitter, Output } from '@angular/core';
import { SubscriptionStateEnum } from '../../../../core/enums/subscription-state.enum';
import { UserService } from '../../../../core/lib/user.service';
import {
  NotAvailableInstallmentDialogComponent,
} from '../../../../pages/home/not-available-installment-dialog/not-available-installment-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-subscription-payment-actions',
  templateUrl: './subscription-payment-actions.component.html',
  styleUrls: ['./subscription-payment-actions.component.scss'],
})
export class SubscriptionPaymentActionsComponent {

  @Output() checkOut: EventEmitter<boolean> = new EventEmitter<boolean>();

  viewModel: any = {

    alreadySubscribed: false,
    userNextPayment: null,

  };

  constructor(private userService: UserService, private dialog: MatDialog) {

    this.setup();

  }

  async setup() {

    const subscriptionState = await this.userService.subscriptionState();

    this.viewModel.alreadySubscribed = subscriptionState === SubscriptionStateEnum.PURCHASED || subscriptionState === SubscriptionStateEnum.WAITING;

    this.viewModel.userNextPayment = await this.userService.userNextPayment();

  }

  hasPaymentEnabled = () => !this.viewModel.alreadySubscribed || this.viewModel.userNextPayment;

  async installmentCheckout() {

    if (this.viewModel.userNextPayment) {

      const userPendingPayment = await this.userService.userPendingPayment();

      if (!!userPendingPayment) {

        this.dialog.open(NotAvailableInstallmentDialogComponent);

      } else {

        this.checkOut.emit(true);

      }

    } else {

      this.checkOut.emit(true);

    }
  }

}
