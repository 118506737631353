import { Component, Input, OnInit } from '@angular/core';
import { ProductListConfiguration } from '../product-list.configuration';
import { ProductController } from '../../../core/controllers/product.controller';
import { ProductListFilter } from '../../../core/classes/product-list-filter';
import { SPNProductQuoteDTO } from '../../../core/interfaces/spn-product-quote';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  providers: [ProductController],
})
export class ProductListComponent {

  @Input() configuration!: ProductListConfiguration;
  list: Array<SPNProductQuoteDTO> = [];

  constructor(private productController: ProductController) {
  }

  ngAfterViewInit(): void {

    this.getProducts();

  }

  getProducts(): void {

    const parameter: ProductListFilter = new ProductListFilter(this.configuration.ProductCategoryMacroType);

    if (this.configuration.ProductOIDs) {

      parameter.ProductOIDs = this.configuration.ProductOIDs;

    }

    this.productController
      .getAvailableList(parameter)
      .then((data: SPNProductQuoteDTO[]) => this.list = data);

  }

}
