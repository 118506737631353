import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { LoginController } from 'src/app/core/controllers/login.controller';
import { GeneralService } from 'src/app/core/lib/general.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  public form: FormGroup = Object.create(null);

  isPasswordReset: boolean = false;
  token: string | undefined;

  hide = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    private loginController: LoginController,
    private matSnackbar: MatSnackBar,
  ) {

    this.activatedRoute
      .queryParams.subscribe(
      p => {

        if (p['t']) {
          this.token = p['t'];
        }

        if (p['e']) {
          this.form.get('email')?.setValue(p['e']);
        }

      });

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      // @ts-ignore
      email: [null, Validators.compose([Validators.required, CustomValidators.email])],
      password: [null, Validators.compose([Validators.required])],
    });

  }

  onSubmit(): void {

    if (this.form.valid && this.token) {

      this.loginController
        .put(this.form.get('email')?.value, this.token, this.form.get('password')?.value)
        .then((data: any) => {

          this.matSnackbar.open('Password reimpostata con successo', 'X', {
            duration: 3000,
            panelClass: 'general-snackbar-panel',
          });

          this.generalService.navigateTo(['/']);

        });

    }

  }

}
