<div class='cart-container'>

  <h2> Carrello </h2>

  <div>

    <ng-container *ngIf='cart?.CartProducts.length > 0; else emptyTemplate'>

      <div>

        <h4 class='m-t-0'> In questa sezione potrai consultare la lista dei prodotti che hai aggiunto al tuo
          carrello.</h4>

      </div>

      <mat-list role='list'>

        <ng-container *ngFor='let product of cart.CartProducts'>

          <app-purchase-item [item]='product'></app-purchase-item>

          <div style='margin-bottom: 15px; text-align: right'>

            <button mat-raised-button color='warn' (click)='deleteFromCart(product)'> Rimuovi
              <mat-icon>delete</mat-icon>
            </button>

          </div>

        </ng-container>

        <mat-divider></mat-divider>

      </mat-list>

      <mat-divider></mat-divider>

      <div class='cart-recap clearfix'>

        <div class='text-right total-price'>

          <h2>Totale: {{ cart.FinalAmount | currency:'EUR':'symbol':'1.0'}}</h2>

          <button mat-raised-button color='primary' (click)='checkOut()'> Vai al pagamento</button>

        </div>

        <div class='empty-cart-container'>

          <h2> &nbsp; </h2>

          <button mat-raised-button color='warn' (click)='emptyCart()'> Svuota carrello
            <mat-icon>delete_sweep</mat-icon>
          </button>

        </div>

      </div>

    </ng-container>
    <ng-template #emptyTemplate>

      <p class='text-center'> Il tuo carrello è vuoto.</p>

    </ng-template>

  </div>

</div>
