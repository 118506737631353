import { Injectable } from '@angular/core';
import { RoleService } from '../../core/lib/role.service';
import { MenuTechnicalItems } from './menu.technical-items';
import { MenuDefaultItems } from './menu.default-items';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface Separator {
  name: string;
  type?: string;
}

export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
}

@Injectable()
export class MenuItems {
  constructor(private roleService: RoleService) {
  }

  async getMenuitem(): Promise<Menu[]> {
    return (await this.roleService.isTechnical()) ? MenuTechnicalItems : MenuDefaultItems;
  }

}
