import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsComponent } from './documents.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DocumentsUploaderModule } from '../../../_components/documents-uploader/documents-uploader.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [
    DocumentsComponent,
  ],
  exports: [
    DocumentsComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    DocumentsUploaderModule,
    MatButtonModule
  ],
  providers: [],
})
export class DocumentsModule {
}
