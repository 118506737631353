import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventConvocationsListComponent } from './event-convocations-list/event-convocations-list.component';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import { SplittedTimeFormatModule } from '../event-results-list/splitted-time-format/splitted-time-format.module';

@NgModule({
  declarations: [
    EventConvocationsListComponent,
  ],
  exports: [
    EventConvocationsListComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    SplittedTimeFormatModule,
  ],
})
export class EventConvocationsListModule {
}
