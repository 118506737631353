<mat-accordion class='event-filters-cntr'>

  <mat-expansion-panel [expanded]='expandedFilterPanel'>

    <mat-expansion-panel-header>
      <mat-panel-title>
        Filtra la tua ricerca
      </mat-panel-title>
    </mat-expansion-panel-header>

    <form
      [formGroup]='form'
      fxLayout='row wrap'>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-notification-event-select-control
          [configuration]='notificationEventSelectControlConfiguration'
          [control]="form.get('EventProductOid')"></app-notification-event-select-control>

      </div>


      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-specialty-filter [control]="form.get('SwimStyle')"></app-spn-specialty-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-distance-filter [control]="form.get('Distance')"></app-spn-distance-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-pool-length-filter [control]="form.get('PoolLength')"></app-spn-pool-length-filter>

      </div>


      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-company-business-name-filter
          [parentName]="'qualificationsFilterComponent'"
          [control]="form.get('CompanyBusinessName')"></app-spn-company-business-name-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-athlete-category-filter
          [multiple]='true'
          [control]="form.get('AthleteCategories')"></app-spn-athlete-category-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-gender-filter
          [control]="form.get('Gender')"></app-spn-gender-filter>

      </div>

    </form>

  </mat-expansion-panel>

</mat-accordion>

