import { Component, OnInit } from '@angular/core';
import { IUBENDA_CONFIG } from '../../../../../app.module';
import { CtWidgetConfiguration } from '@ctsolution/ct-framework';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {

  viewModel = IUBENDA_CONFIG;

  widgets: CtWidgetConfiguration[] = [];

  constructor() {
  }

  ngOnInit(): void {

    Object.keys(IUBENDA_CONFIG)
      .forEach((key: string) => {

        const element: { label: string, url: string } = (<any>IUBENDA_CONFIG)[key];
        this.widgets.push(this.getWidgetConfiguration(element.label, element.url));

      });

  }

  getWidgetConfiguration = (label: string, url: string): CtWidgetConfiguration => CtWidgetConfiguration.create(() => window.open(url, '_blank'))
    .setTitle(label)
    .setText('Visualizza')
    .setMaterialIcon('policy');

}
