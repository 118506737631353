import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchasesListComponent } from './purchases-list/purchases-list.component';
import { RouterModule } from '@angular/router';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { UserPurchasesComponent } from './purchases-list/user-purchases/user-purchases.component';
import {
  PurchaseItemSubheaderComponent,
} from './purchases-list/user-purchases/purchase-item-subheader/purchase-item-subheader.component';
import { PurchaseItemModule } from './purchases-list/user-purchases/purchase-item/purchase-item.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [
    PurchasesListComponent,
    UserPurchasesComponent,
    PurchaseItemSubheaderComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    PurchaseItemModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: '',
            component: PurchasesListComponent,
            data: {
              title: 'I miei acquisti',
              urls: [{ title: 'I miei acquisti', url: '/personal-area/purchases' }, { title: 'I miei acquisti' }],
            },
          }],
      },
    ]),
  ],
})
export class PurchasesModule {
}
