import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualificationsComponent } from './qualifications.component';
import { RouterModule } from '@angular/router';
import { CtDatatableModule } from '@ctsolution/ct-framework';
import {
  SplittedTimeFormatModule,
} from '../_components/event-card/event-card/event-results-list/splitted-time-format/splitted-time-format.module';
import { QualificationsFiltersModule } from './qualifications-filters/qualifications-filters.module';

@NgModule({
  declarations: [
    QualificationsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: QualificationsComponent,
        data: {
          title: 'Qualifiche',
        },
      },
    ]),
    CtDatatableModule,
    SplittedTimeFormatModule,
    QualificationsFiltersModule,
  ],
})
export class QualificationsModule {
}
