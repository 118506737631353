import { Component } from '@angular/core';
import { ProductListConfiguration } from '../../../_components/product-list/product-list.configuration';
import { ProductCategoryMacroTypeEnum } from '../../../core/enums/product-category-macro-type.enum';

@Component({
  selector: 'app-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrls: ['./subscriptions-list.component.scss'],
})
export class SubscriptionsListComponent {

  subscriptions: ProductListConfiguration = ProductListConfiguration.create(ProductCategoryMacroTypeEnum.Subscription);

  constructor() {
  }

}
