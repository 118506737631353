import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DeleteProfileConfirmModule } from './delete-profile-confirm/delete-profile-confirm.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DeleteProfileConfirmModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: 'change-password',
            component: ChangePasswordComponent,
            data: {
              title: 'Modifica password',
              urls: [{
                title: 'Modifica password',
                url: '/personal-area/profile/change-password',
              }, { title: 'Modifica password' }],
            },
          },
          {
            path: '',
            component: ProfileComponent,
            data: {
              title: 'Profilo',
              urls: [{ title: 'Profilo', url: '/personal-area/profile' }, { title: 'Profilo' }],
            },
          }],
      },
    ]),
  ],
})
export class ProfileModule {
}
