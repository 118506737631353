<div class='register-form-cntr'>

  <form [formGroup]='form' (ngSubmit)='onSubmit()'>

    <div class='text-center'>

      <img class='logo-icon-format' alt='superbanuoto' src='assets/images/logo_superbanuoto_icon.svg' />
      <h4 class='m-t-0'>Registrati</h4>

    </div>

    <div fxLayout='row wrap'>

      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>

        <mat-form-field style='width: 100%'>

          <input matInput placeholder='Nome' type='text' formControlName='name' />

          <mat-error *ngIf="form.get('name')?.hasError('required')">Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('name')?.hasError('maxlength')">Il nome è
            troppo lungo
          </mat-error>

        </mat-form-field>

      </div>

      <!-- col full-->
      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>

        <mat-form-field style='width: 100%'>

          <input matInput placeholder='Cognome' type='text' formControlName='surname' />

          <mat-error *ngIf="form.get('surname')?.hasError('required')">Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('surname')?.hasError('maxlength')">Il cognome è
            troppo lungo
          </mat-error>

        </mat-form-field>

      </div>

      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>

        <mat-form-field style='width: 100%'>

          <input matInput placeholder='Indirizzo mail' type='email' formControlName='email' />

          <mat-error *ngIf="form.get('email')?.hasError('required')">Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('surname')?.hasError('maxlength')">La mail è
            troppo lunga
          </mat-error>
          <mat-error *ngIf="form.get('email')?.hasError('email')">Inserisci un indirizzo email valido</mat-error>

        </mat-form-field>

      </div>

      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>

        <mat-form-field appearance='fill' class='date-box-cntr' (click)='picker.open()'>

          <mat-label>Data di nascita (gg/mm/aaaa)</mat-label>
          <input matInput [matDatepicker]='picker' formControlName='birthDate' readonly [max]='birthDateMaxValue'>
          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>

          <mat-error *ngIf="form.get('birthDate')?.hasError('required')">Campo obbligatorio</mat-error>

        </mat-form-field>

      </div>

      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>

        <mat-form-field style='width: 100%'>

          <input matInput placeholder='Codice Fiscale' type='text' formControlName='fiscalCode' />

          <mat-error *ngIf="form.get('fiscalCode')?.hasError('required')">Campo obbligatorio</mat-error>
          <mat-error
            *ngIf="!form.get('fiscalCode')?.hasError('required') && form.get('fiscalCode')?.hasError('FiscalCode')">
            Campo non valido
          </mat-error>

        </mat-form-field>

      </div>

      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>

        <mat-form-field style='width: 100%'>

          <input matInput formControlName='phone' placeholder='Cellulare' type='tel' />
          <mat-error *ngIf="form.get('phone')?.hasError('required')">Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('phone')?.hasError('pattern')">Campo non valido</mat-error>

        </mat-form-field>

      </div>

      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>

        <mat-form-field style='width: 100%'>

          <input matInput placeholder='Password' formControlName='password' [type]="hide ? 'password' : 'text'" />
          <mat-icon matSuffix (click)='hide = !hide'>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>

          <mat-error *ngIf="form.get('password')?.hasError('required')">Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('password')?.hasError('passwordStrength')">La password deve essere composta da
            almeno 6 caratteri, contenere
            almeno una lettera minuscola, una lettera maiuscola e un numero
          </mat-error>

        </mat-form-field>

      </div>

      <!-- col full-->
      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>

        <mat-form-field style='width: 100%'>

          <input matInput placeholder='Conferma Password' formControlName='confirmPassword'
                 [type]="hideConf ? 'password' : 'text'" />
          <mat-icon matSuffix (click)='hideConf = !hideConf'>{{hideConf ? 'visibility_off' : 'visibility'}}</mat-icon>

          <mat-error *ngIf="form.get('confirmPassword')?.hasError('required')">Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('confirmPassword')?.hasError('equalTo')">Le password non coincidono.</mat-error>

        </mat-form-field>

      </div>

      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>

        <ng-container *ngIf='companiesList && companiesList.length > 0'>

          <mat-form-field>

            <mat-label>Sede Gruppo di allenamento</mat-label>

            <mat-select class='mt-2 mb-4' formControlName='company'>

              <mat-option *ngFor='let company of companiesList'
                          [value]='company.Oid'>{{ company.BusinessName }}</mat-option>

            </mat-select>

            <mat-error *ngIf="form.get('company')?.hasError('required')">Campo obbligatorio</mat-error>

          </mat-form-field>

        </ng-container>

      </div>

      <!-- col full-->
      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>
        <mat-checkbox class='font-14' formControlName='termsAndCondition'>Accetto i
          <a [href]='viewModel.termsAndConditions.url' target='_blank' class='link text-info'>termini e le
            condizioni</a>.
        </mat-checkbox>
        <small *ngIf="submitted && form.controls['termsAndCondition'].errors" class='text-danger terms-error'>Campo
          obbligatorio</small>

      </div>

      <!-- col full-->
      <!-- <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>
        <mat-checkbox class='font-14' formControlName='newsletter'>Voglio iscrivermi alla newsletter.</mat-checkbox>
      </div> -->

      <!-- col full-->
      <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-10'>
        <!--  [disabled]="!form.valid" -->
        <button mat-raised-button color='primary' class='btn-block btn-lg m-t-20 m-b-20 backgroundColor' type='submit'>
          Crea il tuo account
        </button>
      </div>

      <div class='text-center' fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>

                <span>Hai già un account?
                    <a [routerLink]="['/authentication/login']" class='link text-info'> Login</a>
                </span>

      </div>

    </div>

  </form>

</div>
