<div class='event-list-container'>

  <div class='container p-10'>

    <app-event-filters [parentName]="'eventListComponent'" #filters (update)='getList()'></app-event-filters>

  </div>

  <div fxLayout='row wrap' fxLayoutAlign='center start'>

    <ng-container
      *ctSkeleton="viewModel.loadingList;
           repeat: 3;
           className: 'event-card-skeleton';">

      <ng-container *ngIf='events.length > 0; else emptyListTemplate'>

        <app-event-card
          fxFlex.gt-xs='100'
          fxFlex.gt-sm='50'
          fxFlex.gt-md='50'
          fxFlex='100'
          *ngFor='let event of events'
          [product]='event'
          (updateList)='getList()'></app-event-card>

      </ng-container>
      <ng-template #emptyListTemplate>

        <p class='text-center'> Nessun evento disponibile. </p>

      </ng-template>

    </ng-container>

  </div>

</div>

