import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerateNotificationFormDialogComponent } from './generate-notification-form-dialog.component';
import { GenerateNotificationFormDialogService } from './generate-notification-form-dialog.service';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalNotificationController } from '../../../../core/controllers/global-notification.controller';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import {
  NotificationCompaniesSelectControlModule
} from './notification-companies-select-control/notification-companies-select-control.module';
import {
  NotificationEventSelectControlModule
} from './notification-event-select-control/notification-event-select-control.module';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { FlexModule } from '@angular/flex-layout';
import { FileMaxSizeWarningDialogComponent } from './file-max-size-warning-dialog/file-max-size-warning-dialog.component';

@NgModule({
  declarations: [
    GenerateNotificationFormDialogComponent,
    FileMaxSizeWarningDialogComponent,
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatIconModule,
    MatLegacyInputModule,
    MatDatepickerModule,
    MatDividerModule,
    NotificationCompaniesSelectControlModule,
    NotificationEventSelectControlModule,
    MatLegacyCheckboxModule,
    FlexModule,
  ],
  providers: [
    GenerateNotificationFormDialogService,
    GlobalNotificationController,
  ],
})
export class GenerateNotificationFormDialogModule {
}
