import { Component } from '@angular/core';
import { CTDatatablePaginationParameter, CtDatatableResult } from '@ctsolution/ct-framework';
import { EventController, EventListFilterDTO } from '../../../core/controllers/event.controller';
import { CalendarService } from '../../../core/lib/calendar.service';
import { SPNEventProduct } from '../../../core/classes/SPNEvent';
import { EventCategoryCode } from '../../../core/enums/event-category-code';

@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.scss'],
})
export class UpcomingComponent {

  constructor(
    private eventController: EventController,
    public calendarService: CalendarService) {
  }

  // tslint:disable-next-line:max-line-length
  getCompetitions = (params: EventListFilterDTO = new EventListFilterDTO()): Promise<CtDatatableResult<SPNEventProduct>> => this.eventController.getList(this.getEventFilter(params, EventCategoryCode.COMPETITION), new CTDatatablePaginationParameter(1, 100));

  // tslint:disable-next-line:max-line-length
  getEvents = (params: EventListFilterDTO = new EventListFilterDTO()): Promise<CtDatatableResult<SPNEventProduct>> => this.eventController.getList(this.getEventFilter(params, EventCategoryCode.EVENT), new CTDatatablePaginationParameter(1, 100));

  private getEventFilter(params: EventListFilterDTO, categoryCode: EventCategoryCode): EventListFilterDTO {

    params.CategoryCode = categoryCode;
    return params;

  }

}
