import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AthletesListModule } from './athletes-list/athletes-list.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: 'list',
            loadChildren: () => AthletesListModule,
          },
          {
            path: '**',
            redirectTo: 'list',
            pathMatch: 'full',
          },
        ],
      },
    ]),
  ],
})
export class AthletesModule {
}
