<mat-card
  class='event-card'
  [ngClass]='product?.EventProduct?.CategoryCode'
  [class.subscribed]='viewModel.confirmed'>

  <mat-card-header>

    <img
      *ngIf='viewModel.icon'
      mat-card-avatar [src]='viewModel.icon'>

    <mat-card-title> {{ product?.Name }}</mat-card-title>

    <mat-card-subtitle>
      <img src='/assets/images/events/logo/FIN.svg'> <img src='/assets/images/events/logo/superba.svg'>
    </mat-card-subtitle>

    <img mat-card-md-image
         *ngIf='viewModel.athleteImagePreviewUrl'
         [src]='viewModel.athleteImagePreviewUrl | secureImage | async'>

  </mat-card-header>

  <mat-card-content>

    <div class='m-b-15'>

      <app-event-card-info
        [product]='product'
        (download)='downloadBrochure()'
        (downloadLimitTimes)='downloadLimitTimes()'></app-event-card-info>

    </div>

    <app-event-convocations-list
      *ngIf='viewModel.subscribable'
      [product]='product'></app-event-convocations-list>

    <app-event-results-list
      *ngIf='product?.EventProduct?.RaceResultList'
      [product]='product'
      [historicalDetailFilters]='historicalDetailFilters'
      (compare)='compare.emit($event)'></app-event-results-list>

  </mat-card-content>

  <mat-card-actions>

    <app-event-card-actions
      [product]='product'
      (downloadBrochure)='downloadBrochure()'
      (downloadLimitTimes)='downloadLimitTimes()'
      (downloadConvocationDocument)='downloadConvocationDocument()'></app-event-card-actions>

  </mat-card-actions>

</mat-card>
