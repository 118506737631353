import { FileDTO } from "src/app/core/interfaces/file";

export class DocumentsUploaderConfiguration {

  // tslint:disable-next-line:variable-name
  private _values: FileDTO[] = [];
  get values(): FileDTO[] {

    return this._values;

  }

  set values(values: FileDTO[]) {

    this._values = values;

  }

  public static create = (): DocumentsUploaderConfiguration => new DocumentsUploaderConfiguration();

  setValues(values: FileDTO[]): DocumentsUploaderConfiguration {

    this.values = values;
    return this;

  }

}
