import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotificationsWidgetModule } from '../../_components/notifications-widget/notifications-widget.module';
import { GenerateNotificationComponent } from './generate-notification/generate-notification.component';
import { NotificationsComponent } from './notifications-list/notifications.component';
import { GlobalNotificationController } from '../../core/controllers/global-notification.controller';
import {
  GenerateNotificationFormDialogModule,
} from './generate-notification/generate-notification-form-dialog/generate-notification-form-dialog.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [
    NotificationsComponent,
    GenerateNotificationComponent,
  ],
  imports: [
    CommonModule,
    NotificationsWidgetModule,
    GenerateNotificationFormDialogModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: 'send',
            component: GenerateNotificationComponent,
            data: {
              title: 'Comunicazioni',
              urls: [{ title: 'Comunicazioni', url: '/notifications/send' }, { title: 'Comunicazioni' }],
            },
          },
          {
            path: '',
            component: NotificationsComponent,
            data: {
              title: 'Notifiche',
              urls: [{ title: 'Notifiche', url: '/notifications' }, { title: 'Notifiche' }],
            },
          },
          {
            path: 'historical',
            pathMatch: 'full',
            redirectTo: '',
          }],
      },
    ]),
    MatIconModule,
    MatLegacyButtonModule,
  ],
  providers: [GlobalNotificationController],
})
export class NotificationsModule {
}
