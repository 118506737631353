import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  CELL_TYPE,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter,
  CtDatatableResult,
} from '@ctsolution/ct-framework';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Record } from '../../../core/interfaces/record';
import { RecordController } from '../../../core/controllers/record.controller';
import { RecordFiltersComponent } from './record-filter/record-filters.component';
import { RecordCategory, RecordFilter, RecordType } from '../../../core/classes/record-filter';
import { EventController, PoolLengthDTO, SwimStyleDTO } from '../../../core/controllers/event.controller';
import { GenderEnum } from '../../../core/enums/gender.enum';
import { EnumHelper } from '../../../core/lib/enum.helper';
import { POOL_LENGTHS } from '../../../_components/spn-filters/spn-pool-length-filter/spn-pool-length-filter.component';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  providers: [RecordController],
})
export class RecordComponent {

  @ViewChild('filters') recordFiltersComponent!: RecordFiltersComponent;
  @ViewChild('recordTemplate') recordTemplate!: TemplateRef<any>;
  @ViewChild('descriptionTemplate') descriptionTemplate!: TemplateRef<any>;

  record: CtDatatableConfiguration | undefined;
  private params: RecordFilter = RecordFilter.create();

  SWIM_STYLE: SwimStyleDTO[] = [];

  constructor(private recordController: RecordController, private enumHelper: EnumHelper, private eventController: EventController) {
  }

  ngAfterViewInit() {

    this.setupColumn();

  }

  async setupColumn() {

    const columns: TableColumn[] = [
      {
        prop: 'RecordDate',
        name: 'Data',
        cellTemplate: this.recordTemplate,
      },
      {
        prop: 'TimeForGrid',
        name: '',
        cellTemplate: this.descriptionTemplate,
        sortable: false,
        width: 100,
      }];

    this.SWIM_STYLE = await this.eventController
      .getSwimStyleList();

    this.record = CtDatatableConfiguration
      .create()
      .setExternalPaging(true)
      .setHeaderHeight(0)
      .setColumns(columns);

  }

  filter(event: any): void {

    this.params = event ?? {};
    this.fetch();

  }

  fetch(pagination?: CTDatatablePaginationParameter): void {

    this.params = this.recordFiltersComponent.setFilters(this.params);

    this.record?.setLoadingIndicator(true);

    this.recordController
      .getList(this.params, pagination)
      .then((result: CtDatatableResult<Record>) => {

        this.record
          ?.setCount(result.ItemTotal)
          .setRows(result.Items)
          .setLoadingIndicator(false);

      });

  }

  getPoolLength(row: any) {

    const poolLength: PoolLengthDTO | undefined = POOL_LENGTHS.find((elm: PoolLengthDTO) => elm.Oid === row.PoolLength);

    if (poolLength) {

      return poolLength.Value;

    }

    return POOL_LENGTHS[0].Value;

  }

  getTypeLabel = (value: string) => this.enumHelper.getEnumLabel(RecordType, value);

  getCategoryLabel = (value: string) => this.enumHelper.getEnumLabel(RecordCategory, value);

  getSpecialtyLabel = (value: string) => this.SWIM_STYLE.find(element => element.Oid === +value)?.Name;

  getGender = (value: string) => this.enumHelper.getEnumLabel(GenderEnum, value);

}
