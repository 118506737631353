import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegisterComponent} from './register.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {RegistrationStep1Module} from "./registration-step1/registration-step1.module";
import { CompanyController } from 'src/app/core/controllers/company.controller';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    RegistrationStep1Module
  ],
  declarations: [RegisterComponent],
  exports: [RegisterComponent],
  providers: [CompanyController]
})
export class RegisterModule {
}
