import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialRankingsComponent } from './special-rankings.component';
import { RouterModule } from '@angular/router';
import { CtDatatableModule } from '@ctsolution/ct-framework';
import { EventFiltersModule } from '../_components/event-list/event-filters/event-filters.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { SpecialRankingsFiltersModule } from './special-rankings-filters/special-rankings-filters.module';
import {
  SplittedTimeFormatModule
} from '../_components/event-card/event-card/event-results-list/splitted-time-format/splitted-time-format.module';

@NgModule({
  declarations: [
    SpecialRankingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: SpecialRankingsComponent,
        data: {
          title: 'Graduatorie speciali',
        },
      },
    ]),
    CtDatatableModule,
    EventFiltersModule,
    MatIconModule,
    MatLegacyButtonModule,
    SpecialRankingsFiltersModule,
    SplittedTimeFormatModule,
  ],
})
export class SpecialRankingsModule {
}
