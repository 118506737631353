export class GetEventDetailParameter {

  userInfoOid: number | null = null;
  productOid: number | null = null;

  public static create = (): GetEventDetailParameter => new GetEventDetailParameter();

  setUserInfoOid(value: number | null): GetEventDetailParameter {

    this.userInfoOid = value;
    return this;

  }

  setProductOid(value: number | null): GetEventDetailParameter {

    this.productOid = value;
    return this;

  }

}
