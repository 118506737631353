import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemComponent } from './product-item.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  SubscriptionPaymentActionsComponent,
} from './subscription-payment-actions/subscription-payment-actions.component';
import { EventActionsComponent } from './event-actions/event-actions.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { SecurePipeModule } from '../../../core/pipes/secure-pipe.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [
    ProductItemComponent,
    SubscriptionPaymentActionsComponent,
    EventActionsComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    SecurePipeModule,
    FlexLayoutModule,
    MatDialogModule,
  ],
  exports: [
    ProductItemComponent,
  ],
})
export class ProductItemModule {
}
