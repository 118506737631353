<div class='documents-container'>

  <h2> Documenti</h2>

  <div>

    <h4 class='m-t-0'>Inserisci i tuoi documenti</h4>

  </div>

  <div fxLayout='row wrap'>

    <div fxFlex='100'>

      <app-documents-uploader [configuration]='documentsUploaderConfiguration'
                              (refreshDocument)='getRequestedDocuments()' #documentsUploader></app-documents-uploader>

    </div>

  </div>

  <div class='actions-container m-t-25' style='padding: 10px'>

    <button mat-button *ngIf='comesFromRegistration && skipEnabled' (click)='skip()'> Salta questo passaggio</button>

    <button mat-raised-button color='primary' style='min-width: 180px;'
            (click)='goToProducts()'> {{ comesFromRegistration ? 'Salva e procedi' : 'Salva' }}
    </button>

  </div>

</div>
