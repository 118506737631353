import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss'],
})
export class ThankYouPageComponent {

  paymentImageSrc: string | null = null;
  paymentMessage: string | null = null;

  constructor(private activatedRoute: ActivatedRoute) {

    this.activatedRoute
      .queryParams.subscribe(
      p => {

        if (p.paymentSuccess && p.paymentSuccess === 'true') {

          this.setSuccessData(p);

        } else {

          this.setFailureData();

        }

      });

  }

  private setFailureData() {

    this.paymentImageSrc = '/assets/images/thank-you-page/payment_failed.svg';
    this.paymentMessage = 'Il tuo pagamento non è andato a buon fine';

  }

  private setSuccessData(p: any) {

    this.paymentImageSrc = '/assets/images/thank-you-page/payment_success.svg';

    this.paymentMessage = 'Il tuo pagamento è andato a buon fine.';

    if (p.macroTypes) {

      if (p.macroTypes.includes('Subscription')) {

        this.paymentMessage += '<br/> Buona stagione agonistica.';

      }

      if (p.macroTypes.includes('Merchandising')) {

        this.paymentMessage += '<br/> Il tuo ordine di materiale è stato inoltrato via mail alla segreteria e al nostro fornitore.';

      }

    }

    this.paymentMessage += '<br/><br/><br/><small class=\'text-center\'>Le ricevute saranno disponibili 24H dopo l\'acquisto nell\'apposita sezione "I miei acquisti" della tua area personale.</small>';

  }

}
