import { Injectable } from '@angular/core';
import {
  CheckoutSplittedWarningComponent,
} from '../checkout/checkout-splitted-warning/checkout-splitted-warning.component';
import { CheckOutComponent } from '../checkout/check-out.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SPNProductQuoteDTO } from '../../core/interfaces/spn-product-quote';
import { AddToCartDTO } from '../../core/classes/add-to-cart';
import { SPNProductDTO } from '../../core/interfaces/spn-product';
import { CartPaymentMethodEnum } from '../../core/enums/cart-payment-method.enum';
import { PurchaseDTO } from '../../core/classes/purchase';
import { CheckOutPaymentData } from '../checkout/checkout-payment/check-out-payment-data';
import { StripeService } from '../../core/lib/stripe.service';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { PurchaseAdditionalValues } from '../checkout/checkout-payment/checkout-payment.component';
import { UserCart } from '../../core/interfaces/user-cart';
import { UserService } from '../../core/lib/user.service';

const fullScreenDialogSettings = {
  maxWidth: '100vw',
  width: '100vw',
  maxHeight: '100vh',
  height: '100vh',
};

@Injectable({
  providedIn: 'root',
})
export class ProductListService {

  constructor(
    private dialog: MatDialog,
    private stripeService: StripeService,
    private ctAuthenticationService: CtAuthenticationService,
    private userService: UserService,
  ) {
  }

  async subscriptionCheckOut(product: SPNProductQuoteDTO, splittedPayment: boolean = false) {

    const checkOutData: CheckOutPaymentData = new CheckOutPaymentData();
    checkOutData.product = product;
    checkOutData.splittedPayment = splittedPayment;

    if (checkOutData.product.Quote.FinalPrice) {

      checkOutData.UserCart = { FinalAmount: checkOutData.product.Quote.FinalPrice } as UserCart;

    }

    const nextPayment = await this.userService.userNextPayment();

    if (!splittedPayment || nextPayment) {

      this.paymentSelectionDialog(checkOutData);

    } else {

      this.subscriptionSplittedPaymentWarning()
        .subscribe((confirm: boolean) => {

          if (confirm) {

            this.paymentSelectionDialog(checkOutData);

          }

        });

    }

  }

  cartCheckOut(cart: UserCart | null) {

    const checkOutData: CheckOutPaymentData = new CheckOutPaymentData();
    checkOutData.fullCart = true;

    if (cart) {

      checkOutData.UserCart = cart;

    }

    this.paymentSelectionDialog(checkOutData);

  }

  subscriptionSplittedPaymentWarning = () => this.dialog.open(CheckoutSplittedWarningComponent).afterClosed();

  paymentSelectionDialog = (data: CheckOutPaymentData) => this.dialog.open(CheckOutComponent, {
    ...fullScreenDialogSettings,
    data,
  });

  getCartItemsByProduct = (productQuote: SPNProductQuoteDTO): AddToCartDTO[] => productQuote.Product.map((product: SPNProductDTO) => new AddToCartDTO(product));

  getPurchasableProductObject(method: CartPaymentMethodEnum, product: SPNProductQuoteDTO, opt: PurchaseAdditionalValues): PurchaseDTO {

    const parameter: PurchaseDTO = new PurchaseDTO(method, opt.FiscalCode);
    parameter.CartProducts = this.getCartItemsByProduct(product);

    if (opt?.CouponAmount) {

      parameter.CouponAmount = opt.CouponAmount;

    }

    return parameter;

  };

  private isValidHttpUrl(string: string) {

    let url;

    try {

      url = new URL(string);
    } catch (_) {

      return false;

    }

    return url.protocol === 'http:' || url.protocol === 'https:';

  }

  handlePurchaseResult = (method: CartPaymentMethodEnum, data: any): Promise<CartPaymentMethodEnum> => {

    return new Promise((resolve) => {

      switch (method) {

        case CartPaymentMethodEnum.Stripe:
        case CartPaymentMethodEnum.StripeSplitted2:

          if (this.isValidHttpUrl(data.Result)) {

            window.location.href = data.Result;

          } else {

            this.stripeService.handleCheckOutForm(data.Result);

          }

          break;

        case CartPaymentMethodEnum.Bank:
        case CartPaymentMethodEnum.BankTransferSplitted2:
        case CartPaymentMethodEnum.Financig:

          this.ctAuthenticationService
            .getClaims()
            .then(() => {

              resolve(method);

            });

          break;

      }

    });

  };

}
