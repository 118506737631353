import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-spn-name-surname-filter',
  templateUrl: './spn-name-surname-filter.component.html',
})
export class SpnNameSurnameFilterComponent {

  @Input() control: FormControl | null = null;

}
