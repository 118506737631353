import { Component, TemplateRef, ViewChild } from '@angular/core';
import { EventFiltersComponent } from '../_components/event-list/event-filters/event-filters.component';
import { TimeLimitController } from '../../../core/controllers/time-limit.controller';
import {
  CELL_TYPE,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter,
  CtDatatableResult,
} from '@ctsolution/ct-framework';
import { EventListFilterDTO } from '../../../core/controllers/event.controller';
import { TableColumn } from '@swimlane/ngx-datatable';
import { TimeLimitResult } from '../../../core/interfaces/time-limit';
import { SPNEventProduct } from '../../../core/classes/SPNEvent';
import {
  HistoricalEventDetailComponent,
  HistoricalEventDetailConfiguration,
} from '../historical/historical-event-detail/historical-event-detail.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { GeneralService } from '../../../core/lib/general.service';
import { ActivatedRoute } from '@angular/router';
import { TechnicalHelperService } from '../../../core/lib/technical.helper.service';

@Component({
  selector: 'app-limit-times',
  templateUrl: './limit-times.component.html',
  styleUrls: ['./limit-times.component.scss'],
  providers: [TimeLimitController],
})
export class LimitTimesComponent {

  @ViewChild('filters') eventFiltersComponent!: EventFiltersComponent;
  @ViewChild('resultTemplate') public resultCellTemplate!: TemplateRef<any>;
  @ViewChild('descriptionTemplate') public descriptionCellTemplate!: TemplateRef<any>;

  limitTimes: CtDatatableConfiguration | undefined;
  athleteQualifiedRaceDetail: {
    QualifiedRaceOid: number | null;
    QualifiedRaceName: string | null;
    UserInfoOid: number | null;
    UserInfoFullName: string | null;
  } | null = null;

  private params: EventListFilterDTO = new EventListFilterDTO();

  constructor(
    private limitTimesController: TimeLimitController,
    private bottomSheet: MatBottomSheet,
    public general: GeneralService,
    private technicalService: TechnicalHelperService,
    private route: ActivatedRoute) {

    this.route
      .queryParams
      .subscribe(qp => {

        if (qp['QualifiedRaceOid'] && qp['UserInfoOid'] && qp['UserInfoFullName'] && qp['QualifiedRaceName']) {

          this.athleteQualifiedRaceDetail = {
            QualifiedRaceOid: +qp['QualifiedRaceOid'],
            QualifiedRaceName: qp['QualifiedRaceName'],
            UserInfoOid: +qp['UserInfoOid'],
            UserInfoFullName: qp['UserInfoFullName'],
          };

        }

      });

  }

  ngAfterViewInit() {

    const columns: TableColumn[] = [
      {
        prop: 'TimeLimit.SPNEventProductStartDate',
        name: 'Data',
        cellClass: CELL_TYPE.DATE,
        maxWidth: 100,
      },
      {
        prop: 'TimeLimit',
        name: '',
        cellTemplate: this.descriptionCellTemplate,
        sortable: false,
      },
      {
        prop: 'AthleteResult',
        name: 'Risultato',
        cellTemplate: this.resultCellTemplate,
        sortable: false,
        maxWidth: 100,
      },
    ];

    this.limitTimes = CtDatatableConfiguration
      .create()
      .setExternalPaging(true)
      .setColumns(columns)
      .setDetailActionEnabled(true);

  }

  filter(event: any): void {

    this.params = event ?? {};

    this.fetch();

  }

  fetch(pagination?: CTDatatablePaginationParameter): void {

    this.params = this.eventFiltersComponent.setFilters(this.params);

    if (this.athleteQualifiedRaceDetail) { // visualizzazione qualifiche atleta

      this.params.FromEventStartDate = null;
      this.params.ToEventStartDate = null;
      this.params.QualifiedRaceOid = this.athleteQualifiedRaceDetail.QualifiedRaceOid;
      this.params.UserInfoOid = this.athleteQualifiedRaceDetail.UserInfoOid;

    }

    this.limitTimes?.setLoadingIndicator(true);

    this.limitTimesController
      .getList(this.params, pagination)
      .then((result: CtDatatableResult<TimeLimitResult>) => {

        this.limitTimes
          ?.setCount(result.ItemTotal)
          .setRows((<any>result)['FulfilledList'])
          .setLoadingIndicator(false);

      });

  }

  detail(value: TimeLimitResult): void {

    const product: SPNEventProduct = SPNEventProduct
      .create()
      .setupFromAthleteTimeResult(value.AthleteResult);

    const configuration: HistoricalEventDetailConfiguration = HistoricalEventDetailConfiguration
      .create(product)
      .setBypassFetch(true);

    configuration
      .filters
      .setBypassTrend(true);

    this.bottomSheet.open(HistoricalEventDetailComponent, {
      data: configuration,
      panelClass: 'bottom-sheet-event-detail-cntr',
    });

  }

  back() {

    this.technicalService.backToAthleteList(this.athleteQualifiedRaceDetail?.UserInfoOid);

  }

}
