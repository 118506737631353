<div class='m-b-20'>

  <div style='display: flex; flex-direction: column;'>

    <!--    <button mat-raised-button color='primary' class='m-b-10'
                (click)='directPurchase()'> PAGAMENTO
        </button>-->

    <button mat-raised-button color='accent'
            (click)='addToCart()'> AGGIUNGI AL CARRELLO
    </button>

  </div>

</div>
