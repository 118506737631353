import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { UserController } from '../../../core/controllers/user.controller';
import { AthleteFilterParameter } from '../../../core/classes/athlete-filter-parameter';
import { AthleteCardConfiguration } from './athlete-card/athlete-card.configuration';
import { Athlete } from '../../../core/classes/athlete';
import { ActivatedRoute } from '@angular/router';
import { HtmlService } from '../../../core/lib/html.service';

@Component({
  selector: 'app-athletes-list',
  templateUrl: './athletes-list.component.html',
  styleUrls: ['./athletes-list.component.scss'],
  providers: [UserController],
})
export class AthletesListComponent {

  athletes: AthleteCardConfiguration[] = [];
  @ViewChildren('athleteCardContainer') athleteCards: QueryList<ElementRef> = new QueryList<ElementRef>();

  viewModel = {

    loadingList: true,

  };

  constructor(private userController: UserController, private route: ActivatedRoute, private html: HtmlService) {
  }

  getList(filter: AthleteFilterParameter = AthleteFilterParameter.create()) {

    this.viewModel.loadingList = true;

    this.userController
      .getAthleteCardList(filter)
      .subscribe(response => {

        this.athletes = response.Result.Items.map(item => AthleteCardConfiguration.create().setInfo(Athlete.create(item)));

        this.viewModel.loadingList = false;

       // this.scrollToAthlete();

      });

  }

  scrollToAthlete() {

    this.route
      .queryParams
      .subscribe(qp => {

        if (qp['oid']) {

          setTimeout(() => {

            const specificAthleteCard = this.athleteCards
              .find(card => card?.nativeElement?.getAttribute('oid') === qp['oid']);

            if (specificAthleteCard) this.html.scrollToElement(specificAthleteCard);

          });

        }

      });

  }

}
