import { SPNEventProduct } from '../classes/SPNEvent';
import { CTBase } from '@ctsolution/ct-base';

export class Athlete extends CTBase<Athlete> {

  Oid: number | null = null;
  UserId: string | null = null;
  FullName: string | null = null;
  Name: string | null = null;
  Surname: string | null = null;
  Phone: string | null = null;
  CompanyOid: number | null = null;
  CompanyBusinessName: string | null = null;
  AthleteCategoryDescription: string | null = null;
  DateOfBirth: string | null = null;
  PortraitUrl: string | null = null;
  QualifiedRaceList: SPNEventProduct[] | null = new Array<SPNEventProduct>();

  createListElementClassByProperty(value: any, attribute: string) {

    switch (attribute) {

      case 'QualifiedRaceList':
        return SPNEventProduct.create(value);

      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  private constructor();
  private constructor(model?: Athlete);
  private constructor(model?: Athlete) {

    super();
    if (model) this.getClass(model);

  }

  public static create = (model?: Athlete): Athlete => new Athlete(model);

  getFullYear(): number | null {

    if (!this.DateOfBirth) return null;

    return new Date(this.DateOfBirth).getFullYear();

  }

}
