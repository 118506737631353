import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCardInfoComponent } from './event-card-info/event-card-info.component';
import { FlexModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    EventCardInfoComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
  ],
  exports: [EventCardInfoComponent]
})
export class EventCardInfoModule {
}
