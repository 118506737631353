import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EnumHelper } from '../../../core/lib/enum.helper';
import { GenderEnum } from '../../../core/enums/gender.enum';

@Component({
  selector: 'app-spn-gender-filter',
  templateUrl: './spn-gender-filter.component.html',
})
export class SpnGenderFilterComponent {

  @Input() control: FormControl | null = null;

  values;

  constructor(private enumHelper: EnumHelper) {

    this.values = this.enumHelper.getEnumValues(GenderEnum);

  }

  getLabel = (value: string) => this.enumHelper.getEnumLabel(GenderEnum, value);


}
