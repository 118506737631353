<div class="login-register">

  <div class="login-register-box">

    <mat-card>

      <mat-card-content>

        <app-registration-step1></app-registration-step1>

      </mat-card-content>

    </mat-card>

  </div>

</div>
