import { Injectable } from '@angular/core';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { SubscriptionStateEnum } from '../enums/subscription-state.enum';
import { CartPaymentDTO } from '../interfaces/cart-payment';
import { CartPaymentStateEnum } from '../enums/cart-payment-state.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private ctAuthenticationService: CtAuthenticationService) {
  }

  private async userBirthDate() {

    await this.ctAuthenticationService.claimsUploaded();
    return this.ctAuthenticationService.getJwtValueByContainedKey('DateOfBirth');

  }

  private async userNeedsParentData() {

    await this.ctAuthenticationService.claimsUploaded();
    return this.ctAuthenticationService.getJwtValueByContainedKey('NeedParentData') === 'True';

  }

  async getUserName() {

    await this.ctAuthenticationService.claimsUploaded();
    return this.ctAuthenticationService.getJwtValueByContainedKey('Name');

  };

  async getEventCalendar() {

    await this.ctAuthenticationService.claimsUploaded();
    return this.ctAuthenticationService.getJwtValueByContainedKey('EventCalendar');

  };

  async getUserSurname() {

    await this.ctAuthenticationService.claimsUploaded();
    return this.ctAuthenticationService.getJwtValueByContainedKey('Surname');

  }

  async getCompanyBusinessName() {

    await this.ctAuthenticationService.claimsUploaded();
    return this.ctAuthenticationService.getJwtValueByContainedKey('CompanyBusinessName');

  }

  async getCompanyOid() {

    await this.ctAuthenticationService.claimsUploaded();
    return this.ctAuthenticationService.getJwtValueByContainedKey('CompanyOid');

  }

  async userNeedsDocuments() {

    await this.ctAuthenticationService.claimsUploaded();
    return this.ctAuthenticationService.getJwtValueByContainedKey('NeedDocuments') === 'True';

  }

  async subscriptionState(): Promise<SubscriptionStateEnum | null> {

    await this.ctAuthenticationService.claimsUploaded();
    return this.ctAuthenticationService.getJwtValueByContainedKey('SubscriptionState');

  };

  async needsParentalSupervision() {

    const adultUser = await this.adultUser();
    const userNeedsParentData = await this.userNeedsParentData();

    return userNeedsParentData && !adultUser;

  }

  async subscriptionExpired() {

    await this.ctAuthenticationService.claimsUploaded();

    const expiration: string | null = this.ctAuthenticationService.getJwtValueByContainedKey('SubscriptionExpireDate');

    if (expiration) {

      const expirationDate: Date = new Date(expiration);

      return expirationDate.getTime() < new Date().getTime();

    }

    return false;

  }

  async adultUser() {

    const birthClaim: string | null = await this.userBirthDate();

    if (birthClaim) {

      const birthdate: Date = new Date(birthClaim);

      let differenceInDates = (new Date().getTime() - birthdate.getTime()) / 1000;
      differenceInDates /= (60 * 60 * 24);

      return Math.abs(Math.round(differenceInDates / 365.25)) > 17;

    }

    return false;

  }

  private async getSubscriptionRatesCart(): Promise<CartPaymentDTO[] | null> {

    await this.ctAuthenticationService.claimsUploaded();

    const value: string | null = this.ctAuthenticationService.getJwtValueByContainedKey('SubscriptionRatesCart');

    if (!value) {
      return null;
    }

    return JSON.parse(value) as CartPaymentDTO[];

  }

  private async userInstallments(): Promise<CartPaymentDTO[]> {

    const subscriptionRatesCart = await this.getSubscriptionRatesCart();

    if (!subscriptionRatesCart) return [];

    subscriptionRatesCart.forEach((element: CartPaymentDTO, index: number) => element.InstallmentIndex = index + 1);

    return subscriptionRatesCart;

  }

  async missingInstallments(): Promise<CartPaymentDTO[]> {

    const installments = await this.userInstallments();

    return installments.filter((payment: CartPaymentDTO) => [CartPaymentStateEnum.WaitingValidation, CartPaymentStateEnum.Waiting, CartPaymentStateEnum.Error].includes(payment.State));

  }

  async userPendingPayment(): Promise<CartPaymentDTO | undefined> {

    const missingInstallments = await this.missingInstallments();

    return missingInstallments.find((payment: CartPaymentDTO) => this.installmentPendingApproval(payment));

  }

  async userNextPayment(): Promise<CartPaymentDTO | null> {

    const missingInstallments = await this.missingInstallments();

    return missingInstallments.filter((payment: CartPaymentDTO) => !this.installmentPendingApproval(payment))[0] ?? null;

  }

  installmentPendingApproval = (payment: CartPaymentDTO): boolean => payment.State === CartPaymentStateEnum.WaitingValidation;

}
