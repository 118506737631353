import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentsUploaderConfiguration } from './documents-uploader.configuration';
import { DocumentBoxUploaderConfiguration } from './document-box-uploader/document-box-uploader.configuration';
import { SpnUserFile } from 'src/app/core/classes/spn-user-file';
import { FileDTO } from 'src/app/core/interfaces/file';
import { ProfileController } from 'src/app/core/controllers/profile.controller';
import { FileService } from 'src/app/core/lib/file.service';
import { DateService } from 'src/app/core/lib/date.service';
import { FileSideEnum } from '../../core/enums/file-side.enum';
import { FileMacroTypeEnum } from '../../core/enums/file-macro-type.enum';

@Component({
  selector: 'app-documents-uploader',
  templateUrl: './documents-uploader.component.html',
  styleUrls: ['./documents-uploader.component.scss'],
})
export class DocumentsUploaderComponent {

  @Input() configuration: DocumentsUploaderConfiguration = DocumentsUploaderConfiguration.create();
  @Output() refreshDocument: EventEmitter<any> = new EventEmitter<any>();

  documentBoxUploaderConfigurationsArray: DocumentBoxUploaderConfiguration[] = [];

  form: FormGroup;

  constructor(
    private dateService: DateService,
    private fileService: FileService,
    private formBuilder: FormBuilder,
    private profileController: ProfileController,
  ) {

    this.form = this.formBuilder.group({});

  }

  /**
   * It creates a form array of form groups
   */
  buildConfigurationValues(): void {

    this.form = this.formBuilder.group({});
    this.documentBoxUploaderConfigurationsArray = [];

    const valueArray = this.configuration.values.map((value: FileDTO) => {

      const documentBox = this.createDocumentBoxUploaderConfigurationByValue(value);
      return this.getDocumentFormGroupByBoxConfiguration(documentBox);

    });

    this.form.addControl('formArray', new FormArray(valueArray));

  }

  sendDocument(objectToSend: any): void {

    this.profileController
      .setDocument(objectToSend)
      .then(() => this.refreshDocument.emit());

  }

  getDocumentExpirationDate = (emissionDate: Date, expireInMonths: number): string => {

    const expirationDate: Date = new Date(emissionDate.getTime());
    expirationDate.setMonth(expirationDate.getMonth() + expireInMonths);

    return this.dateService.timeZoneOffset(expirationDate).toISOString();

  };

  /**
   * It creates a DocumentBoxUploaderConfiguration object and sets the validators for the document, date and retro controls
   * @param {any} value - any - this is the value of the document type.
   * @returns A DocumentBoxUploaderConfiguration object.
   */
  createDocumentBoxUploaderConfigurationByValue(value: FileDTO): DocumentBoxUploaderConfiguration {

    const fileType: FileDTO = {
      Oid: value.Oid,
    };

    const userFile: SpnUserFile = new SpnUserFile(fileType);

    let lastUploadedFrontFile: any | null = null;
    let lastUploadedRearFile: any | null = null;

    if (value.UploadedFiles && value.UploadedFiles.length > 0) {

      const uploadedFilesOrderByOid: SpnUserFile[] = value.UploadedFiles.sort((a: SpnUserFile, b: SpnUserFile) => b.Oid - a.Oid);

      lastUploadedFrontFile = uploadedFilesOrderByOid.find((userFile: SpnUserFile) => (userFile.Type === FileSideEnum.FRONT || userFile.Type !== FileSideEnum.REAR)) ?? null; // Per il tesserino FIN vado a sovascrivere il dato Type con il codice, dunque non posso prefiltrare per FRONT o REAR come faccio in cima
      lastUploadedRearFile = uploadedFilesOrderByOid.find((userFile: SpnUserFile) => userFile.Type === FileSideEnum.REAR) ?? null;

    }

    const configuration = DocumentBoxUploaderConfiguration
      .create(value)
      .onChange((event, type: FileSideEnum | any, date?: Date) => {

        userFile.File = event.target.files[0];

        if (value.MacroType === FileMacroTypeEnum.TESSERINO_FIN) {

          userFile.Type = configuration.documentCodeControl.value;

        } else {

          userFile.Type = type;

        }

        switch (type) {

          case FileSideEnum.REAR:
            userFile.Oid = lastUploadedRearFile?.Oid ?? -1;
            break;

          case FileSideEnum.FRONT:
          default:
            userFile.Oid = lastUploadedFrontFile?.Oid ?? -1;
            break;

        }

        if (value.ExpireInMonths && date) {

          const emissionDate: string = this.dateService.timeZoneOffset(date).toISOString();
          const expireDate: string = this.getDocumentExpirationDate(date, value.ExpireInMonths);

          userFile.EmissionDate = emissionDate;
          userFile.ExpireDate = expireDate;

        }

        const formData = this.fileService.setFormData(userFile);
        this.sendDocument(formData);

      });

    if (value.ExpireInMonths) {

      configuration.setDateControlValidators(Validators.required);

    }

    if (value.RearFront) {

      configuration.setDocumentRetroControlValidators(Validators.required);

    }

    if (value.Name) {

      configuration.setDocumentLabel(value.Name);

    }

    if (value.MacroType !== FileMacroTypeEnum.TESSERINO_FIN) {

      configuration.setDocumentControlValidators(Validators.required);

    }

    if (lastUploadedFrontFile) {

      configuration.setDocumentControlValue(lastUploadedFrontFile.PreviewUrl);

      if (value.MacroType === FileMacroTypeEnum.TESSERINO_FIN) {

        configuration.setDocumentCodeControlValue(lastUploadedFrontFile.Type);

      }

      if (lastUploadedFrontFile.EmissionDate) {

        const emissionDate: Date = new Date(lastUploadedFrontFile.EmissionDate);
        configuration.setDateControlValue(emissionDate);

      }

    }

    if (lastUploadedRearFile) {

      configuration.setDocumentRetroControlValue(lastUploadedRearFile.PreviewUrl);

    }

    this.documentBoxUploaderConfigurationsArray.push(configuration);

    return configuration;

  }

  /**
   * It creates a form group with the document control and the date control if the date control has a required validator
   * @param {DocumentBoxUploaderConfiguration} configuration - DocumentBoxUploaderConfiguration
   * @returns A FormGroup
   */
  getDocumentFormGroupByBoxConfiguration(configuration: DocumentBoxUploaderConfiguration): FormGroup {

    const formGroup: FormGroup = this.formBuilder.group({
      document: configuration.documentControl,
    });

    if (configuration.dateControl.hasValidator(Validators.required)) {

      formGroup.addControl('date', configuration.dateControl);

    }

    if (configuration.documentRetroControl.hasValidator(Validators.required)) {

      formGroup.addControl('retro', configuration.documentRetroControl);

    }

    return formGroup;

  }

  get formArray(): FormArray {

    return this.form.get('formArray') as FormArray;

  }

}
