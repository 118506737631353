import { AthleteCategoryEnum } from '../enums/athlete-category.enum';
import { GenderEnum } from '../enums/gender.enum';

export class AthleteFilterParameter {

  CompanyBusinessName: string | null = null;
  NameSurname: string | null = null;
  Category: AthleteCategoryEnum | null = null;
  Gender: GenderEnum | null = null;

  private constructor() {
  }

  public static create = (): AthleteFilterParameter => new AthleteFilterParameter();

}
