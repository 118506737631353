import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SecurePipeModule } from "../../core/pipes/secure-pipe.module";

@NgModule({
    declarations: [BannerComponent],
    exports: [BannerComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        SecurePipeModule
    ]
})
export class BannerModule { }
