import { CTBase } from '@ctsolution/ct-base';

export class SportFacility extends CTBase<SportFacility> {

  Description: string | null = null;
  City: string | null = null;
  Province: string | null = null;
  Address: string | null = null;

  private constructor();
  private constructor(model?: SportFacility);
  private constructor(model?: SportFacility) {

    super();
    if (model) this.getClass(model);

  }

  public static create = (model?: SportFacility): SportFacility => new SportFacility(model);

  setDescription(value: string | null): SportFacility {

    this.Description = value;
    return this;

  }

}
