<div class='event-cancel-confirm-cntr'>

  <h1 mat-dialog-title>Attenzione</h1>

  <div mat-dialog-content>
    <p class='text-center'>
      La tua mancata partecipazione verrà segnalata al tuo tecnico.<br/>
      Sei sicuro di voler proseguire?
    </p>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color='warn' [mat-dialog-close]='false'>
      <mat-icon>close</mat-icon>
      Annulla
    </button>

    <button mat-raised-button color='primary' [mat-dialog-close]='true'>
      Prosegui
    </button>
  </div>

</div>
