<div class='record-container'>

  <h2 class='title-min-size'> Record </h2>

  <div class='container'>

    <div class='m-b-10 m-t-10'>

      <app-record-filter #filters (update)='fetch()'></app-record-filter>

    </div>

    <ct-datatable
      *ngIf='record'
      [configuration]='record'
      (reload)='fetch($event)'></ct-datatable>

  </div>

</div>

<ng-template #recordTemplate let-row='row' let-value='value' let-i='index'>

  <div>

    <div>
      {{ row['AthleteFullName'] ?? '' }}
      <small>({{ getCategoryLabel(row['Category']) }})</small>
    </div>

    <div><small><i>{{ row['RecordDate'] | date }}</i></small></div>

  </div>

</ng-template>

<ng-template #descriptionTemplate let-row='row' let-value='value' let-i='index'>

  <div>

    <div>
      {{ getGender(row.Gender)?.toUpperCase() }} {{ row.SwimStyleOid ? getSpecialtyLabel(row.SwimStyleOid) : 'N.D.' }} {{ row.Distance ?? '' }}
    </div>
    <div><strong> TG: {{ row['TimeForGrid'] | splittedTimeFormat }}</strong></div>
    <div>
      <strong>{{ row.Type || row.Type === 0 ? getTypeLabel(row.Type) : '' }} {{ value || value === 0 ? getCategoryLabel(value) : '' }}</strong>
      (V{{ getPoolLength(row) }})
    </div>

  </div>

</ng-template>
