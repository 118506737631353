import { CartPaymentMethodEnum } from '../enums/cart-payment-method.enum';

export class CartCheckoutParameter {

  CouponAmount?: number;

  constructor(
    public PaymentMethod: CartPaymentMethodEnum,
    public FiscalCode: string,
  ) {

    this.FiscalCode = this.FiscalCode.toUpperCase();

  }

}
