<div class='m-b-20' *ngIf='hasPaymentEnabled()'>

  <div style='display: flex; flex-direction: column;'>

    <ng-container *ngIf='!viewModel.userNextPayment'>

      <button
        mat-raised-button
        color='primary' class='m-b-10'
        (click)='checkOut.emit(false)'> PAGAMENTO
      </button>

    </ng-container>

    <button mat-raised-button color='accent'
            (click)='installmentCheckout()'>

      <ng-container *ngIf='viewModel.userNextPayment; else defaultInstallmentTemplate'>

        PAGA {{ viewModel.userNextPayment.InstallmentIndex }}° RATA

      </ng-container>
      <ng-template #defaultInstallmentTemplate>
        PAGAMENTO A RATE
      </ng-template>

    </button>

  </div>

</div>
