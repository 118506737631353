import { Component, Input } from '@angular/core';
import { CartPaymentMethodEnum } from '../../../core/enums/cart-payment-method.enum';
import { CheckOutPaymentData } from '../checkout-payment/check-out-payment-data';
import { UserCartProductDTO } from '../../../core/interfaces/user-cart-product';
import { ProductCategoryMacroTypeEnum } from '../../../core/enums/product-category-macro-type.enum';

@Component({
  selector: 'app-checkout-payment-message',
  templateUrl: './checkout-payment-message.component.html',
  styleUrls: ['./checkout-payment-message.component.scss'],
})
export class CheckoutPaymentMessageComponent {

  @Input() paymentMethod?: CartPaymentMethodEnum;
  @Input() checkOutPaymentData!: CheckOutPaymentData;

  viewModel = {

    checkOutContainsMerchandising: false,

  };

  PAYMENT_METHOD = CartPaymentMethodEnum;

  constructor() {
  }

  ngAfterViewInit() {

    this.viewModel.checkOutContainsMerchandising = (this.checkOutPaymentData?.UserCart?.CartProducts ?? [])
      .some((product: UserCartProductDTO) => product.Product.CategoryMacroType === ProductCategoryMacroTypeEnum.Merchandising);

  }

}
