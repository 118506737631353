<mat-list role='list'>

  <mat-list-item role='listitem' *ngFor='let item of list'>

    <mat-icon mat-list-icon color='primary'>book_online</mat-icon>

    <div mat-line class='couponTitle'>
      <span class='couponName'>{{item.Description}}</span>
      <span class='couponAmount' *ngIf='item.Amount'> {{item.Amount | currency:'EUR':'symbol':'1.0' }}</span>
    </div>

    <div mat-line *ngIf='item.ExpireDate' class='couponExpiration' [class.expired]='couponExpired(item)'>
      <small> {{ couponExpired(item) ? 'Scaduto il' : 'Scadenza:' }} {{ item.ExpireDate | date }}</small></div>

    <mat-divider></mat-divider>
  </mat-list-item>

</mat-list>
