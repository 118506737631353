import {Component, Input, OnDestroy, ViewEncapsulation, ChangeDetectorRef} from '@angular/core';
import {LoaderService} from '@ctsolution/ct-webapi';

@Component({
  selector: 'app-spinner',
  template: `
    <div class="preloader fade-in" *ngIf="isSpinnerVisible">
      <mat-spinner class="spinner"></mat-spinner>
    </div>`,
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent implements OnDestroy {

  public isSpinnerVisible = true;

  @Input() public backgroundColor = 'rgba(0, 115, 170, 0.69)';

  constructor(private cdr: ChangeDetectorRef, private loaderService: LoaderService) {

  }

  ngAfterViewInit() {

    this.loaderService
      .isLoading
      .subscribe((v) => {

        this.isSpinnerVisible = v;
        this.cdr.detectChanges();

      });

  }

  ngOnDestroy(): void {

    this.isSpinnerVisible = false;

  }

}
