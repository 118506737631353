import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseItemComponent } from './purchase-item.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [PurchaseItemComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule
  ],
  exports: [PurchaseItemComponent],
})
export class PurchaseItemModule {
}
