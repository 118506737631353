import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RecordCategory } from '../../../core/classes/record-filter';
import { EnumHelper } from '../../../core/lib/enum.helper';

@Component({
  selector: 'app-spn-record-category-filter',
  templateUrl: './spn-record-category-filter.component.html',
})
export class SpnRecordCategoryFilterComponent {

  @Input() control: FormControl | null = null;

  values: string[] = [];

  constructor(private enumHelper: EnumHelper) {

    this.setupValues();

  }

  getLabel = (value: string) => {

    if (+value === RecordCategory.Ragazzi14) return 'Ragazzi 14';

    return this.enumHelper.getEnumLabel(RecordCategory, value);

  };

  setupValues() {

    this.values = this.enumHelper.getEnumValues(RecordCategory);

  }

}
