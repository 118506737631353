<div class='subscription-recap'>

  <ng-container *ngIf='!data.fullCart'>

    <ng-container *ngIf='!viewModel.nextPayment; else splittedPayment'>

      <h3>Riepilogo del tuo tesseramento:</h3>

    </ng-container>
    <ng-template #splittedPayment>

      <h3>Addebito {{viewModel.nextPayment?.InstallmentIndex}}° rata:</h3>

    </ng-template>

  </ng-container>

  <div style='padding-left: 25px;'>

    <p>

      <strong>Prezzo:</strong> {{ price | currency:'EUR':'symbol':'1.0'}}

    </p>

    <p *ngIf='!data.fullCart && (data.product?.Quote.DiscountAmount ?? 0) > 0'>

      <strong>Sconto:</strong> {{data.product?.Quote.DiscountAmount ?? 0 | currency:'EUR':'symbol':'1.0'}}

    </p>

    <p>

      <span *ngIf='!couponAmount; else couponInfo'>&nbsp;</span>
      <ng-template #couponInfo><strong>Coupon:</strong> -{{ couponAmount | currency:'EUR':'symbol':'1.0' }}
      </ng-template>

    </p>

  </div>

  <h4><strong>Totale:</strong> {{ getTotalAmount | currency:'EUR':'symbol':'1.0' }}</h4>

  <div *ngIf='data.splittedPayment'>
    <small> {{ splittedPaymentWarningMessage ?? '' }}</small>
  </div>

</div>
