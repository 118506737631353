import { Component, Input } from '@angular/core';
import { SPNEventRaceResult } from '../../../../../../../core/classes/SPNEventRaceResult';

@Component({
  selector: 'app-event-splitted-times',
  templateUrl: './event-splitted-times.component.html',
  styleUrls: ['./event-splitted-times.component.scss'],
})
export class EventSplittedTimesComponent {

  @Input() result: SPNEventRaceResult | null = null;

  constructor() {
  }

}
