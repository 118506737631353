import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordFiltersComponent } from './record-filters.component';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { SpnFiltersModule } from '../../../../_components/spn-filters/spn-filters.module';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';

@NgModule({
  declarations: [
    RecordFiltersComponent],
  imports: [
    CommonModule,
    FlexModule,
    FormsModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatIconModule,
    SpnFiltersModule,
    MatLegacyCheckboxModule,
  ],
  exports: [
    RecordFiltersComponent],
})
export class RecordFilterModule {
}
