<div class='text-center thank-you-page-container'>

  <img
    *ngIf='paymentImageSrc'
    [src]='paymentImageSrc' class='roll-in-blurred-right' />

  <p
    *ngIf='paymentMessage'
    [innerHtml]='paymentMessage'></p>

</div>
