import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CtAuthenticationService, SignInParameter } from '@ctsolution/ct-authentication';
import { CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { UserInfo } from '../interfaces/user-info';
import { GeneralService } from '../lib/general.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class AccountController {

  controller = '/Account/';

  params: any = {};

  constructor(
    private ctAuthenticationService: CtAuthenticationService,
    private generalService: GeneralService,
    private ctWebapiService: CtWebapiService,
    private router: Router,
    private matSnackbar: MatSnackBar,
  ) {
  }

  registration(user: UserInfo): void {


    user.Name = this.generalService.upperCamelCase(user.Name);
    user.Surname = this.generalService.upperCamelCase(user.Surname);
    user.FiscalCode = user.FiscalCode.toUpperCase();

    this.ctWebapiService
      .post(new DataRequest(`${this.controller}RegistrationAthlete`), user)
      .subscribe((r: any) => {

        let message: string = '';

        if (r.Result === 'WAITING_VALIDATION') {

          message = 'Grazie per esserti registrato. Il tuo account è in attesa di validazione.';

          this.router
            .navigate(['/authentication/login']);

        } else {

          message = 'Registrazione avvenuta con successo, ricordati di confermare il tuo account cliccando sul link che ti abbiamo inviato per email. Grazie!';

          const signInParameters = {
            Email: user.Email,
            Password: user.Password,
            RememberMe: true,
          };

          this.ctAuthenticationService
            .signIn(signInParameters as SignInParameter)
            .subscribe(() => {

              this.generalService.navigateTo(['personal-area', 'familiar'], { queryParams: { registration: true } });

            });

        }

        this.matSnackbar.open(message, 'X', {
          duration: 3000,
          panelClass: 'general-snackbar-panel',
        });

      });

  }

}
