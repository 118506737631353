import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CtDatatableModule } from '@ctsolution/ct-framework';
import { EventFiltersModule } from '../_components/event-list/event-filters/event-filters.module';
import { MatIconModule } from '@angular/material/icon';
import { LimitTimesComponent } from './limit-times.component';
import {
  SplittedTimeFormatModule
} from '../_components/event-card/event-card/event-results-list/splitted-time-format/splitted-time-format.module';
import { FlexModule } from '@angular/flex-layout';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';


@NgModule({
  declarations: [
    LimitTimesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: LimitTimesComponent,
        data: {
          title: 'I tuoi tempi limite',
          urls: [{
            title: 'I tuoi tempi limite',
            url: '/competitions-and-events/limit-times',
          }, { title: 'I tuoi tempi limite' }],
        },
      },
    ]),
    CtDatatableModule,
    EventFiltersModule,
    MatIconModule,
    SplittedTimeFormatModule,
    FlexModule,
    MatLegacyButtonModule,
  ],
})
export class LimitTimesModule {
}
