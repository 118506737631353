import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BannerService } from './banner-service';
import { AdvertisingDTO } from 'src/app/core/interfaces/advertising';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  timer = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdvertisingDTO,
    private cdr : ChangeDetectorRef,
    private dialogRef: MatDialogRef<BannerComponent>,
    private bannerService : BannerService
  ) {


  }

  ngOnInit() {

    setTimeout(() => {
      this.timer = true;
      this.cdr.detectChanges();
    }, 5000);


  }

  close(){

    this.dialogRef.close();
    this.bannerService.saveWithExpiry();

  }

  openUrl(){

    if(this.data.ExternalUrl)
      window.location.href = this.data.ExternalUrl;

  }

}
