<mat-card class='athlete-card'>

  <mat-card-header>

    <mat-card-title> {{ configuration?.info?.FullName }} </mat-card-title>

    <mat-card-subtitle>
      <p>{{ configuration?.info?.getFullYear()}} | {{ configuration?.info?.AthleteCategoryDescription }}</p>
      <p>{{ configuration?.info?.CompanyBusinessName }}</p>
    </mat-card-subtitle>

    <img mat-card-md-image
         *ngIf='configuration?.info?.PortraitUrl'
         [src]='configuration?.info?.PortraitUrl | secureImage | async'>

  </mat-card-header>

  <mat-card-content>

    <mat-list *ngIf='configuration?.info?.QualifiedRaceList?.length; else defaultTemplate'>

      <div mat-subheader>Qualifiche</div>

      <ng-container *ngFor='let race of configuration?.info?.QualifiedRaceList; let last = last;'>

        <mat-list-item>

          <div mat-line style='display: flex; align-items: center;'>
            <mat-icon>military_tech</mat-icon>
            {{race.Description}}
          </div>

          <div mat-line style='display: flex; justify-content: space-between'>

            <div>
              <small>{{race.EventProduct.StartDate | date}}</small>
            </div>

            <div>
              <button mat-icon-button (click)='eventDetail(race)' color='accent'>
                <mat-icon>info</mat-icon>
              </button>
              <button mat-icon-button (click)='limitTimes(race)' color='primary'>
                <mat-icon>history</mat-icon>
              </button>
            </div>

          </div>
        </mat-list-item>

        <mat-divider *ngIf='!last' style='margin: 10px auto; width: 90%;'></mat-divider>

      </ng-container>

    </mat-list>

    <ng-template #defaultTemplate>

      <p class='text-center' style='margin-top: 10px'> Nessuna qualifica disponibile.</p>

    </ng-template>

  </mat-card-content>

  <mat-card-actions>

    <button
      mat-raised-button
      [routerLink]="['best-times']"
      [queryParams]='{ Oid: configuration?.info?.Oid, Name: configuration?.info?.FullName}'
      color='primary'> Tempi migliori
    </button>
  </mat-card-actions>

</mat-card>
