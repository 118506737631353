<h1 mat-dialog-title>Conferma pagamento</h1>
<div mat-dialog-content>

  <ng-container [ngSwitch]='data.method'>

    <ng-container *ngSwitchCase='cartPaymentMethod.Bank'>

      Confermando l’operazione si dichiara di voler effettuare il pagamento mediante bonifico bancario da effettuarsi
      alle seguenti coordinate bancarie:<br />
      <br />

      <div class='m-b-20 m-l-10'>

        <ng-container *ngTemplateOutlet='CoordinateBancarie'></ng-container>

      </div>

    </ng-container>

    <ng-container *ngSwitchCase='cartPaymentMethod.BankTransferSplitted2'>

      Confermando l’operazione si dichiara di voler effettuare il pagamento mediante bonifico bancario da effettuarsi
      alle seguenti coordinate bancarie:<br />
      <br />

      <div class='m-b-20 m-l-10'>

        <ng-container *ngTemplateOutlet='CoordinateBancarie'></ng-container>

      </div>

    </ng-container>

    <ng-container *ngSwitchDefault>

      Hai scelto di effettuare un pagamento tramite {{ payment }}. <br />

    </ng-container>

  </ng-container>

  Confermi di voler proseguire?

</div>
<div mat-dialog-actions style='display: flex; justify-content: space-between'>
  <button mat-button [mat-dialog-close]='false'>No</button>
  <button mat-raised-button color='primary' [mat-dialog-close]='true' cdkFocusInitial>Conferma</button>
</div>

<ng-template #CoordinateBancarie>

  <div>
    SUPERBA NUOTO SSD RL
  </div>
  <div>
    BANCO BPM
  </div>
  <div>
    IBAN:

    <div class='iban-cntr'>
      {{IBAN}}
      <button mat-icon-button [cdkCopyToClipboard]='IBAN' color='primary' (click)='copy()'>
        <mat-icon style='font-size: 15px'>content_copy</mat-icon>
      </button>
    </div>
  </div>

  <ng-container
    *ngIf='data.checkOutPaymentData?.product?.MainProductCategoryMacroType === productCategoryMacroType.Subscription'>

    <div>
      CAUSALE: TESSERAMENTO E QUOTA STAGIONE AGONISTICA CODICE FISCALE DELL’ATLETA
    </div>

  </ng-container>

</ng-template>
