<div class='login-register'>

  <div class='login-register-box'>

    <mat-card>

      <mat-card-content>

        <form [formGroup]='form' (ngSubmit)='onSubmit()'>

          <div class='text-center'>
            <img class='logo-icon-format' alt='superbanuoto' src='assets/images/logo_superbanuoto_icon.svg' />
            <h4 class='m-t-0'>Reimposta password</h4>
          </div>

          <p class='text-center font-14'>
            Inserisci la tua email e la tua nuova password
          </p>

          <div fxLayout='column' fxLayoutAlign='space-around'>

            <div class='pb-1'>
              <mat-form-field style='width: 100%'>
                <input matInput placeholder='Indirizzo email' type='email' [formControl]="form.controls['email']" />
              </mat-form-field>
              <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                     class='text-danger support-text'>Inserisci il tuo indirizzo email.</small>
              <small *ngIf="form.controls['email'].errors?.email && form.controls['email'].touched"
                     class='text-danger support-text'>Non è un indirizzo email valido.</small>
            </div>

            <div class='pb-1'>
              <mat-form-field style='width: 100%'>
                <input matInput placeholder='Nuova password' type='password'
                       [formControl]="form.controls['password']"[type]="hide ? 'password' : 'text'" />
                       <mat-icon matSuffix  (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
              <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched"
                     class='text-danger support-text'>Inserisci la nuova password.</small>
            </div>

            <div fxFlex.gt-sm='50' fxFlex.gt-xs='50' class='text-right'>
              <a [routerLink]="['/authentication/login']" class='link'>Torna alla login</a>
            </div>

            <button mat-raised-button color='primary' type='submit'
                    class='btn-block btn-lg m-t-20'
                    [disabled]='!form.valid'>
              Reimposta password
            </button>

          </div>

        </form>

      </mat-card-content>

    </mat-card>

  </div>

</div>
