import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkout-splitted-warning',
  templateUrl: './checkout-splitted-warning.component.html',
  styleUrls: ['./checkout-splitted-warning.component.scss'],
})
export class CheckoutSplittedWarningComponent {

  constructor() {
  }

}
