import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root',
})
export class TechnicalHelperService {

  constructor(private router: Router, private general: GeneralService) {
  }

  back(technicalConfig?: { lastPath: string[], qp: any }) {

    if (technicalConfig) { // Sono tecnico e sto vedendo qualche dettaglio di un mio atleta

      this.router.navigate(technicalConfig.lastPath, { queryParams: technicalConfig.qp });

    } else { // Sono atleta e sto vedendo il mio dettaglio

      this.general.back();

    }

  }

  backToAthleteList(oid: number | null = null) {

    let config;

    if (oid) {

      config = {
        lastPath: ['/', 'athletes', 'list'],
        qp: { oid },
      };

    }

    this.back(config);

  }

}
