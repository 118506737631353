<h2>Modifica password</h2>

<div class='profile-cntr'>

  <div class='profile-cntr-box'>

    <mat-card>

      <mat-card-content>

        <form [formGroup]='form' (ngSubmit)='onSubmit()'>

          <div class='text-center'>
            <img class='logo-icon-format' alt='superbanuoto' src='../../../../../assets/images/logo_superbanuoto_icon.svg' />
          </div>

          <div fxLayout='row wrap'>

            <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-20'>

              <mat-form-field>

                <input matInput placeholder='Password attuale' formControlName='oldpassword' type='password'[type]="hideOldPw ? 'password' : 'text'" />
                <mat-icon matSuffix  (click)="hideOldPw = !hideOldPw">{{hideOldPw ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="form.get('oldpassword')?.hasError('required')">Inserisci la
                  tua password attuale
                </mat-error>

              </mat-form-field>

            </div>

            <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-20'>

              <mat-form-field>

                <input matInput placeholder='Nuova password' formControlName='newpassword' type='password' [type]="hideNewPw ? 'password' : 'text'" />
                <mat-icon matSuffix  (click)="hideNewPw = !hideNewPw">{{hideNewPw ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="form.get('newpassword')?.hasError('required')"> Inserisci la
                  tua nuova password
                </mat-error>
                <mat-error
                  *ngIf="!form.get('newpassword')?.hasError('required') && form.get('newpassword')?.errors?.notEqualTo">
                  Le nuova password non può essere uguale a quella precedente.
                </mat-error>
                <mat-error
                  *ngIf="!form.get('newpassword')?.hasError('required') && form.get('newpassword')?.hasError('passwordStrength')">
                  La password deve essere composta da
                  almeno 6 caratteri, contenere
                  almeno una lettera minuscola, una lettera maiuscola e un numero
                </mat-error>

              </mat-form-field>

            </div>

            <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100' class='m-b-20'>

              <mat-form-field>

                <input matInput placeholder='Conferma password' formControlName='repeatnewpassword' type='password' [type]="hideNewPw2 ? 'password' : 'text'" />
                <mat-icon matSuffix  (click)="hideNewPw2 = !hideNewPw2">{{hideNewPw2 ? 'visibility_off' : 'visibility'}}</mat-icon>

                <mat-error *ngIf="form.get('repeatnewpassword')?.hasError('required')"> Inserisci la
                  tua conferma password
                </mat-error>
                <mat-error
                  *ngIf="!form.get('repeatnewpassword')?.hasError('required') && form.get('repeatnewpassword')?.errors?.equalTo">
                  Le password non coincidono
                </mat-error>

              </mat-form-field>

            </div>

            <div fxFlex.gt-sm='100' fxFlex.gt-xs='100' fxFlex='100'>

              <button mat-raised-button color='primary' class='btn-block btn-lg m-t-20 m-b-20' type='submit'
                      [disabled]='!form.valid'>
                Salva
              </button>

            </div>

          </div>

        </form>

      </mat-card-content>

    </mat-card>

  </div>

</div>
