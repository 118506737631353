import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { AdvertisingDTO } from '../interfaces/advertising';

@Injectable({
  providedIn: 'root',
})
export class AdvertisingController {

  controller: string = '/Advertising/';

  params: any = {};

  constructor(private ctWebapiService: CtWebapiService) {
  }

  getList(): Promise<CtWebapiGenericResponse<AdvertisingDTO[]>> {

    return new Promise<CtWebapiGenericResponse<AdvertisingDTO[]>>((resolve) => {

      this.ctWebapiService.get(new DataRequest(`${this.controller}GetList`)).subscribe(r => resolve(r as CtWebapiGenericResponse<AdvertisingDTO[]>));

    });

  }

}
