export class NotificationEventSelectControlConfiguration {

  timeLimitOnly: boolean = false;
  multiple: boolean | null = null;
  appearance: string | null = null;
  nullValueEnabled: boolean = true;

  private constructor() {
  }

  public static create = (): NotificationEventSelectControlConfiguration => new NotificationEventSelectControlConfiguration();

  setTimeLimitOnly(value: boolean) {

    this.timeLimitOnly = value;
    return this;

  }

  setMultiple(value: boolean | null) {

    this.multiple = value;
    return this;

  }

  setAppearance(value: string | null) {

    this.appearance = value;
    return this;

  }

  setNullValueEnabled(value: boolean) {

    this.nullValueEnabled = value;
    return this;

  }

}
