import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NotificationsWidgetConfiguration } from '../notifications-widget.configuration';
import { SPNNotification } from '../../../core/controllers/global-notification.controller';
import { CTExpandableTextConfiguration } from '@ctsolution/ct-framework';

@Component({
  selector: 'app-notification-post-it',
  templateUrl: './notification-post-it.component.html',
  styleUrls: ['./notification-post-it.component.scss'],
})
export class NotificationPostItComponent implements OnInit {
  @Input() configuration: NotificationsWidgetConfiguration | null = null;
  @Output() onDownload: EventEmitter<SPNNotification> = new EventEmitter<SPNNotification>();
  notifications: SPNNotification[] = [];

  ngOnInit() {
    this.notifications = this.configuration?.list ?? [];
  }

  getExpandableTextConfiguration(value: SPNNotification) {

    return CTExpandableTextConfiguration
      .create()
      .setText(value.Body)
      .setMaxLength(200);

  }

}
