import { Injectable } from '@angular/core';
import {
  CtSerializerHelperService,
  CtWebapiGenericResponse,
  CtWebapiService,
  DataRequest,
  MethodEnum,
} from '@ctsolution/ct-webapi';
import { Observable } from 'rxjs';
import { CTDatatablePaginationParameter, CtDatatableResult } from '@ctsolution/ct-framework';
import { GlobalNotificationParameter } from '../classes/global-notification-parameter';
import { CTBase } from '@ctsolution/ct-base';

const CONTROLLER: string[] = ['GlobalNotification'];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class GlobalNotificationController {

  constructor(private _webapi: CtWebapiService, private serializerHelperService: CtSerializerHelperService) {
  }

  getGeneralList = (count: number = 100): Promise<SPNNotification[]> => this.getList('GetList', count);

  getTechnicalList = (count: number = 100): Promise<SPNNotification[]> => this.getList('GetTechnicalList', count);

  getList(action: string, count: number = 100): Promise<SPNNotification[]> {

    return new Promise<SPNNotification[]>((resolve) => {

      const pagination: CTDatatablePaginationParameter = new CTDatatablePaginationParameter(1, count);

      const request: DataRequest = CONTROLLER_REQUEST()
        .setAction(action)
        .setQueryParams(pagination);

      this._webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<CtDatatableResult<SPNNotification>>) => {

          let items: SPNNotification[] = (response?.Result?.Items ?? []).map((elm: SPNNotification) => SPNNotification.create(elm));
          resolve(items);

        });

    });

  }

  insertGlobalNotification(parameter: GlobalNotificationParameter): Observable<CtWebapiGenericResponse<any>> {

    const isUpdate = (parameter.Oid ?? 0) > 0;

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction(isUpdate ? 'UpdateGlobalNotification' : 'InsertGlobalNotification')
      .setMethod(isUpdate ? MethodEnum.PUT : MethodEnum.POST)
      .setBody(isUpdate ? parameter : this.serializerHelperService.objectToFormData(parameter));

    return this._webapi
      .request(request);

  }

  deleteGlobalNotification(oid: number) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction('DeleteGlobalNotification')
      .setQueryParams({ oid });

    return this._webapi
      .delete(request);

  }

}

export class SPNNotification extends CTBase<SPNNotification> {

  Oid: number | null = null;
  Title: string | null = null;
  Body: string | null = null;
  DownloadUrl: string | null = null;
  FileName: string | null = null;
  Companies: number[] = new Array<number>();
  EventProducts: number[] = new Array<number>();
  CompaniesBusinessName: string | null = null;
  EnabledDate: string | null = null;
  SentDate: string | null = null;
  GlobalNotificationTrackingsTotal: number | null = null;
  GlobalNotificationTrackingsRead: number | null = null;
  ForExpiringDocument: boolean | null = null;
  OwnerUserInfoGetFullName: string | null = null;
  OwnerIsSuperbaNuoto: boolean | null = null;

  override createListElementClassByProperty(value: any, attribute: string): number | null {

    switch (attribute) {

      case 'Companies':
      case 'EventProducts':
        return +value;

      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  private constructor();
  private constructor(model?: SPNNotification);
  private constructor(model?: SPNNotification) {
    super();

    if (model) {

      this.getClass(model);

    }

  }

  public static create = (model?: SPNNotification): SPNNotification => new SPNNotification(model);

  get editEnabled(): boolean {

    return new Date(this.EnabledDate ?? 0).getTime() > new Date().getTime();

  }

}
