import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-profile-confirm',
  template: `
    <h1 mat-dialog-title>Eliminazione profilo</h1>
    <div mat-dialog-content>
      <p>Confermi di voler eliminare il tuo profilo? Una volta eliminato non potrai più accedere all'applicazione ed
        usufruire dei vantaggi ad essa associati.</p>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color='warn' [mat-dialog-close]='false'>
        <mat-icon>close</mat-icon>
        Annulla
      </button>

      <button mat-raised-button color='primary' [mat-dialog-close]='true'>
        Prosegui
      </button>
    </div>
  `,
  styles: [`
    ::ng-deep app-delete-profile-confirm {
      .mat-dialog-actions {
        display: flex;
        justify-content: space-between;
      }
    }
  `]
})
export class DeleteProfileConfirmComponent {
}
