import { Component } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';
import { TimeService } from '../../../../core/lib/time.service';
import { SPNEventProduct } from '../../../../core/classes/SPNEvent';
import { SPNEventRaceResult } from '../../../../core/classes/SPNEventRaceResult';
import { EventController, PoolLengthDTO } from '../../../../core/controllers/event.controller';
import {
  POOL_LENGTHS
} from '../../../../_components/spn-filters/spn-pool-length-filter/spn-pool-length-filter.component';

@Component({
  selector: 'app-historical-chart',
  templateUrl: './historical-chart.component.html',
  styleUrls: ['./historical-chart.component.scss'],
})
export class HistoricalChartComponent {

  results: Array<{ name: string; series: Array<{ value: number; name: number; tooltipText: string }> }> = [];

  chart: any = {
    yAxis: true,
    xAxis: true,
    yScaleMin: 0,
    showYAxisLabel: false,
    showXAxisLabel: false,
    legend: false,
    legendPosition: LegendPosition.Below,
    xAxisLabel: 'Data',
    yAxisLabel: 'Tempo',
    title: null,
    referenceLines: [],
    showRefLines: true,
    showRefLabels: true,
    yAxisTickFormatting: (value: number) => this.timeService.msToTime(value, false),
    xAxisTickFormatting: (value: number) => new Date(value).toLocaleDateString(),
  };

  constructor(public timeService: TimeService, private eventController: EventController) {
  }

  getSwimStyleName = (swimStyle: number): string | null => this.eventController.swimStyleList.find(elm => elm.Oid === swimStyle)?.Name ?? null;

  setupChart(results: SPNEventProduct[] = [], filtersValue: any) {

    this.results = this.getChart(results, filtersValue);

  }

  private getChart(results: SPNEventProduct[] = [], filtersValue: any): Array<{
    name: string;
    series: Array<{ value: number; name: number; tooltipText: string }>
  }> {

    if (results.length === 0) {

      return [];

    }

    const swimStyleName: string | null = this.getSwimStyleName(filtersValue.SwimStyle);

    if (swimStyleName && filtersValue.Distance) {

      const chartSeries: Array<{
        name: string;
        series: Array<{ value: number; name: number; tooltipText: string }>
      }> = [];

      const getProductSeriesValue = (product: SPNEventProduct): {
        value: number;
        name: number;
        tooltipText: string
      } | null => {

        const raceResult: SPNEventRaceResult | undefined = (product.EventProduct?.RaceResultList ?? [])[0];

        if (raceResult) {

          const date: Date = new Date(product.EventProduct?.StartDate ?? 0);

          return {
            name: date.getTime(),
            value: this.timeService.timeStringToMs(raceResult.TimeForGrid),
            tooltipText: date.toLocaleDateString(),
          };

        }

        return null;

      };

      POOL_LENGTHS
        .forEach((element: PoolLengthDTO) => {

          const poolSeries: Array<{ value: number; name: number; tooltipText: string }> = [];

          results
            .filter((product: SPNEventProduct) => product?.EventProduct?.PoolLength === element.Oid)
            .forEach((product: SPNEventProduct) => {

              const serie: { value: number; name: number; tooltipText: string } | null = getProductSeriesValue(product);

              if (serie) poolSeries.push(serie);

            });

          if (poolSeries.length) {

            chartSeries.push({
              name: `${swimStyleName} ${filtersValue.Distance}m - Vasca ${element.Value} m`,
              series: poolSeries,
            });

          }

        });

      this.chart.title = `${swimStyleName} ${filtersValue.Distance}m`;

      let min: number = 0;

      chartSeries
        .forEach((elm) => {

          const minOfSerie: number = Math.min(...elm.series.map(item => item.value));

          if (!min || min && min > minOfSerie) min = minOfSerie;

        });

      this.chart.yScaleMin = min;

      return chartSeries;

    }

    return [];

  }


}
