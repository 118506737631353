<div class="login-register">

    <div class="login-register-box">

        <mat-card>

            <mat-card-content>

                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="text-center">
                        <img class="logo-icon-format" alt="superbanuoto" src="assets/images/logo_superbanuoto_icon.svg" />
                        <h4 class="m-t-0">Recupero password</h4>
                    </div>

                    <p class="text-center font-14">
                        Inserisci la tua email e ti invieremo un link per resettare la password.
                    </p>

                    <div fxLayout="column" fxLayoutAlign="space-around">

                        <div class="pb-1">
                            <mat-form-field style="width: 100%">
                                <input matInput placeholder="Indirizzo email" type="email" [formControl]="form.controls['email']" />
                            </mat-form-field>
                            <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" class="text-danger support-text">L'indirizzo email è obbligatorio.</small>
                            <small *ngIf="form.controls['email'].errors?.email && form.controls['email'].touched" class="text-danger support-text">Inserisci un indirizzo email valido.</small>
                        </div>

                        <!-- col half-->
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" class="text-right">
                            <a [routerLink]="['/authentication/login']" class="link">Torna alla login</a>
                        </div>

                        <button mat-raised-button color="primary" type="submit" class="btn-block btn-lg m-t-20" [disabled]="!form.valid">
                          Recupero password
                        </button>

                    </div>

                </form>

            </mat-card-content>

        </mat-card>

    </div>

</div>
