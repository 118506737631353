<div class='familiar-container'>

  <h2> Genitore </h2>

  <div>

    <h4>Inserisci i dati di un tuo genitore</h4>
    <h6 class='m-t-0'> Obbligatorio se sei minorenne </h6>

  </div>

  <form [formGroup]='form'>

    <div class='m-b-10'>

      <mat-form-field style='width: 100%'>

        <input matInput placeholder='Nome' type='text' formControlName='ParentName' />
        <mat-error *ngIf="form.get('ParentName')?.hasError('required')">Campo obbligatorio
        </mat-error>

      </mat-form-field>

    </div>

    <div class='m-b-10'>

      <mat-form-field style='width: 100%'>

        <input matInput placeholder='Cognome' type='text' formControlName='ParentSurname' />
        <mat-error *ngIf="form.get('ParentSurname')?.hasError('required')">Campo obbligatorio
        </mat-error>

      </mat-form-field>

    </div>

    <div class='m-b-10'>

      <mat-form-field style='width: 100%'>

        <input matInput placeholder='Codice Fiscale' type='text' formControlName='ParentFiscalCode' />
        <mat-error *ngIf="form.get('ParentFiscalCode')?.hasError('required')">Campo obbligatorio
        </mat-error>
        <mat-error
          *ngIf="!form.get('ParentFiscalCode')?.hasError('required') && form.get('ParentFiscalCode')?.hasError('FiscalCode')">
          Campo non valido
        </mat-error>

      </mat-form-field>

    </div>

    <div class='m-b-10'>

      <mat-form-field style='width: 100%'>

        <input matInput placeholder='Cellulare' type='text' formControlName='ParentPhone' type='tel' />
        <mat-error *ngIf="form.get('ParentPhone')?.hasError('required')">Campo obbligatorio
        </mat-error>
        <mat-error *ngIf="form.get('ParentPhone')?.hasError('pattern')">Campo non valido</mat-error>
      </mat-form-field>

    </div>

    <div class='m-b-10'>

      <mat-form-field>

        <input matInput placeholder='Indirizzo mail' type='email' formControlName='ParentEmail' />

        <mat-error *ngIf="form.get('ParentEmail')?.hasError('required')"> Campo obbligatorio
        </mat-error>
        <mat-error *ngIf="form.get('ParentEmail')?.hasError('maxlength')"> La
          mail è troppo lunga
        </mat-error>
        <mat-error *ngIf="form.get('ParentEmail')?.errors?.email"> Inserisci un indirizzo email valido</mat-error>

      </mat-form-field>

    </div>

  </form>

  <div class='actions-container m-t-25' style='padding: 10px'>

    <button mat-button *ngIf='comesFromRegistration && skipEnabled' (click)='skip()'> Salta questo passaggio</button>

    <button mat-raised-button color='primary' style='min-width: 180px;'
            (click)='saveParentData()'> {{ comesFromRegistration ? 'Salva e procedi' : 'Salva' }} </button>

  </div>

</div>
