import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SPNEvent, SPNEventProduct } from '../../../../../../../core/classes/SPNEvent';
import {
  ConfirmEventPartecipationParameter,
  EventController,
} from '../../../../../../../core/controllers/event.controller';
import { CtWebapiGenericResponse } from '@ctsolution/ct-webapi';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EventCancelConfirmComponent } from '../event-cancel-confirm/event-cancel-confirm.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { GeneralService } from '../../../../../../../core/lib/general.service';

@Component({
  selector: 'app-event-card-actions',
  templateUrl: './event-card-actions.component.html',
  styleUrls: ['./event-card-actions.component.scss'],
})
export class EventCardActionsComponent {

  @Input() product: SPNEventProduct | null = null;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadBrochure: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadConvocationDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadLimitTimes: EventEmitter<any> = new EventEmitter<any>();

  viewModel: any = {

    subscribable: false,
    confirmed: false,
    eventWaitingPayment: false,
    eventSuccessfulPaid: false,
    invitationLabel: null,

  };

  constructor(private eventController: EventController, private dialog: MatDialog, private snackbar: MatSnackBar, private router: Router, private general: GeneralService) {
  }

  ngAfterViewInit() {

    if (this.product) {

      this.viewModel.eventWaitingPayment = this.product?.eventWaitingPayment() ?? false;
      this.viewModel.eventSuccessfulPaid = this.product?.eventSuccessfulPaid() ?? false;

      if (this.product.EventProduct) {

        this.viewModel.subscribable = this.product?.EventProduct?.subscribable() ?? false;
        this.viewModel.confirmed = this.product?.EventProduct?.confirmed() ?? false;
        this.viewModel.invitationLabel = this.product?.EventProduct?.getInvitationLabel();

      }

    }

  }

  accept = () => this.confirm(true);

  cancel(): void {

    this.dialog
      .open(EventCancelConfirmComponent)
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) {

          this.confirm(false);

        }

      });

  }

  confirm(accepted: boolean) {

    const parameter: ConfirmEventPartecipationParameter = new ConfirmEventPartecipationParameter(this.product?.EventProduct?.Oid!, accepted);

    this.eventController
      .confirmEventPartecipation(parameter)
      .then((response: CtWebapiGenericResponse<any>) => {

        this.snackbar.open(`Partecipazione ${accepted ? 'confermata' : 'rifiutata'}!`, 'X', {
          duration: 3000,
          panelClass: 'general-snackbar-panel',
        }).afterOpened()
          .subscribe(() => this.reloadCurrentRoute());

      });

  }

  payment(): void {

    this.general.navigateTo(['competitions-and-events', 'my-competitions', 'competition-payment'], { queryParams: { id: this.product?.Oid } });

  }

  private reloadCurrentRoute(): void {

    const currentUrl = this.router.url;

    this.router
      .navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([currentUrl]));

  }

}
