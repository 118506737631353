import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanyController } from '../../../../../core/controllers/company.controller';
import { CtWebapiGenericResponse } from '@ctsolution/ct-webapi';
import { SPNCompanyDTO } from '../../../../../core/interfaces/company';

@Component({
  selector: 'app-notification-companies-select-control',
  templateUrl: './notification-companies-select-control.component.html',
  styleUrls: ['./notification-companies-select-control.component.scss'],
  providers: [CompanyController],
})
export class NotificationCompaniesSelectControlComponent implements OnInit {

  @Input() control: FormControl | null = null;

  values: SPNCompanyDTO[] = [];

  constructor(private companyController: CompanyController) {
  }

  ngOnInit() {

    this.getList();

  }

  getList() {

    this.companyController
      .list()
      .then((result: CtWebapiGenericResponse<SPNCompanyDTO[]>) => this.values = result.Result);

  }

}
