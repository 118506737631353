import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCompetitionsComponent } from './my-competitions/my-competitions.component';
import { RouterModule } from '@angular/router';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { EventListModule } from '../_components/event-list/event-list.module';
import { CompetitionPaymentModule } from './competition-payment/competition-payment.module';

@NgModule({
  declarations: [
    MyCompetitionsComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    RouterModule.forChild([
      {
        path: 'competition-payment',
        loadChildren: () => CompetitionPaymentModule,
      },
      {
        path: '',
        component: MyCompetitionsComponent,
        data: {
          title: 'Mie Gare',
          urls: [{ title: 'Mie Gare', url: '/competitions-and-events/my-competitions' }, { title: 'Mie Gare' }],
        },
      }]),
    EventListModule,
  ],
})
export class MyCompetitionsModule {
}
