import { Injectable } from '@angular/core';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { SPNEventProduct, SPNLightEventProduct } from '../classes/SPNEvent';
import { EventFilterStatus } from '../enums/event-filter-status';
import { EventCategoryCode } from '../enums/event-category-code';
import { CTDatatablePaginationParameter, CtDatatableResult } from '@ctsolution/ct-framework';
import { GetEventDetailParameter } from '../classes/get-event-detail-parameter';
import { AthleteCategoryEnum } from '../enums/athlete-category.enum';
import { GenderEnum } from '../enums/gender.enum';

const EVENT_CONTROLLER = ['Event'];

const STANDARD_REQUEST = (): DataRequest => DataRequest
  .create()
  .setController(EVENT_CONTROLLER);

@Injectable({
  providedIn: 'root',
})
export class EventController {

  swimStyleList: SwimStyleDTO[] = [];
  private distanceList: number[] = [];

  constructor(private webapi: CtWebapiService) {
  }

  getList(filters?: EventListFilterDTO, pagination: CTDatatablePaginationParameter = new CTDatatablePaginationParameter()): Promise<CtDatatableResult<SPNEventProduct>> {

    pagination
      .setElementsForPage(null);

    return new Promise<CtDatatableResult<SPNEventProduct>>((resolve) => {

      let params: any = {
        ...filters,
        ...pagination,
        hideSpinner: true,
      };

      params = Object.fromEntries(Object.entries(params).filter(([key, value]) => (!!value || value === 0)));

      const request = STANDARD_REQUEST()
        .setAction('GetList')
        .setQueryParams(params);

      this.webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<CtDatatableResult<SPNEventProduct>>) => {

          let items: SPNEventProduct[] = response.Result.Items ?? [];

          response
            .Result
            .Items = items.map((elm: SPNEventProduct) => {

            if ((filters?.Status === EventFilterStatus.All || !filters?.Status) && elm.EventProduct) elm.EventProduct.UserConvocationState = null;

            return SPNEventProduct.create(elm)
              .setParentProductDescription(elm.Description);

          });

          resolve(response.Result);

        });

    });

  }

  getEventProductLightList(parameter: EventProductLightListParameter): Promise<SPNLightEventProduct[]> {

    return new Promise<SPNLightEventProduct[]>((resolve) => {

      const request = STANDARD_REQUEST()
        .setAction('GetEventProductLightList')
        .setQueryParams(parameter);

      this.webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<SPNLightEventProduct[]>) => resolve(response.Result));

    });

  }

  getDetail(parameter: GetEventDetailParameter): Promise<SPNEventProduct> {

    return new Promise<SPNEventProduct>((resolve) => {

      const request = STANDARD_REQUEST()
        .setAction('GetDetail')
        .setQueryParams(parameter);

      this.webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<SPNEventProduct>) => resolve(SPNEventProduct.create(response.Result)));

    });

  }

  confirmEventPartecipation(parameter: ConfirmEventPartecipationParameter): Promise<CtWebapiGenericResponse<any>> {

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => {

      const request = STANDARD_REQUEST()
        .setAction('ConfirmEventPartecipation');

      this.webapi
        .post(request, parameter)
        .subscribe((response: CtWebapiGenericResponse<any>) => resolve(response));

    });

  }

  getSwimStyleList = (): Promise<SwimStyleDTO[]> => new Promise<SwimStyleDTO[]>((resolve) => {

    if (this.swimStyleList.length > 0) {

      resolve(this.swimStyleList);
      return;

    }

    const request = STANDARD_REQUEST()
      .setAction('GetSwimStyleList');

    this.webapi
      .get(request)
      .subscribe((response: CtWebapiGenericResponse<any>) => {

        this.swimStyleList = response.Result;
        resolve(this.swimStyleList);

      });


  });

  getDistanceList = (): Promise<number[]> => new Promise<number[]>((resolve) => {

    if (this.distanceList.length > 0) {

      resolve(this.distanceList);
      return;

    }

    const request = STANDARD_REQUEST()
      .setAction('GetDistanceList');

    this.webapi
      .get(request)
      .subscribe((response: CtWebapiGenericResponse<any>) => {

        this.distanceList = response.Result;
        resolve(this.distanceList);

      });

  });

}

export class EventListFilterDTO {

  public Status: EventFilterStatus = EventFilterStatus.All;
  public MustHaveTimeLimit: boolean | null = null;
  public CategoryCode?: EventCategoryCode;
  public ToEventStartDate?: Date | null = null;
  public FromEventStartDate?: Date | null = null;
  public Distance?: number;
  public PoolLength?: number;
  public SwimStyle?: number;
  public QualifiedRaceOid: number | null = null;
  public UserInfoOid: number | null = null;

  public AthleteCategories: AthleteCategoryEnum | null = null;
  public CompanyBusinessName: string | null = null;
  public EventProductOid: number | null = null;
  public Gender: GenderEnum | null = null;
  public TopTimesOnly: boolean | null = null;

  constructor() {
  }

}

export class ConfirmEventPartecipationParameter {

  constructor(
    public EventProductOid: number,
    public Accepted: boolean,
  ) {
  }

}

export class SwimStyleDTO {

  constructor(
    public Oid: number,
    public Name: string,
    public Synonyms: string,
  ) {
  }

}

export class PoolLengthDTO {

  constructor(
    public Oid: number,
    public Value: number,
  ) {
  }

}

export class EventProductLightListParameter {

  constructor(public TimeLimitOnly: boolean = false) {
  }

}
