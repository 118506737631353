<mat-form-field appearance='outline'>

  <mat-label>Tipologia</mat-label>

  <mat-select [formControl]='control'>
    <mat-option *ngFor='let type of values' [value]='type'>
      {{getLabel(type)}}
    </mat-option>
    <mat-option [value]='null'>Vedi tutto</mat-option>
  </mat-select>

</mat-form-field>
