import { EventCategoryCode } from '../enums/event-category-code';
import { CTBase } from '@ctsolution/ct-base';
import { EventStatus } from '../enums/event-status';
import { SPNEventRaceResult } from './SPNEventRaceResult';
import { PoolLengthDTO } from '../controllers/event.controller';
import { CartPaymentStateEnum } from '../enums/cart-payment-state.enum';
import { POOL_LENGTHS } from '../../_components/spn-filters/spn-pool-length-filter/spn-pool-length-filter.component';
import { AthleteTimeResult } from '../interfaces/athlete-time-result';
import { SportFacility } from './sport-facility';

export interface SPNLightEventProduct {

  Oid: number | null;
  ProductOid: number | null;
  ProductName: string | null;
  ProductDescription: string | null;
  StartDate: string | null;
  EndDate: string | null;

}

export class SPNEventProduct extends CTBase<SPNEventProduct> {

  Oid: number | null = 0;
  Name: string | null = null;
  Description: string | null = null;
  UrlEventPosterImage: string | null = null;
  UrlEventPosterDocument: string | null = null;
  UrlEventTimeLimitDocument: string | null = null;
  UrlEventConvocationDocument: string | null = null;
  EventProduct: SPNEvent | null = SPNEvent.create();
  PaymentState: CartPaymentStateEnum | null = null;
  ParentProductDescription: string | null = null;

  private constructor();
  private constructor(model?: SPNEventProduct);
  private constructor(model?: SPNEventProduct) {

    super();

    if (model) {

      this.getClass(model);

      if (model.EventProduct) this.EventProduct = SPNEvent.create(model.EventProduct);

    }

  }

  public static create = (model?: SPNEventProduct): SPNEventProduct => new SPNEventProduct(model);

  setName(value: string | null): SPNEventProduct {

    this.Name = value;
    return this;

  }

  setParentProductDescription(value: string | null): SPNEventProduct {

    this.ParentProductDescription = value;
    return this;

  }

  eventSuccessfulPaid(): boolean {

    return !!this.PaymentState && this.PaymentState === CartPaymentStateEnum.Success;

  }

  eventWaitingPayment(): boolean {

    return !!this.PaymentState && this.PaymentState === CartPaymentStateEnum.WaitingValidation;

  }

  setupFromAthleteTimeResult(value: AthleteTimeResult | null) {

    this.EventProduct = SPNEvent
      .create()
      .setTrophyDescription(value?.SPNEventProductTrophyDescription ?? null)
      .setStartDate(value?.SPNEventProductStartDate ?? null)
      .setPoolLength(value?.PoolLength ?? null);

    this.EventProduct
      .SportFacility
      ?.setDescription(value?.SPNEventProductSportFacilityName ?? null);

    if ((value?.EventProductSplitTimes ?? []).length) {

      this.EventProduct
        ?.setRaceResultListFromAthleteResult(value);

    }

    return this
      .setName(value?.SPNEventProductProductName ?? null);

  }

}

export class SPNEvent extends CTBase<SPNEvent> {

  Oid: number | null = 0;
  CategoryCode: EventCategoryCode = EventCategoryCode.COMPETITION;
  TrophyDescription: string | null = null;
  SportFacility: SportFacility | null = SportFacility.create();
  StartDate: string | null = null;
  EndDate: Date | null = null;
  UserConvocationState: EventStatus | null = null;
  PoolLength: number | null = null;
  ConvocationDataList: SPNConvocationData[] | null = new Array<SPNConvocationData>();
  Time: string | null = null;
  RaceResultList: SPNEventRaceResult[] | null = new Array<SPNEventRaceResult>();

  override createListElementClassByProperty(value: any, attribute: string): SPNConvocationData | SPNEventRaceResult | null {

    switch (attribute) {

      case 'ConvocationDataList':
        return SPNConvocationData.create(value);

      case 'RaceResultList':
        return SPNEventRaceResult.create(value);

      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  private constructor();
  private constructor(model?: SPNEvent);
  private constructor(model?: SPNEvent) {

    super();
    if (model) {

      this.getClass(model);

      if (model.SportFacility) this.SportFacility = SportFacility.create(model.SportFacility);

    }

  }

  public static create = (model?: SPNEvent): SPNEvent => new SPNEvent(model);

  getIcon(): string {

    const prefix: string = '/assets/images/events/icons/';

    switch (this.CategoryCode) {

      case EventCategoryCode.COMPETITION:
        return `${prefix}competition.svg`;

      case EventCategoryCode.EVENT:
      default:
        return `${prefix}generic.svg`;
    }

  }

  getInvitationLabel(): string {

    const prefix: string = 'Clicca su conferma per partecipare';

    switch (this.CategoryCode) {

      case EventCategoryCode.COMPETITION:
        return `${prefix} alla gara`;

      case EventCategoryCode.EVENT:
      default:
        return `${prefix} all'evento`;

    }

  }

  confirmed(): boolean {

    return this.UserConvocationState === EventStatus.PartecipationAccepted;

  }

  subscribable(): boolean {

    const startDate: Date = new Date(this.StartDate ?? 0);
    startDate.setHours(0, 0, 0, 0);

    const todayDate: Date = new Date();
    todayDate.setHours(0, 0, 0, 0);

    return (this.UserConvocationState ?? -1) >= 0 && startDate.getTime() >= todayDate.getTime();

  }

  getPoolLengthValue(): number | null {

    const poolLength: PoolLengthDTO | undefined = POOL_LENGTHS.find((elm: PoolLengthDTO) => elm.Oid === this.PoolLength);

    if (poolLength) {

      return poolLength.Value;

    }

    return null;

  }

  setTrophyDescription(value: string | null): SPNEvent {

    this.TrophyDescription = value;
    return this;

  }

  setStartDate(value: string | null): SPNEvent {

    this.StartDate = value;
    return this;

  }

  setPoolLength(value: number | null): SPNEvent {

    this.PoolLength = value;
    return this;

  }

  setRaceResultList(value: SPNEventRaceResult[] | null): SPNEvent {

    this.RaceResultList = value;
    return this;

  }

  setRaceResultListFromAthleteResult(value: AthleteTimeResult | SPNEventRaceResult | null = null): SPNEvent {

    if (value) {

      const raceResult: SPNEventRaceResult = SPNEventRaceResult
        .create()
        .setRaceSpecialtySwimStyleName(value?.RaceSpecialtySwimStyleName ?? null)
        .setEventProductSplitTimes(value?.EventProductSplitTimes ?? [])
        .setRaceSpecialtyDistance(value.RaceSpecialtyDistance)
        .setTimeForGrid(value.TimeForGrid);

      this
        .setRaceResultList(new Array<SPNEventRaceResult>(raceResult));

    }

    return this;

  }

}

export class SPNConvocationData extends CTBase<SPNConvocationData> {

  public SwimStyleName: string | null = null;
  public Distance: number | null = null;
  public Time: string | null = null;

  private constructor();
  private constructor(model?: SPNConvocationData);
  private constructor(model?: SPNConvocationData) {

    super();
    if (model) this.getClass(model);

  }

  public static create = (model?: SPNConvocationData): SPNConvocationData => new SPNConvocationData(model);

}
