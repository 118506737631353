import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-parent-redirect-confirm',
  templateUrl: './parent-redirect-confirm.component.html',
  styleUrls: ['./parent-redirect-confirm.component.scss'],
})
export class ParentRedirectConfirmComponent {

  constructor(private _bottomSheetReference: MatBottomSheetRef) {
  }

  dismiss(proceed: boolean = false) {

    this._bottomSheetReference
      .dismiss(proceed);

  }

}
