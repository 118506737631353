<mat-accordion class='event-filters-cntr'>

  <mat-expansion-panel [expanded]='expandedFilterPanel'>

    <mat-expansion-panel-header>
      <mat-panel-title>
        Filtra la tua ricerca
      </mat-panel-title>
    </mat-expansion-panel-header>

    <form
      [formGroup]='form'
      fxLayout='row wrap'>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-daterange-filter
          [startDateControl]="form.get('StartDate')"
          [endDateControl]="form.get('EndDate')"></app-spn-daterange-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-specialty-filter [control]="form.get('SwimStyle')"></app-spn-specialty-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-distance-filter [control]="form.get('Distance')"></app-spn-distance-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10'>

        <app-spn-pool-length-filter [control]="form.get('PoolLength')"></app-spn-pool-length-filter>

      </div>

      <div
        fxFlex='100'
        fxFlex.gt-md='50'
        class='filter-input p-l-10 p-r-10 must-have-time-limit-cntr'>

        <mat-checkbox formControlName='MustHaveTimeLimit' class='m-b-10'> Tempi limite</mat-checkbox>

      </div>

    </form>

  </mat-expansion-panel>

</mat-accordion>

