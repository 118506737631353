import { Component } from '@angular/core';
import { CartController } from '../../../core/controllers/cart.controller';
import { UserCart } from '../../../core/interfaces/user-cart';
import { ProductController } from '../../../core/controllers/product.controller';
import { ProductListService } from '../../../_components/product-list/product-list.service';
import { UserCartProductDTO } from '../../../core/interfaces/user-cart-product';

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss'],
  providers: [CartController, ProductController],
})
export class CartListComponent {

  cart: UserCart | null = null;

  constructor(
    private cartController: CartController,
    private productListService: ProductListService) {
  }

  ngOnInit(): void {

    this.getCartItems();

  }

  getCartItems() {

    this.cartController
      .getActiveCart()
      .then((result: UserCart | null) => this.cart = result);

  }

  emptyCart() {

    this.cartController
      .deleteActive()
      .then(() => this.getCartItems());

  }

  deleteFromCart(product: UserCartProductDTO) {

    this.cartController
      .removeProductFromActive(product.Product.Oid)
      .then(() => this.getCartItems());

  }

  checkOut = () => this.productListService.cartCheckOut(this.cart);

}

