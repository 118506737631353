<div class='document-box-uploader-cntr m-b-20'>

  <mat-label><strong>{{configuration.documentLabel}}</strong></mat-label>

  <div fxLayout='row wrap' class='m-t-15'>

    <ng-container>

      <div fxFlex.gt-sm='45' fxFlex.gt-xs='45' fxFlex='100' *ngIf='isDateControlRequired()'>

        <mat-form-field appearance='fill' class='input-box-cntr' (click)='picker.open()'>

          <mat-label>Seleziona la data di emissione (gg/mm/aaaa)</mat-label>
          <input matInput [matDatepicker]='picker' [formControl]='configuration.dateControl' readonly
                 [max]='releaseDateMaxValue'>

          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>

          <mat-error *ngIf="configuration.dateControl.hasError('required')"> * Campo obbligatorio</mat-error>

        </mat-form-field>

      </div>

    </ng-container>

    <div fxFlex.gt-sm='45' fxFlex.gt-xs='45' fxFlex='100' *ngIf='isDocumentCodeControlAvailable()'>

      <mat-form-field appearance='fill' class='input-box-cntr'>

        <mat-label> Numero </mat-label>
        <input matInput type='text' [formControl]='configuration.documentCodeControl'>

        <mat-error *ngIf="configuration.documentCodeControl.hasError('required')"> * Campo obbligatorio</mat-error>

      </mat-form-field>

    </div>

  </div>

  <div fxLayout='row wrap'>

    <div fxFlex.gt-sm='45' fxFlex.gt-xs='45' fxFlex='100'>

      <app-document-single-box-uploader [configuration]='configuration'
                                        [side]='DOCUMENT_SIDE.FRONT'></app-document-single-box-uploader>

    </div>

    <div fxFlex.gt-sm='45' fxFlex.gt-xs='45' fxFlex='100' fxFlexOffset='2' *ngIf='isDocumentRetroControlRequired()'>

      <app-document-single-box-uploader [configuration]='configuration'
                                        [side]='DOCUMENT_SIDE.REAR'></app-document-single-box-uploader>

    </div>

  </div>

</div>
